import Fuse from 'fuse.js'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { BlackoutDateHeader } from './backdateheader'
import { CarsType } from './BlackoutDateType'
import { CardListCars } from './cardlistcar'
import styles from './css/backoutdate.module.css'

interface valueFilter {
  reservation: string
  rating: boolean
  rateing_count: number
}

export const BlackoutDateFilter = (props: any) => {
  const [dataFilter, setDataFilter] = useState<valueFilter>({
    reservation: '',
    rating: false,
    rateing_count: 0,
  })
  const [selectCar, setSelectCar] = useState<number | undefined>()
  const handleCheckBox = (e: any, type: string) => {
    switch (type) {
      case 'rating':
        setDataFilter({
          ...dataFilter,
          rateing_count: 0,
          rating: e.target.checked,
        })
        break
      case 'reserved':
        setDataFilter({
          ...dataFilter,
          reservation: e.target.checked,
        })
        break
      case 'myreserved':
        setDataFilter({
          ...dataFilter,
          reservation: e.target.checked,
        })
        break
      case 'ma':
        setDataFilter({
          ...dataFilter,
          reservation: e.target.checked,
        })
        break
      default:
        break
    }
  }
  const handleRate = (value: number) => {
    setDataFilter({
      ...dataFilter,
      rateing_count: value,
    })
  }
  const handleReset = (e: any) => {
    e.preventDefault()
    setDataFilter({
      reservation: '',
      rating: false,
      rateing_count: 0,
    })
    setSelectCar(undefined)
  }

  const handleRadio = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDataFilter({
      ...dataFilter,
      reservation: e.target.value,
    })
  }

  const handleFilter = () => {
    const changetodate = moment('01-' + props.monthCurrent, 'DD-MM-YYYY')
    props.setMonthActive(new Date(moment(changetodate).format('YYYY-MM-DD')))

    if (props.dataReservation.length > 0) {
      let datas = props.dataReservation

      const options = {
        includeMatches: true,
        useExtendedSearch: true,
        keys: ['vehicleid', 'reservationStatus'],
      }
      const fuses = new Fuse(props.dataReservation, options)

      const filters: any = []

      if (selectCar) {
        filters.push({ vehicleid: '=' + selectCar })
      }
      if (!selectCar) {
        const count_rating: string[] = []
        if (dataFilter.rating !== true) {
          props.carsHost.filter((val: CarsType) => {
            if (val.rating === dataFilter.rateing_count.toString()) {
              count_rating.push(val.vehicleId.toString())
            }
          })

          const filerArry = count_rating.map((val: string) => {
            return { vehicleid: '=' + val }
          })
          filters.push({ $or: filerArry })
        }
      }

      if (dataFilter.reservation) {
        filters.push({ reservationStatus: '^' + dataFilter.reservation })
      }

      if (filters.length > 0) {
        const result = fuses.search({ $and: filters })
        datas = result.map((val) => {
          return val.item
        })
        props.setDataReservation(datas)
      }
    }
    props.setCilckFilter({
      ...props.clickFilter,
      open: false,
      reset: false,
    })
  }

  const handleBack = (): void => {
    props.setCilckFilter({
      ...props.clickFilter,
      open: false,
      reset: true,
    })
  }

  useEffect(() => {
    if (selectCar) {
      setDataFilter({
        ...dataFilter,
        rating: false,
        rateing_count: 0,
      })
    }
  }, [selectCar])

  return (
    <>
      <div className={`${styles.fix_height_100}`}>
        <BlackoutDateHeader
          title="Filter"
          gotoback={true}
          btn_resrt={true}
          handleReset={handleReset}
          handleBack={handleBack}
        />
        <div className={styles.box_title_filter}>
          <label className={styles.title_filter}>By Car</label>
        </div>
        <div
          className={`${props.carsHost.length > 1 ? styles.box_list_cars : styles.box_list_car}`}
        >
          <CardListCars
            listCars={props.carsHost}
            setSelectCar={setSelectCar}
            selectCar={selectCar}
          />
        </div>
        {/* <div className={`${styles.box_title_filter} ${selectCar ? styles.disabled : ''}`}>
          <label className={styles.title_filter}>By Rating</label>
          <div className={styles.box_checkbox}>
            <span className={`${styles.all_rating}`}>All Rating</span>
            <input
              type="checkbox"
              className={styles.check_box}
              defaultChecked={dataFilter.rating}
              checked={dataFilter.rating}
              disabled={selectCar ? true : false}
              name="rating"
              value="true"
              onChange={(e) => handleCheckBox(e, 'rating')}
            />
          </div>
        </div>
        <div className={styles.box_rateing_filter}>
          <Rate
            allowHalf
            value={dataFilter.rateing_count}
            disabled={selectCar || dataFilter.rating ? true : false}
            className={`${styles.rateing_filter} ${selectCar ? styles.disabled : ''}`}
            onChange={handleRate}
          />
        </div> */}
        <div className={styles.box_title_filter}>
          <label className={styles.title_filter}>By Availability</label>
        </div>
        <div className="box_availability">
          <div className={styles.box_availability_checked}>
            <label className={styles.title_availability}>Reserved</label>
            <div className={`${styles.box_checkbox} ${styles.check_box_availability}`}>
              <input
                name="reservation"
                checked={dataFilter.reservation === 'Reserved'}
                type="radio"
                className={`${styles.check_box} `}
                value="Reserved"
                onChange={(e) => handleRadio(e)}
              />
            </div>
          </div>
        </div>
        <div className="box_availability">
          <div className={styles.box_availability_checked}>
            <label className={styles.title_availability}>My Reservation</label>
            <div className={`${styles.box_checkbox} ${styles.check_box_availability}`}>
              <input
                name="reservation"
                checked={dataFilter.reservation === 'My Reservation'}
                type="radio"
                className={`${styles.check_box} `}
                value="My Reservation"
                onChange={(e) => handleRadio(e)}
              />
            </div>
          </div>
        </div>
        <div className="box_availability">
          <div className={styles.box_availability_checked}>
            <label className={styles.title_availability}>MA</label>
            <div className={`${styles.box_checkbox} ${styles.check_box_availability}`}>
              <input
                name="reservation"
                checked={dataFilter.reservation === 'MA'}
                type="radio"
                className={`${styles.check_box} `}
                value="MA"
                onChange={(e) => handleRadio(e)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={`${styles.box_btn_submit}`}>
        <button type="submit" onClick={() => handleFilter()} className={`${styles.btn_submit}`}>
          Apply
        </button>
      </div>
    </>
  )
}
