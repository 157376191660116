import axios, { AxiosResponse } from 'axios'
import config from '../../config'

const { restfulService } = config

interface IAuthContext {
  user: any
  userLoggedIn: any
}

interface IPostReplyReview {
  reservationNo: number
  text: string
}

export enum ReservationType {
  FLEET = 'fleet',
  CORPORATE = 'corporate',
}

export enum RequestAction {
  accept = 'accept',
  reject = 'reject',
}

export enum ControlAction {
  lock = 'lock',
  unlock = 'unlock',
}

export enum ReservationStatus {
  ALL = 'ALL',
  REQUEST = 'REQUEST',
  RESERVE = 'RESERVE',
  REJECT = 'REJECT',
  FINISH = 'FINISH',
  COMPLETE = 'COMPLETE',
  CANCEL = 'CANCEL',
}

export enum ReservationCategory {
  MA = 'MA',
  EXTERNAL = 'EXTERNAL',
  LONGTERM = 'LONGTERM',
  HAUP = 'HAUP',
  INSTANT = 'INSTANT',
  BATCH = 'BATCH',
}

export interface IGetReservation {
  vehicleId?: number
  hostUserId?: number
  reservationStatus?: ReservationStatus | string
  reservationNo?: number
  page?: number
  results?: number
  dateFrom?: string
  dateTo?: string
  stationId?: number
}

export interface IGetReservationExtended extends IGetReservation {
  query?: string
  sortBy?: string
  sortOrder?: 'ASC' | 'DESC'
  reservationCategory?: ReservationCategory | string
}

export interface IReservationListMeta {
  results: number
  page: string
  total: number
}

export interface IReservationListResponse {
  result: boolean
  data: IReservationListData[]
  meta: IReservationListMeta
}
export interface IReservationListData {
  reservationno: number
  logtime: Date
  reservationstate: ReservationStatus
  category: string
  estimatedprice: number
  chargetotal: number
  chargestate: string
  charge_id: string
  chargecreditcard: string
  extra_chargetotal: number
  extra_chargestate: string
  extra_charge_id: string
  extra_chargecreditcard: string
  totalprice: number
  hourprice: number
  bundleprice: number
  discount: number
  latehourprice: number
  reservestarttime: Date
  reservestoptime: Date
  verifiedtype: string
  vehicle_picture: string
  vehiclemodel: string
  vehicleid: number
  licensenumber: string
  stationname: string
}

export interface IReservationDetail {
  reservation: {
    reservationno: number
    logtime: string
    reservationstate: ReservationStatus
    category: string
    estimatedprice: number
    chargetotal: number
    chargestate: string
    charge_id: string
    chargecreditcard: string
    extra_chargetotal: number
    extra_chargestate: string
    extra_charge_id: string
    extra_chargecreditcard: string
    hourprice: number
    distance_price: number
    promotioncode: string
    discount: number
    fullprice: number
    totalprice: number
    reservestarttime: string
    reservestoptime: string
    bundleprice: number
    latehourprice: number
    actualprice: number
    remark: string
  }
  vehicle: {
    vehicle_picture: string
    vehiclemodel: string
    licensenumber: string
    licenseprovince: string
    verifiedtype: string
    vehicleid: number
  }
  station: {
    name: string
    lat: number
    lng: number
  }
  dst_station: {
    name: string
    lat: number
    lng: number
  }
  renter: {
    name: string
    lastname: string
    birthdate: string
    registeredtime: string
    userid: number
    completed_reservation: number
    finished_reservation: number
  }
  renterid: number
  charges: {
    chargeno: number
    chargeevent: string
    chargetime: string
    chargeid: string
    chargeaccount: string
    chargestate: string
    chargetotal: number
    chargestatus: string
    description: string
  }[]
}

interface VehicleAvailabilityReturnType {
  vehicleId: number
  available: boolean
  changeable: boolean
}

export async function getReservationDetail(
  authContext: IAuthContext,
  reservationNo: number,
): Promise<any> {
  return new Promise((resolve, reject) => {
    const userToken = authContext?.userLoggedIn?.userToken
    axios
      .get(`${restfulService.URL}/partners/host/reservation/${reservationNo}`, {
        headers: {
          authorization: userToken,
        },
      })
      .then(function (response) {
        const { data } = response
        if (data) {
          resolve(data)
        } else {
          reject(data)
        }
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export async function manageRequestReservation(
  authContext: IAuthContext,
  action: RequestAction,
  reservationNo: number | string,
  note: string,
): Promise<any> {
  return new Promise((resolve, reject) => {
    const userToken = authContext?.userLoggedIn?.userToken
    axios
      .post(
        `${restfulService.URL}/partners/host/reservation/${reservationNo}/${action}`,
        { note },
        {
          headers: {
            authorization: userToken,
          },
        },
      )
      .then(function (response) {
        const { result } = response?.data
        if (result) {
          resolve(result)
        } else {
          reject(result)
        }
      })
      .catch(function (error) {
        const { message, name } = error?.response?.data
        reject({ name, message })
      })
  })
}

export async function getHostProfile(authContext: IAuthContext): Promise<any> {
  return new Promise((resolve, reject) => {
    const userToken = authContext?.userLoggedIn?.userToken
    axios
      .get(`${restfulService.URL}/partners/host/dashboard/metric`, {
        headers: {
          authorization: userToken,
        },
      })
      .then(function (response) {
        resolve(response?.data)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export async function getHostVehicle(authContext: IAuthContext): Promise<any> {
  return new Promise((resolve, reject) => {
    const userToken = authContext?.userLoggedIn?.userToken
    axios
      .get(`${restfulService.URL}/partners/host/dashboard/vehicle`, {
        headers: {
          authorization: userToken,
        },
      })
      .then(function (response) {
        resolve(response?.data)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export async function getReservationList(
  authContext: IAuthContext,
  yearMonth: string,
): Promise<any> {
  return new Promise((resolve, reject) => {
    const userToken = authContext?.userLoggedIn?.userToken
    axios
      .get(`${restfulService.URL}/partners/host/dashboard/reservation/${yearMonth}`, {
        headers: {
          authorization: userToken,
        },
      })
      .then(function (response) {
        resolve(response?.data)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export async function getReview(authContext: IAuthContext): Promise<any> {
  return new Promise((resolve, reject) => {
    const userToken = authContext?.userLoggedIn?.userToken
    axios
      .get(`${restfulService.URL}/partners/host/dashboard/review`, {
        headers: {
          authorization: userToken,
        },
        params: {
          limit: 50,
        },
      })
      .then(function (response) {
        resolve(response?.data)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export async function postReplyReview(
  authContext: IAuthContext,
  params: IPostReplyReview,
): Promise<any> {
  return new Promise((resolve, reject) => {
    const userToken = authContext?.userLoggedIn?.userToken
    axios
      .post(`${restfulService.URL}/partners/host/dashboard/reply`, params, {
        headers: {
          authorization: userToken,
        },
      })
      .then(function (response) {
        resolve(response?.data)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export async function getVehicleAvailability(
  authContext: IAuthContext,
  vehicleId?: number,
): Promise<VehicleAvailabilityReturnType[]> {
  return new Promise((resolve, reject) => {
    const userToken = authContext?.userLoggedIn?.userToken
    axios
      .get<VehicleAvailabilityReturnType[]>(
        `${restfulService.URL}/partners/host/dashboard/vehicle/availability`,
        {
          params: {
            vehicleid: vehicleId,
          },
          headers: {
            authorization: userToken,
          },
        },
      )
      .then(function (response) {
        resolve(response?.data)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export async function changeVehicleAvailability(
  auth: IAuthContext,
  vehicleId: number,
  turnOn: boolean,
) {
  const userToken = auth?.userLoggedIn?.userToken
  const response = await axios.post<VehicleAvailabilityReturnType[]>(
    `${restfulService.URL}/partners/host/dashboard/vehicle/availability`,
    {
      vehicleId,
      turnOn,
    },
    {
      headers: {
        authorization: userToken,
      },
    },
  )
  return response.data
}

export async function getAllReservations(
  authContext: IAuthContext,
  params: IGetReservationExtended,
): Promise<IReservationListResponse> {
  return new Promise((resolve, reject) => {
    const userToken = authContext?.userLoggedIn?.userToken
    axios
      .get(`${restfulService.URL}/partners/reservation`, {
        headers: {
          authorization: userToken,
        },
        params,
      })
      .then(function (response: AxiosResponse<IReservationListResponse>) {
        if (!response?.data?.result) {
          throw new Error('Response is invalid')
        }
        resolve(response?.data)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export async function countReservations(
  authContext: IAuthContext,
  params: IGetReservationExtended,
): Promise<number> {
  return new Promise((resolve, reject) => {
    const userToken = authContext?.userLoggedIn?.userToken
    axios
      .get(`${restfulService.URL}/partners/reservation/count`, {
        headers: {
          authorization: userToken,
        },
        params,
      })
      .then(function (response: AxiosResponse<number>) {
        if (isNaN(Number(response?.data))) {
          throw new Error('Response is invalid')
        }
        resolve(response?.data)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export async function getReservationById(
  authContext: IAuthContext,
  id: number,
): Promise<IReservationDetail> {
  return new Promise((resolve, reject) => {
    const userToken = authContext?.userLoggedIn?.userToken
    axios
      .get(`${restfulService.URL}/partners/reservation/${id}`, {
        headers: {
          authorization: userToken,
        },
      })
      .then(function (response: AxiosResponse<IReservationDetail>) {
        resolve(response?.data)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export async function acceptReservation(
  authContext: IAuthContext,
  id: number,
  note?: string,
): Promise<any> {
  return new Promise((resolve, reject) => {
    const userToken = authContext?.userLoggedIn?.userToken
    axios
      .put(
        `${restfulService.URL}/partners/reservation/accept`,
        { reservationNo: id, note },
        {
          headers: {
            authorization: userToken,
          },
        },
      )
      .then(function (response: AxiosResponse<any>) {
        resolve(response?.data)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export async function rejectReservation(
  authContext: IAuthContext,
  id: number,
  note?: string,
): Promise<any> {
  return new Promise((resolve, reject) => {
    const userToken = authContext?.userLoggedIn?.userToken
    axios
      .put(
        `${restfulService.URL}/partners/reservation/reject`,
        { reservationNo: id, note },
        {
          headers: {
            authorization: userToken,
          },
        },
      )
      .then(function (response: AxiosResponse<any>) {
        resolve(response?.data)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}
