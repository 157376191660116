import moment, { Moment } from 'moment'
import qs from 'qs'
import React from 'react'
import { Redirect, RouteComponentProps } from 'react-router'
import i18n from '../../i18n'
import { checkValidToken, tokenLogin } from '../../services/auth'
import loadingIcon from '../../static/img/haup-loading.gif'

const defaultValues = {
  user: {
    userId: null,
    userName: null,
    userToken: null,
  },
  userLoggedIn: false,
}

const AuthContext = React.createContext(defaultValues)

class AuthProvider extends React.Component<RouteComponentProps> {
  _isMounted = false
  readyLogin = false
  state = {
    user: {
      userId: null,
      userName: null,
      userToken: null,
    },
    userLoggedIn: true,
  }

  componentDidMount(): any {
    const query = qs.parse(this.props?.location?.search, { ignoreQueryPrefix: true })

    const { token, lang } = query
    this._isMounted = true

    if (lang && typeof lang === 'string') {
      if (['th', 'en'].includes(lang)) {
        i18n.changeLanguage(lang)
      }
    }

    if (token) {
      tokenLogin(`${token}`)
        .then(async (result) => {
          const { userData, accessToken } = result
          if (accessToken && this._isMounted) {
            await setUserData(userData)
            await setUserToken(accessToken)
            this.readyLogin = true
            this.setState({ userLoggedIn: { userToken: accessToken }, user: userData })
          }
        })
        .catch(() => {
          this.setState({ isFailLoggedIn: true })
        })
    } else {
      checkAuthStatus()
        .then(async (res) => {
          if (!res) {
            await clearSession()
            this.setState({ userLoggedIn: false })
          }
          if (this._isMounted) {
            this.setState({ userLoggedIn: res })
            const userData = await getUserData()
            this.readyLogin = true
            this.setState({ user: userData })
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  render(): JSX.Element {
    const isUserLoggedIn = this.state.userLoggedIn

    return (
      <>
        {!this.readyLogin ? (
          <div
            style={{
              backgroundColor: 'rgb(21, 73, 156)',
              height: '100%',
              width: '100%',
              textAlign: 'center',
              position: 'relative',
            }}
          >
            <img
              src={loadingIcon}
              style={{
                margin: 0,
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                maxHeight: '20vh',
              }}
            ></img>
          </div>
        ) : (
          [
            isUserLoggedIn ? (
              <AuthContext.Provider value={this.state} key="AuthProvider">
                {this.props.children}
              </AuthContext.Provider>
            ) : (
              <Redirect to="/login" key="RedirectLogin" />
            ),
          ]
        )}
      </>
    )
  }
}

function useAuth(): any {
  const context = React.useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useCount must be used within a CountProvider')
  }
  return context
}

export async function setUserToken(userToken: string): Promise<boolean> {
  try {
    await localStorage.setItem('userToken', JSON.stringify(userToken))
    return true
  } catch (error) {
    return false
  }
}

export async function setUserData(userData: any): Promise<boolean> {
  try {
    await localStorage.setItem('userData', JSON.stringify(userData))
    return true
  } catch (error) {
    return false
  }
}

export async function setLogTime(userData: any): Promise<boolean> {
  try {
    await localStorage.setItem('logTime', JSON.stringify(userData))
    return true
  } catch (error) {
    return false
  }
}

export async function getUserData(): Promise<any> {
  try {
    const userData = await localStorage.getItem('userData')
    if (userData) {
      return JSON.parse(userData)
    }
    return {}
  } catch (error) {
    return false
  }
}

export async function getUserToken(): Promise<string | null> {
  const tokenString = (await localStorage.getItem('userToken')) || null
  const userToken = tokenString ? JSON.parse(tokenString) : null
  return userToken
}

export async function getLogTime(): Promise<Moment | null> {
  const logTime = (await localStorage.getItem('logTime')) || null
  if (logTime) {
    return moment(logTime)
  }
  return null
}

export async function checkAuthStatus(): Promise<boolean> {
  const userToken = await getUserToken()
  if (!userToken) {
    return false
  } else {
    // const lastCheck = await getLogTime()
    // const now = moment()
    // if (now.diff(lastCheck, 'minutes') > 60) {

    // }
    const isValidToken = await checkValidToken(userToken)
    return isValidToken
  }
}

export async function clearSession(): Promise<boolean> {
  await localStorage.clear()
  return true
}

export { AuthProvider, useAuth, AuthContext }
