import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Button, Card, Col, Modal, Row, Space } from 'antd'
import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import {
  ControlAction,
  controlVehicle,
  getDriveReservation,
  manageRequestReservation,
  RequestAction,
} from '../../services/reservation'
import { AuthContext } from './../../contexts/Auth'

const GreenButton = styled(Button)`
  background-color: #f6ffed;
  border-color: #b7eb8f;
  color: #389e0d;
  &:hover {
    background-color: #389e0d;
  }
  &:focus {
    background-color: #f6ffed;
  }
`

const GrayButton = styled(Button)`
  background-color: #fafafa;
  border-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.85);
  &:hover {
    background-color: rgba(0, 0, 0, 0.85);
  }
  &:focus {
    background-color: #fafafa;
  }
`

const RedButton = styled(Button)`
  background-color: #fff1f0;
  border-color: #cf1322;
  color: #cf1322;
  &:hover {
    background-color: #cf1322;
  }
  &:focus {
    background-color: #f6ffed;
  }
`

const RightCol = styled(Col)`
  text-align: right;
  word-wrap: break-word;
`

interface IReservationDetail {
  data: any
}

interface IRenderDetailCard {
  key: string
  vehicle: string
  reservationStatus: {
    reserveNo: string
    reserveStatus: string
  }
  reservationTime: {
    reserveStarttime: string
    reserveStoptime: string
  }
  reservationOrigin: {
    reserveOrigin: string
    reserveDestination: string
  }
  reservationType: string
  username: string
  distance: string
  approved: string
}

interface IRenderPriceCard {
  hours: string
  freehours: string
  chargehours: string
  hourrate: string
  dayrate: string
  latehourrate: string
  discount: string
  totalprice: string
  paymenttype: string
}

interface IRenderBundleCard {
  bundlepackage: string
  bundleprice: string
  bundledistance: string
  distancerate: string
}

interface IRenderRemoteCard {
  reservationNo: number
  doorlockstate: string
  enginestate: string
  logtime: string
  vehicleid: number
}

enum CONTROL_ACTION {
  lock = 'lock',
  unlock = 'unlock',
}

function showConfirmBox(reservationNo: number | undefined, authContext: any) {
  console.log('showConfirm')
  const reservationNo2 = reservationNo ? reservationNo : '0'
  const getManageRequestReservation = async (action: RequestAction) => {
    return await manageRequestReservation(authContext, action, reservationNo2).catch((error) => {
      const { name, message } = error
      // openNotification(name, message)
      return false
    })
  }
  Modal.confirm({
    title: `Finish reservation ${reservationNo}?`,
    icon: <ExclamationCircleOutlined />,
    content: '',
    okText: 'Finish',
    okType: 'primary',
    cancelText: 'Cancel',
    closable: true,
    // cancelButtonProps: { danger: true },
    async onOk() {
      console.log('OK trigger')
      // await getManageRequestReservation(RequestAction.accept)
    },
  })
}

function confirmControlBox(
  reservationNo: number | undefined,
  vehicleid: number | undefined,
  action: ControlAction,
  authContext: any,
) {
  const reservationNo2 = reservationNo ? reservationNo : '0'
  const vehicleid2 = vehicleid ? vehicleid : '0'
  const getManageRequestReservation = async () => {
    return await controlVehicle(authContext, action, reservationNo2, vehicleid2).catch((error) => {
      const { name, message } = error
      // openNotification(name, message)
      return false
    })
  }
  Modal.confirm({
    title: `Finish reservation ${reservationNo}?`,
    icon: <ExclamationCircleOutlined />,
    content: '',
    okText: 'Finish',
    okType: 'primary',
    cancelText: 'Cancel',
    closable: true,
    // cancelButtonProps: { danger: true },
    async onOk() {
      console.log('OK trigger')
      // await getManageRequestReservation(RequestAction.accept)
    },
  })
}

const RenderDetailCard: React.FC<{ data: IRenderDetailCard }> = (data) => {
  return (
    <>
      <Card
        title={`#${data.data.reservationStatus.reserveNo}`}
        style={{ width: 370 }}
        extra={<a href="#">Close</a>}
      >
        <Row>
          <Col span={12}>
            <p>Username</p>
          </Col>
          <RightCol span={12}>
            <p>{data.data.username}</p>
          </RightCol>
        </Row>
        <Row>
          <Col span={12} style={{ textAlign: 'left' }}>
            <p>Vehicle</p>
          </Col>
          <RightCol span={12}>
            <p>{data.data.vehicle}</p>
          </RightCol>
        </Row>
        <Row>
          <Col span={12} style={{ textAlign: 'left' }}>
            <p>From</p>
          </Col>
          <RightCol span={12}>
            <p>{data.data.reservationTime.reserveStarttime}</p>
          </RightCol>
        </Row>
        <Row>
          <Col span={12} style={{ textAlign: 'left' }}>
            <p>To</p>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <p>{data.data.reservationTime.reserveStoptime}</p>
          </Col>
        </Row>
        <Row>
          <Col span={12} style={{ textAlign: 'left' }}>
            <p>Origin</p>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <p>{data.data.reservationOrigin.reserveOrigin}</p>
          </Col>
        </Row>
        <Row>
          <Col span={12} style={{ textAlign: 'left' }}>
            <p>Destination</p>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <p>{data.data.reservationOrigin.reserveDestination}</p>
          </Col>
        </Row>
        <Row>
          <Col span={12} style={{ textAlign: 'left' }}>
            <p>Distance</p>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <p>{data.data.distance} km</p>
          </Col>
        </Row>
        <Row>
          <Col span={12} style={{ textAlign: 'left' }}>
            <p>Approved by</p>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <p>{data.data.approved}</p>
          </Col>
        </Row>
      </Card>
    </>
  )
}

const RenderPriceCard: React.FC<{ data: IRenderPriceCard }> = (data) => {
  return (
    <>
      <Card title="Price" style={{ width: 370 }}>
        <Row>
          <Col span={12} style={{ textAlign: 'left' }}>
            <p>Hours</p>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <p>{data.data.hours} hr</p>
          </Col>
        </Row>
        <Row>
          <Col span={12} style={{ textAlign: 'left' }}>
            <p>Free Hours</p>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <p>{data.data.freehours} hr</p>
          </Col>
        </Row>
        <Row>
          <Col span={12} style={{ textAlign: 'left' }}>
            <p>Charge hours</p>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <p>{data.data.chargehours} ฿</p>
          </Col>
        </Row>
        <Row>
          <Col span={12} style={{ textAlign: 'left' }}>
            <p>Hour rate</p>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <p>{data.data.hourrate} ฿/hr</p>
          </Col>
        </Row>
        <Row>
          <Col span={12} style={{ textAlign: 'left' }}>
            <p>Day rate</p>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <p>{data.data.dayrate} ฿/hr</p>
          </Col>
        </Row>
        <Row>
          <Col span={12} style={{ textAlign: 'left' }}>
            <p>Late hour rate</p>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <p>{data.data.latehourrate} ฿/hr</p>
          </Col>
        </Row>
        <Row>
          <Col span={12} style={{ textAlign: 'left' }}>
            <p>Discount</p>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <p>{data.data.discount} ฿</p>
          </Col>
        </Row>
        <Row>
          <Col span={12} style={{ textAlign: 'left' }}>
            <p>Total price</p>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <p>{data.data.totalprice} ฿</p>
          </Col>
        </Row>
        <Row>
          <Col span={12} style={{ textAlign: 'left' }}>
            <p>Payment type</p>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <p>{data.data.paymenttype}</p>
          </Col>
        </Row>
      </Card>
    </>
  )
}

const RenderBundleCard: React.FC<{ data: IRenderBundleCard }> = (data) => {
  return (
    <>
      <Card title="Bundle" style={{ width: 370 }}>
        <Row>
          <Col span={12} style={{ textAlign: 'left' }}>
            <p>Bundle package</p>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <p>{data.data.bundlepackage}</p>
          </Col>
        </Row>
        <Row>
          <Col span={12} style={{ textAlign: 'left' }}>
            <p>Bundle price</p>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <p>{data.data.bundleprice} ฿</p>
          </Col>
        </Row>
        <Row>
          <Col span={12} style={{ textAlign: 'left' }}>
            <p>Bundle distance</p>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <p>{data.data.bundledistance} km</p>
          </Col>
        </Row>
        <Row>
          <Col span={12} style={{ textAlign: 'left' }}>
            <p>Distance rate</p>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <p>{data.data.distancerate} ฿/km</p>
          </Col>
        </Row>
      </Card>
    </>
  )
}

const RenderRemoteCard: React.FC<{ data: IRenderRemoteCard | null }> = (data) => {
  const [visible, setVisible] = useState(false)
  // const [btnDisable, setBtnDisable] = useState(true)
  const authContext = useContext(AuthContext)
  // const reservationNo = data?.data?.reservationNo
  // if (reservationNo) {
  //   setBtnDisable(false)
  // }
  console.log('data data', data?.data)
  return (
    <>
      <Card title="Remote" style={{ width: 370 }}>
        <Row>
          <Col span={12} style={{ textAlign: 'left' }}>
            <p>Door lock</p>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <p>{data?.data?.doorlockstate}</p>
          </Col>
        </Row>
        <Row>
          <Col span={12} style={{ textAlign: 'left' }}>
            <p>Engine</p>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <p>{data?.data?.enginestate}</p>
          </Col>
        </Row>
        <Row>
          <Col span={12} style={{ textAlign: 'left' }}>
            <p>Log time</p>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <p>{data?.data?.logtime}</p>
          </Col>
        </Row>
        <Space>
          <GreenButton
            type="primary"
            onClick={() =>
              confirmControlBox(
                data?.data?.reservationNo,
                data?.data?.vehicleid,
                ControlAction.unlock,
                authContext,
              )
            }
            disabled={data?.data?.reservationNo === undefined}
          >
            Unlock
          </GreenButton>
          <GrayButton
            type="primary"
            onClick={() =>
              confirmControlBox(
                data?.data?.reservationNo,
                data?.data?.vehicleid,
                ControlAction.lock,
                authContext,
              )
            }
            disabled={data?.data?.reservationNo === undefined}
          >
            Lock
          </GrayButton>

          <RedButton
            type="primary"
            onClick={() => showConfirmBox(data?.data?.reservationNo, authContext)}
            disabled={data?.data?.reservationNo === undefined}
          >
            Finish
          </RedButton>
        </Space>
      </Card>
    </>
  )
}

class ReservationDetail extends React.Component<IReservationDetail> {
  static contextType = AuthContext
  state = {
    driveStatus: null,
    currentDrive: 0,
  }
  getDriveReservation = (reservationNo: number) => {
    this.setState({ driveStatus: null })
    getDriveReservation(this.context, reservationNo).then((result) => {
      console.log(result)
      this.setState({
        driveStatus: {
          doorlockstate: result?.doorlockstate,
          enginestate: result?.enginestate,
          logtime: result?.logtime,
          reservationNo: result?.reservationNo,
          vehicleid: this?.props?.data?.vehicleid,
        },
      })
    })
  }
  componentDidMount(): void {
    if (this.props.data.reservationStatus.reserveStatus === 'DRIVE') {
      this.getDriveReservation(this.props.data.reservationStatus.reserveNo)
      this.setState({ currentDrive: this.props.data.reservationStatus.reserveNo })
    }
  }
  componentDidUpdate(): void {
    if (
      this.props.data.reservationStatus.reserveStatus === 'DRIVE' &&
      this.props.data.reservationStatus.reserveNo !== this.state.currentDrive
    ) {
      this.getDriveReservation(this.props.data.reservationStatus.reserveNo)
      this.setState({ currentDrive: this.props.data.reservationStatus.reserveNo })
    }
  }
  render() {
    const reserveStatus = this.props.data.reservationStatus.reserveStatus
    console.log('props data', this.props.data)
    return (
      <>
        {/* <Col style={{ backgroundColor: 'white', overflowY: 'auto' }} span={2}> */}
        <RenderDetailCard data={this.props.data} />
        <RenderPriceCard data={this.props.data} />
        <RenderBundleCard data={this.props.data} />
        {reserveStatus === 'DRIVE' && <RenderRemoteCard data={this.state.driveStatus} />}
        {/* </Col> */}
      </>
    )
  }
}

export default ReservationDetail
