import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { Col, List, Row } from 'antd'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AuthContext } from '../../../contexts/Auth'
import i18n from '../../../i18n'
import { getReservationList } from '../../../services/Host'

const generateDayInMonth = (yearMonth: string) => {
  const daysInMonth = moment(`${yearMonth}`, 'YYYY-MM').daysInMonth()
  const getDayOfWeek = (date: string) => {
    return moment(date)
      .locale(i18n.resolvedLanguage || 'en')
      .format('dd')
      .substr(0, 1)
  }
  const arr = []
  for (let i = 1; i <= daysInMonth; i++) {
    arr.push({ date: i, dow: getDayOfWeek(`${yearMonth}-${i}`), event: [] })
  }
  return arr
}

const mapReservation2 = (reservationList: any[]): any => {
  const date: any = {}
  const res: any = []
  reservationList.map((item) => {
    const { reservestarttime, host_stationname, vehicleModel, licensenumber, vehicleid } = item
    const startHour = moment(reservestarttime).format('HH:mm')
    const dateMonth = moment(reservestarttime).format('YYYY-MM-DD')
    const reservationData = {
      reserveStartTime: startHour,
      stationName: host_stationname,
      vehicleModel,
      vehicleId: vehicleid,
      licenseNumber: licensenumber,
      prepareStatus: 'Not prepared',
    }
    if (dateMonth in date) {
      date[`${dateMonth}`].push(reservationData)
    } else {
      date[`${dateMonth}`] = [reservationData]
    }
  })
  Object.entries(date).map((item) => {
    res.push({ date: item[0], reservations: item[1] })
  })

  return res
}

const EventDot: React.FC<{ color: string }> = ({ color }) => {
  return (
    <div
      style={{
        height: '8px',
        width: '8px',
        backgroundColor: color,
        borderRadius: '50%',
        display: 'inline-block',
      }}
    ></div>
  )
}

const StrapBar: React.FC<{ color?: string }> = ({ color = 'black' }) => {
  return <div style={{ width: '3px', height: '50px', backgroundColor: color }}></div>
}

const ReservationCard: React.FC<{ date: string; reservationList: any; vehicleList: any }> = ({
  date,
  reservationList,
  vehicleList,
}) => {
  const { t, i18n } = useTranslation(['HOST_DASHBOARD', 'COMMON'])
  const formatteddate = moment(date).format('DD MMM YYYY')

  return (
    <List
      itemLayout="horizontal"
      dataSource={reservationList}
      header={<div>{formatteddate}</div>}
      renderItem={(item: any) => {
        const vehicleData = vehicleList.filter(
          (vehicle: any) => vehicle?.vehicleId === item?.vehicleId,
        )
        const cardColor = vehicleData[0]?.vehicleColor

        return (
          <List.Item>
            <List.Item.Meta
              avatar={<StrapBar color={cardColor} />}
              title={
                <p style={{ fontSize: 'smaller' }}>
                  {t('HOST_DASHBOARD:CALENDAR.RESERVATION_CARD.RESERVATION')}
                </p>
              }
              description={
                <>
                  <Row style={{ color: '#445064', fontSize: 'smaller' }}>
                    <Col span={8} style={{ textAlign: 'left' }}>
                      {item.reserveStartTime} {t('COMMON:AT')} {item.stationName}
                    </Col>
                    <Col span={8}>
                      {item.vehicleModel} {item.licenseNumber}
                    </Col>
                    {/* <Col span={8} style={{ textAlign: 'right' }}>
                      {item.prepareStatus}
                    </Col> */}
                  </Row>

                  <hr />
                </>
              }
            />
          </List.Item>
        )
      }}
    />
  )
}

const Calendar: React.FC<{ vehicleList: any[] }> = ({ vehicleList }) => {
  const [currentDateMonth, setCurrentDateMonth] = useState(moment())
  const [dateArray, setDateArray] = useState(generateDayInMonth(currentDateMonth.format('YYYY-MM')))
  const [reservationList, setReservationList] = useState([])
  const auth = useContext(AuthContext)
  useEffect(() => {
    setDateArray(generateDayInMonth(currentDateMonth.format('YYYY-MM')))
    _getReservationList()
    // setTimeout(() => {
    //   refs[24].current.scrollIntoView({ behavior: 'smooth' })
    // }, 3000)
  }, [currentDateMonth])

  const currentMonth = currentDateMonth.format('MMM YYYY')
  const displayCurrentMonth = currentDateMonth
    .locale(i18n.resolvedLanguage || 'en')
    .format('MMM YYYY')
  const _getReservationList = () => {
    getReservationList(auth, currentDateMonth.format('MM-YYYY')).then((result) => {
      const reservations = result?.reservations?.data
      const mappedReservations: any = mapReservation2(reservations)
      setReservationList(mappedReservations)
      const copyDateArray: any = generateDayInMonth(currentDateMonth.format('YYYY-MM'))

      if (dateArray.length > 0) {
        mappedReservations.map((item: any) => {
          if (moment(item?.date).format('MMM YYYY') === currentMonth) {
            item?.reservations.map((reservation: any) => {
              const date = parseInt(`${moment(item?.date).format('D')}`)
              const vehicle = vehicleList.filter((vehicle) => {
                return vehicle?.vehicleId === reservation.vehicleId
              })

              const vehicleColor = vehicle.length > 0 ? vehicle[0].vehicleColor : 'red'
              copyDateArray[date - 1].event.push(vehicleColor)
            })
          }
        })
      }
      setDateArray(copyDateArray)
    })
  }

  const setNextMonth = () => {
    setCurrentDateMonth((prevTime) => prevTime.clone().add(1, 'M'))
    setDateArray(generateDayInMonth(currentDateMonth.format('YYYY-MM')))
  }
  const setPrevMonth = () => {
    setCurrentDateMonth((prevTime) => prevTime.clone().subtract(1, 'M'))
    setDateArray(generateDayInMonth(currentDateMonth.format('YYYY-MM')))
  }
  return (
    <>
      <Row style={{ color: '#2945A0', fontSize: '1.2rem' }}>
        <Col span={8}>
          <a onClick={() => setPrevMonth()}>
            <LeftOutlined style={{ color: '#2945A0' }} />
          </a>
        </Col>
        <Col span={8} style={{ textAlign: 'center' }}>
          {displayCurrentMonth}
        </Col>
        <Col span={8} style={{ textAlign: 'right' }}>
          <a onClick={() => setNextMonth()}>
            <RightOutlined style={{ color: '#2945A0' }} />
          </a>
        </Col>
      </Row>
      <Row className="calendar" style={{ marginTop: '1rem' }}>
        {dateArray.map((item) => (
          <Col
            key={`key_day_${item.date}`}
            span={4}
            id={`day_${item.date}`}
            style={{ textAlign: 'center' }}
          >
            <Row>
              <Col flex={1} style={{ textAlign: 'center' }}>
                {item.dow}
              </Col>
            </Row>
            <Row style={{ marginTop: '1rem' }}>
              <Col flex={1} style={{ textAlign: 'center', color: '#445064', fontSize: '1rem' }}>
                {item.date}
              </Col>
            </Row>
            <Row>
              <Col flex={1} style={{ textAlign: 'center' }}>
                {item.event.map((item2, index) => (
                  <EventDot key={`key_day_${item.date}_event-dot-${index}`} color={item2} />
                ))}
              </Col>
            </Row>
          </Col>
        ))}
      </Row>
      {reservationList.map((item: any) => (
        <ReservationCard
          date={item.date}
          reservationList={item.reservations}
          vehicleList={vehicleList}
        />
      ))}
    </>
  )
}

export default Calendar
