import axios from 'axios'
import config from '../../config'
const { restfulService } = config

interface IFreshchatRestore {
  _id: string
  userId: number
  restoreId: string
  createdAt: string
  updatedAt: string
  __v: number
}

export async function createRestoreId(
  userLoggedIn: any,
  restoreId: string,
): Promise<IFreshchatRestore> {
  try {
    const response = await axios.post(
      `${restfulService.URL}/freshchat/restore`,
      { restoreId },
      { headers: { Authorization: userLoggedIn.userToken } },
    )
    return response.data
  } catch (error: any) {
    if (error?.response?.data?.message) {
      throw error?.response?.data?.message
    }
    throw error
  }
}

export async function getRestoreId(userLoggedIn: any): Promise<IFreshchatRestore | null> {
  try {
    const response = await axios.get(`${restfulService.URL}/freshchat/restore`, {
      headers: { Authorization: userLoggedIn.userToken },
    })
    return response.data
  } catch (error: any) {
    if (error?.response?.data?.message) {
      throw error?.response?.data?.message
    }
    throw error
  }
}
