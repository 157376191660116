export const theme = {
  mainColor: 'black',
  subColor: 'white',
  haupBlue: '#0046A0',
  haupBlueSecondary: '#EBF0F7',
  textPrimary: '#445064',
  textSecondary: '#979798',
}

export type IThemeColor = typeof theme
