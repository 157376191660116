import axios from 'axios'
import config from '../../config'

const { restfulService } = config

interface IAuthContext {
  user: any
  userLoggedIn: any
}

export async function getVehicleList(authContext: IAuthContext, filterSearch: any): Promise<any> {
  return new Promise((resolve, reject) => {
    const userToken = authContext?.userLoggedIn?.userToken
    axios
      .get(`${restfulService.URL}/vehicle`, {
        headers: {
          authorization: userToken,
        },
        params: {
          page: 1,
          stationId: filterSearch.stationId === 'ALL' ? null : filterSearch.stationId,
          search: filterSearch.search,
          vehicleStatus: filterSearch.vehicleStatus === 'ALL' ? null : filterSearch.vehicleStatus,
        },
      })
      .then(function (response) {
        const { data } = response
        if (data) {
          resolve(data)
        } else {
          reject(data)
        }
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export async function getSimpleVehicleList(authContext: IAuthContext): Promise<any> {
  return new Promise((resolve, reject) => {
    const userToken = authContext?.userLoggedIn?.userToken
    axios
      .get(`${restfulService.URL}/vehicle/list`, {
        headers: {
          authorization: userToken,
        },
      })
      .then(function (response) {
        const { data } = response
        if (data) {
          resolve(data)
        } else {
          reject(data)
        }
      })
      .catch(function (error) {
        reject(error)
      })
  })
}
