import { Button, DatePicker, Form, Input, Select, Space } from 'antd'
import React from 'react'
import styled from 'styled-components'

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8 },
}

const onFinish = (values: any) => {
  console.log('Success:', values)
}

const onFinishFailed = (errorInfo: any) => {
  console.log('Failed:', errorInfo)
}

const { Option } = Select

function onChangeSelect(value: string) {
  console.log(`selected ${value}`)
}

function onBlurSelect() {
  console.log('blur')
}

function onFocusSelect() {
  console.log('focus')
}

function onSearchSelect(value: string) {
  console.log('search:', value)
}

const { Search } = Input
const onSearch = (value: any) => console.log(value)

function onChangeDate(date: any, dateString: string) {
  console.log(date, dateString)
}

const StyledButton = styled(Button)`
  background-color: #17a2b8;
  border-color: #17a2b8;
  &:hover {
    background-color: #3aa8ba;
  }
  &:focus {
    background-color: #17a2b8;
  }
`

class NewReserve extends React.Component {
  render() {
    return (
      <>
        <div>
          <Form
            {...layout}
            name="basic"
            layout="horizontal"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="Username"
              name="username"
              rules={[{ required: true, message: 'Please input your username' }]}
              hasFeedback
              validateStatus="success"
            >
              <Search
                placeholder="input search text"
                allowClear
                enterButton="Search"
                size="large"
                onSearch={onSearch}
                style={{ width: 420 }}
              />
            </Form.Item>
            <Form.Item
              name="startTime"
              label="Start Time"
              rules={[{ required: true, message: 'Start time is required' }]}
            >
              <DatePicker onChange={onChangeDate} style={{ width: 420 }} />
            </Form.Item>
            <Form.Item
              name="stopTime"
              label="Stop Time"
              rules={[{ required: true, message: 'Stop time is required' }]}
            >
              <DatePicker onChange={onChangeDate} style={{ width: 420 }} />
            </Form.Item>
            <Form.Item
              name="vehicleCode"
              label="Vehicle"
              rules={[{ required: true, message: 'This field is required' }]}
            >
              <Select
                showSearch
                placeholder="--- Please Select ---"
                style={{ width: 420 }}
                optionFilterProp="children"
                onChange={onChangeSelect}
                onFocus={onFocusSelect}
                onBlur={onBlurSelect}
                onSearch={onSearchSelect}
              >
                <Option value="TEST-01">TEST-01</Option>
                <Option value="TEST-02">TEST-02</Option>
                <Option value="TEST-03">TEST-03</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Price (THB)"
              name="price"
              rules={[{ required: true, message: 'This field is required' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="reservetype"
              label="Reservation Type"
              rules={[{ required: true, message: 'This field is required' }]}
            >
              <Select
                showSearch
                placeholder="--- Please Select ---"
                style={{ width: 420 }}
                optionFilterProp="children"
                onChange={onChangeSelect}
                onFocus={onFocusSelect}
                onBlur={onBlurSelect}
                onSearch={onSearchSelect}
              >
                <Option value="haup">Haup (การจองแบบคิดรายได้)</Option>
                <Option value="kiosk-rental">
                  Kiosk Rental (External rental การจองแบบให้ลูกค้านอกแพลตฟอร์มใช้
                  ไม่คิดส่วนแบ่งรายได้)
                </Option>
                <Option value="mainternance">
                  Maintenance (การจองเพื่อการซ่อมบำรุง ไม่คิดส่วนแบ่งรายได้)
                </Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="paymenttype"
              label="Payment Type"
              rules={[{ required: true, message: 'This field is required' }]}
            >
              <Select
                showSearch
                placeholder="--- Please Select ---"
                style={{ width: 420 }}
                optionFilterProp="children"
                onChange={onChangeSelect}
                onFocus={onFocusSelect}
                onBlur={onBlurSelect}
                onSearch={onSearchSelect}
              >
                <Option value="corporate">Corporate (Request)</Option>
                <Option value="admin-payment">Admin payment</Option>
              </Select>
            </Form.Item>
            <Form.Item name="note" label="Note">
              <Input.TextArea style={{ width: 420 }} />
            </Form.Item>
            <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 4 }}>
              <Space size="large">
                <StyledButton type="primary">Preview Reservation</StyledButton>
                <Button type="primary" htmlType="submit">
                  Confirm Reservation
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </div>
      </>
    )
  }
}

export default NewReserve
