import axios from 'axios'
import config from '../../config'

const { restfulService } = config

interface IAuthContext {
  user: any
  userLoggedIn: any
}

export async function addVehicle(authContext: IAuthContext, data: any): Promise<any> {
  return new Promise((resolve, reject) => {
    const userToken = authContext?.userLoggedIn?.userToken
    axios
      .post(
        `${restfulService.URL}/vehicle/add`,
        { ...data },
        {
          headers: {
            authorization: `${userToken}`,
          },
        },
      )
      .then(function (response) {
        resolve(response?.data)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}
