import axios from 'axios'
import config from '../../config'
import {
  DeletType,
  ReserveForm,
  ReserveFormEdit,
} from '../../containers/BlackoutDate/BlackoutDateType'

const { restfulService } = config

interface IAuthContext {
  user: any
  userLoggedIn: any
}

const HeaderDefault = (headers: any, authContext: IAuthContext) => {
  const opts = {
    headers: {
      authorization: authContext,
    },
  }
  return opts
}

export enum ReservationType {
  FLEET = 'fleet',
  CORPORATE = 'corporate',
}

export const getReservationMonth = (payload: string, authContext: IAuthContext) => {
  const userToken = authContext?.userLoggedIn?.userToken

  return axios({
    url: `${restfulService.URL}/partners/host/blackoutdate/reservationlist?month=${payload}`,
    method: 'GET',
    ...HeaderDefault({}, userToken),
  })
    .then(function (response) {
      const { data, status } = response
      return { data, status }
    })
    .catch((error) => {
      return error.response
    })
}
export const getCarsHost = (authContext: IAuthContext) => {
  const userToken = authContext?.userLoggedIn?.userToken
  return axios({
    url: `${restfulService.URL}/partners/host/blackoutdate/vehicle?hostUserId=${authContext?.user?.userid}`,
    method: 'GET',
    ...HeaderDefault({}, userToken),
  })
    .then(function (response) {
      const { data, status } = response
      return { data, status }
    })
    .catch((error) => {
      return error.response
    })
}
export const GetReserveId = (payload: any, authContext: IAuthContext) => {
  const userToken = authContext?.userLoggedIn?.userToken
  return axios({
    url: `${restfulService.URL}/partners/host/blackoutdate/reservation?reservedNo=${payload.id}`,
    method: 'GET',
    ...HeaderDefault({}, userToken),
  })
    .then(function (response) {
      const { data, status } = response
      return { data, status }
    })
    .catch((error) => {
      return error.response
    })
}
export const createReserve = (payload: ReserveForm, authContext: IAuthContext) => {
  const userToken = authContext?.userLoggedIn?.userToken
  return axios({
    url: `${restfulService.URL}/partners/host/blackoutdate/reserve`,
    method: 'POST',
    data: payload,
    ...HeaderDefault({}, userToken),
  })
    .then(function (response) {
      const { data, status } = response
      return { data, status }
    })
    .catch((error) => {
      return error.response
    })
}
export const editReserve = (payload: ReserveFormEdit, authContext: IAuthContext) => {
  const userToken = authContext?.userLoggedIn?.userToken
  return axios({
    url: `${restfulService.URL}/partners/host/blackoutdate/reserve`,
    method: 'PUT',
    data: payload,
    ...HeaderDefault({}, userToken),
  })
    .then(function (response) {
      const { data, status } = response
      return { data, status }
    })
    .catch((error) => {
      return error.response
    })
}
export const putUndo = (payload: any, authContext: IAuthContext) => {
  const userToken = authContext?.userLoggedIn?.userToken
  return axios({
    url: `${restfulService.URL}/partners/host/blackoutdate/undo`,
    method: 'PUT',
    data: payload,
    ...HeaderDefault({}, userToken),
  })
    .then(function (response) {
      const { data, status } = response
      return { data, status }
    })
    .catch((error) => {
      return error.response
    })
}
export const DeleteReserve = (payload: DeletType, authContext: IAuthContext) => {
  const userToken = authContext?.userLoggedIn?.userToken
  return axios({
    url: `${restfulService.URL}/partners/host/blackoutdate/reserve`,
    method: 'DELETE',
    data: payload,
    ...HeaderDefault({}, userToken),
  })
    .then(function (response) {
      const { data, status } = response
      return { data, status }
    })
    .catch((error) => {
      return error.response
    })
}

export const ClearUndo = (payload: any, authContext: IAuthContext) => {
  const userToken = authContext?.userLoggedIn?.userToken
  return axios({
    url: `${restfulService.URL}/partners/host/blackoutdate/clearundo`,
    method: 'PUT',
    data: payload,
    ...HeaderDefault({}, userToken),
  })
    .then(function (response) {
      const { data, status } = response
      return { data, status }
    })
    .catch((error) => {
      return error.response
    })
}
