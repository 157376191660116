import AddVehicle from '../containers/AdminZone/AddVehicle/index'
import BlackoutDate from '../containers/BlackoutDate'
import FormReservation from '../containers/BlackoutDate/FormReservation'
import HostDashboard from '../containers/Host/Dashboard/index'
import HostReservation from '../containers/Host/Reservation/index'
import HostReservationList from '../containers/Host/ReservationList'
import NewReservation from '../containers/NewReservation'
import AllFleet from '../containers/NewReservation/AllFleet'
import Overview from '../containers/Overview/index'
import Reservation from '../containers/Reservation'
import Vehicle from '../containers/Vehicle'

export type RouteItem = {
  title: string
  path: string
  requireAuth: boolean
  prop?: string
  container: any
  module?: 'host' | 'partners'
}

const routes: RouteItem[] = [
  { title: 'Home', path: '/', requireAuth: true, container: Overview },
  {
    title: 'Fleet Reservation List',
    path: '/reservation/fleet',
    requireAuth: true,
    prop: 'fleet',
    container: Reservation,
  },
  {
    title: 'Corporate Reservation List',
    path: '/reservation/corporate',
    requireAuth: true,
    prop: 'corporate',
    container: Reservation,
  },
  {
    title: 'New Reservation',
    path: '/reservation/new',
    requireAuth: true,
    container: NewReservation,
  },
  {
    title: 'New Reservation',
    path: '/reservation/new/all',
    requireAuth: true,
    container: AllFleet,
  },
  { title: 'Vehicle List', path: '/vehicle', requireAuth: true, container: Vehicle },
  { title: 'Add Vehicle', path: '/vehicle/add', requireAuth: true, container: AddVehicle },
  {
    title: 'Reservation Detail',
    path: '/host/reservation/:reservationNo',
    requireAuth: true,
    module: 'host',
    container: HostReservation,
  },
  {
    title: 'Blackout Date',
    path: '/host/blackoutdate',
    requireAuth: true,
    module: 'host',
    container: BlackoutDate,
  },
  {
    title: 'New Reservation',
    path: '/host/blackoutdate/create',
    requireAuth: true,
    module: 'host',
    container: FormReservation,
  },
  {
    title: 'New Reservation',
    path: '/host/blackoutdate/edit/:id',
    requireAuth: true,
    module: 'host',
    container: FormReservation,
  },
  {
    title: 'Host Dashboard',
    path: '/host/dashboard',
    requireAuth: true,
    module: 'host',
    container: HostDashboard,
  },
  {
    title: 'Host Reservation List',
    path: '/host/dashboard/reservation',
    requireAuth: true,
    module: 'host',
    container: HostReservationList,
  },
]

export default routes
