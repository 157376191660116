import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { Alert, Button, Checkbox, Col, Form, Input, Layout, Row, Space, Typography } from 'antd'
import React, { useState } from 'react'
import { Redirect } from 'react-router'
import { checkAuthStatus, setUserData, setUserToken } from '../../contexts/Auth'
import { getUserToken } from '../../services/auth'
import LogoHaup from '../../static/img/logo2.png'
import Loginbg from './loginbg.jpg'

const { Title } = Typography

const LoginForm = () => {
  const [loggedIn, setLoggedIn] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const onFinish = async (values: any) => {
    const credential = {
      username: values.username,
      password: values.password,
    }
    await getUserToken(credential)
      .then(async (result) => {
        const { accessToken, userData } = result
        await setUserToken(accessToken)
        await setUserData(userData)
        setLoggedIn(true)
      })
      .catch((error) => {
        const errorResponse = error?.response?.data?.message
        if (errorResponse) {
          setErrorMessage(errorResponse)
        } else {
          setErrorMessage(error?.message)
        }
      })
  }

  return (
    <>
      {loggedIn && <Redirect to="/" />}
      <Form
        name="normal_login"
        className="login-form"
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
        <Title level={3}>Please login</Title>
        {errorMessage && (
          <div>
            <Alert message={errorMessage} type="error" />
            <br />
          </div>
        )}
        <Form.Item
          name="username"
          rules={[{ required: true, message: 'Please input your Username!' }]}
        >
          <Input
            size="large"
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Username"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Please input your Password!' }]}
        >
          <Input
            size="large"
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item>
          <Form.Item name="remember" valuePropName="checked" noStyle>
            <Checkbox>Remember me</Checkbox>
          </Form.Item>
        </Form.Item>

        <Form.Item>
          <Button type="primary" size="large" htmlType="submit" className="login-form-button" block>
            Log in
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}

class Login extends React.Component {
  state = {
    loggedIn: false,
  }

  componentDidMount() {
    checkAuthStatus().then((res) => {
      this.setState({ loggedIn: res })
    })
  }

  render(): JSX.Element {
    const isUserLoggedIn = this.state.loggedIn
    return (
      <>
        {isUserLoggedIn && <Redirect to="/" />}
        <Layout
          style={{
            minHeight: '100vh',
            backgroundImage: `url(${Loginbg})`,
            backgroundSize: 'cover',
          }}
        >
          <Row justify="space-around" align="middle" style={{ minHeight: '100vh' }}>
            <Space direction="vertical" style={{ width: '100%' }} size="large">
              <Row style={{ minWidth: '100%' }} justify="center">
                <Col style={{ textAlign: 'center' }}>
                  <img src={LogoHaup} height="40vh" />
                </Col>
              </Row>
              <Row style={{ minWidth: '100%' }} justify="center">
                <Col
                  flex="500px"
                  style={{
                    backgroundColor: 'white',
                    textAlign: 'center',
                    padding: '50px',
                  }}
                >
                  <LoginForm />
                </Col>
              </Row>
              <Row style={{ minWidth: '100%' }} justify="center">
                <Col span={8} style={{ textAlign: 'center', color: 'white' }}>
                  <p>© 2021 Haupcar Company Limited.</p>
                </Col>
              </Row>
            </Space>
          </Row>
        </Layout>
      </>
    )
  }
}

export default Login
