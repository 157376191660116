import { Layout } from 'antd'
import 'antd/dist/antd.less'
import React from 'react'
import { AuthContext } from '../../contexts/Auth'
import '../../static/css/index.less'
import HeaderMenu from '../HeaderMenu/HeaderMenu'
import { ReactComponent as ReservationGrey } from '../SideMenu/reservation_grey.svg'
import SideMenu from '../SideMenu/SideMenu'

const { Content, Footer } = Layout

interface AppProps {
  children: React.ReactNode
}

interface AppState {
  collapsed: boolean
  user: any
}

class Page extends React.Component<AppProps, AppState> {
  static contextType = AuthContext
  state = {
    collapsed: false,
    user: {
      userId: null,
      userName: '',
      userToken: null,
    },
  }
  user = {
    userId: null,
    userName: '',
    userToken: null,
  }

  onCollapse = (collapsed: boolean): void => {
    this.setState({ collapsed })
  }

  render(): JSX.Element {
    const { collapsed } = this.state
    const menus = [
      {
        key: 'reservation',
        title: 'Reservation',
        icon: ReservationGrey,
        subMenu: [
          {
            title: 'Corporate Reservaion',
            key: 'corporateReservation',
            link: '/reservation/corporate',
          },
          { title: 'New Reservaion', key: 'newReservation', link: '/reservation/new/all' },
        ],
        link: '/reservation',
      },
    ]
    // const menus = [
    //   { key: 'index', title: 'Overview', icon: OverviewGrey, link: '/' },
    //   {
    //     key: 'reservation',
    //     title: 'Reservation',
    //     icon: ReservationGrey,
    //     subMenu: [
    //       { title: 'Fleet Reservaion', key: 'fleetReservation', link: '/reservation/fleet' },
    //       {
    //         title: 'Corporate Reservaion',
    //         key: 'corporateReservation',
    //         link: '/reservation/corporate',
    //       },
    //       { title: 'New Reservaion', key: 'newReservation', link: '/reservation/new' },
    //     ],
    //     link: '/reservation',
    //   },
    //   {
    //     key: 'vehicle',
    //     title: 'Vehicle',
    //     icon: FleetGrey,
    //     subMenu: [{ title: 'Add Vehicle', key: 'newVehicle', link: '/vehicle/add' }],
    //     link: '/vehicle',
    //   },
    // ]
    return (
      <Layout style={{ height: '100vh' }}>
        <SideMenu collapsed={collapsed} menus={menus}></SideMenu>
        <Layout className="site-layout" style={{ height: '100vh' }}>
          <HeaderMenu headerText="Fleet console" username={this.context.user.username}></HeaderMenu>
          <Content
            style={{
              margin: '16px',
              // paddingTop: '10px',
              // height: 'calc(100% - 64px)',
              // overflow: 'auto',
            }}
          >
            <div
              className="site-layout-background"
              style={{ padding: 24, minHeight: 360, height: '100%', overflow: 'auto' }}
            >
              {this.props.children}
            </div>
          </Content>
          {/* <Footer style={{ textAlign: 'center' }}>Develop by HAUP</Footer> */}
        </Layout>
      </Layout>
    )
  }
}

export default Page
