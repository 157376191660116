import { Form, Input, Radio, Select } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../../contexts/Auth'
import { getStationList } from '../../../services/station'
import { addVehicle } from '../../../services/vehicle'
import { vehicleList } from './vehicleList'

const { Option } = Select

const vehicleGrade: string[] = [
  '1.2',
  '1.4',
  '1.5',
  '1.6',
  '1.8',
  '2.0',
  '2.2',
  '2.4',
  '2.5',
  '2.6',
]

const colorList: string[] = [
  'white',
  'black',
  'grey',
  'silver',
  'brown',
  'red',
  'green',
  'blue',
  'yellow',
  'lightblue',
]

interface ProvinceObject {
  PROVINCE_ID: number
  PROVINCE_CODE: string
  PROVINCE_NAME: string
  GEO_ID: number
}

const provinceList: ProvinceObject[] = [
  { PROVINCE_ID: 1, PROVINCE_CODE: '10', PROVINCE_NAME: 'กรุงเทพมหานคร', GEO_ID: 2 },
  { PROVINCE_ID: 2, PROVINCE_CODE: '11', PROVINCE_NAME: 'สมุทรปราการ', GEO_ID: 2 },
  { PROVINCE_ID: 3, PROVINCE_CODE: '12', PROVINCE_NAME: 'นนทบุรี', GEO_ID: 2 },
  { PROVINCE_ID: 4, PROVINCE_CODE: '13', PROVINCE_NAME: 'ปทุมธานี', GEO_ID: 2 },
  { PROVINCE_ID: 5, PROVINCE_CODE: '14', PROVINCE_NAME: 'พระนครศรีอยุธยา', GEO_ID: 2 },
  { PROVINCE_ID: 6, PROVINCE_CODE: '15', PROVINCE_NAME: 'อ่างทอง', GEO_ID: 2 },
  { PROVINCE_ID: 7, PROVINCE_CODE: '16', PROVINCE_NAME: 'ลพบุรี', GEO_ID: 2 },
  { PROVINCE_ID: 8, PROVINCE_CODE: '17', PROVINCE_NAME: 'สิงห์บุรี', GEO_ID: 2 },
  { PROVINCE_ID: 9, PROVINCE_CODE: '18', PROVINCE_NAME: 'ชัยนาท', GEO_ID: 2 },
  { PROVINCE_ID: 10, PROVINCE_CODE: '19', PROVINCE_NAME: 'สระบุรี', GEO_ID: 2 },
  { PROVINCE_ID: 11, PROVINCE_CODE: '20', PROVINCE_NAME: 'ชลบุรี', GEO_ID: 5 },
  { PROVINCE_ID: 12, PROVINCE_CODE: '21', PROVINCE_NAME: 'ระยอง', GEO_ID: 5 },
  { PROVINCE_ID: 13, PROVINCE_CODE: '22', PROVINCE_NAME: 'จันทบุรี', GEO_ID: 5 },
  { PROVINCE_ID: 14, PROVINCE_CODE: '23', PROVINCE_NAME: 'ตราด', GEO_ID: 5 },
  { PROVINCE_ID: 15, PROVINCE_CODE: '24', PROVINCE_NAME: 'ฉะเชิงเทรา', GEO_ID: 5 },
  { PROVINCE_ID: 16, PROVINCE_CODE: '25', PROVINCE_NAME: 'ปราจีนบุรี', GEO_ID: 5 },
  { PROVINCE_ID: 17, PROVINCE_CODE: '26', PROVINCE_NAME: 'นครนายก', GEO_ID: 2 },
  { PROVINCE_ID: 18, PROVINCE_CODE: '27', PROVINCE_NAME: 'สระแก้ว', GEO_ID: 5 },
  { PROVINCE_ID: 19, PROVINCE_CODE: '30', PROVINCE_NAME: 'นครราชสีมา', GEO_ID: 3 },
  { PROVINCE_ID: 20, PROVINCE_CODE: '31', PROVINCE_NAME: 'บุรีรัมย์', GEO_ID: 3 },
  { PROVINCE_ID: 21, PROVINCE_CODE: '32', PROVINCE_NAME: 'สุรินทร์', GEO_ID: 3 },
  { PROVINCE_ID: 22, PROVINCE_CODE: '33', PROVINCE_NAME: 'ศรีสะเกษ', GEO_ID: 3 },
  { PROVINCE_ID: 23, PROVINCE_CODE: '34', PROVINCE_NAME: 'อุบลราชธานี', GEO_ID: 3 },
  { PROVINCE_ID: 24, PROVINCE_CODE: '35', PROVINCE_NAME: 'ยโสธร', GEO_ID: 3 },
  { PROVINCE_ID: 25, PROVINCE_CODE: '36', PROVINCE_NAME: 'ชัยภูมิ', GEO_ID: 3 },
  { PROVINCE_ID: 26, PROVINCE_CODE: '37', PROVINCE_NAME: 'อำนาจเจริญ', GEO_ID: 3 },
  { PROVINCE_ID: 27, PROVINCE_CODE: '39', PROVINCE_NAME: 'หนองบัวลำภู', GEO_ID: 3 },
  { PROVINCE_ID: 28, PROVINCE_CODE: '40', PROVINCE_NAME: 'ขอนแก่น', GEO_ID: 3 },
  { PROVINCE_ID: 29, PROVINCE_CODE: '41', PROVINCE_NAME: 'อุดรธานี', GEO_ID: 3 },
  { PROVINCE_ID: 30, PROVINCE_CODE: '42', PROVINCE_NAME: 'เลย', GEO_ID: 3 },
  { PROVINCE_ID: 31, PROVINCE_CODE: '43', PROVINCE_NAME: 'หนองคาย', GEO_ID: 3 },
  { PROVINCE_ID: 32, PROVINCE_CODE: '44', PROVINCE_NAME: 'มหาสารคาม', GEO_ID: 3 },
  { PROVINCE_ID: 33, PROVINCE_CODE: '45', PROVINCE_NAME: 'ร้อยเอ็ด', GEO_ID: 3 },
  { PROVINCE_ID: 34, PROVINCE_CODE: '46', PROVINCE_NAME: 'กาฬสินธุ์', GEO_ID: 3 },
  { PROVINCE_ID: 35, PROVINCE_CODE: '47', PROVINCE_NAME: 'สกลนคร', GEO_ID: 3 },
  { PROVINCE_ID: 36, PROVINCE_CODE: '48', PROVINCE_NAME: 'นครพนม', GEO_ID: 3 },
  { PROVINCE_ID: 37, PROVINCE_CODE: '49', PROVINCE_NAME: 'มุกดาหาร', GEO_ID: 3 },
  { PROVINCE_ID: 38, PROVINCE_CODE: '50', PROVINCE_NAME: 'เชียงใหม่', GEO_ID: 1 },
  { PROVINCE_ID: 39, PROVINCE_CODE: '51', PROVINCE_NAME: 'ลำพูน', GEO_ID: 1 },
  { PROVINCE_ID: 40, PROVINCE_CODE: '52', PROVINCE_NAME: 'ลำปาง', GEO_ID: 1 },
  { PROVINCE_ID: 41, PROVINCE_CODE: '53', PROVINCE_NAME: 'อุตรดิตถ์', GEO_ID: 1 },
  { PROVINCE_ID: 42, PROVINCE_CODE: '54', PROVINCE_NAME: 'แพร่', GEO_ID: 1 },
  { PROVINCE_ID: 43, PROVINCE_CODE: '55', PROVINCE_NAME: 'น่าน', GEO_ID: 1 },
  { PROVINCE_ID: 44, PROVINCE_CODE: '56', PROVINCE_NAME: 'พะเยา', GEO_ID: 1 },
  { PROVINCE_ID: 45, PROVINCE_CODE: '57', PROVINCE_NAME: 'เชียงราย', GEO_ID: 1 },
  { PROVINCE_ID: 46, PROVINCE_CODE: '58', PROVINCE_NAME: 'แม่ฮ่องสอน', GEO_ID: 1 },
  { PROVINCE_ID: 47, PROVINCE_CODE: '60', PROVINCE_NAME: 'นครสวรรค์', GEO_ID: 2 },
  { PROVINCE_ID: 48, PROVINCE_CODE: '61', PROVINCE_NAME: 'อุทัยธานี', GEO_ID: 2 },
  { PROVINCE_ID: 49, PROVINCE_CODE: '62', PROVINCE_NAME: 'กำแพงเพชร', GEO_ID: 2 },
  { PROVINCE_ID: 50, PROVINCE_CODE: '63', PROVINCE_NAME: 'ตาก', GEO_ID: 4 },
  { PROVINCE_ID: 51, PROVINCE_CODE: '64', PROVINCE_NAME: 'สุโขทัย', GEO_ID: 2 },
  { PROVINCE_ID: 52, PROVINCE_CODE: '65', PROVINCE_NAME: 'พิษณุโลก', GEO_ID: 2 },
  { PROVINCE_ID: 53, PROVINCE_CODE: '66', PROVINCE_NAME: 'พิจิตร', GEO_ID: 2 },
  { PROVINCE_ID: 54, PROVINCE_CODE: '67', PROVINCE_NAME: 'เพชรบูรณ์', GEO_ID: 2 },
  { PROVINCE_ID: 55, PROVINCE_CODE: '70', PROVINCE_NAME: 'ราชบุรี', GEO_ID: 4 },
  { PROVINCE_ID: 56, PROVINCE_CODE: '71', PROVINCE_NAME: 'กาญจนบุรี', GEO_ID: 4 },
  { PROVINCE_ID: 57, PROVINCE_CODE: '72', PROVINCE_NAME: 'สุพรรณบุรี', GEO_ID: 2 },
  { PROVINCE_ID: 58, PROVINCE_CODE: '73', PROVINCE_NAME: 'นครปฐม', GEO_ID: 2 },
  { PROVINCE_ID: 59, PROVINCE_CODE: '74', PROVINCE_NAME: 'สมุทรสาคร', GEO_ID: 2 },
  { PROVINCE_ID: 60, PROVINCE_CODE: '75', PROVINCE_NAME: 'สมุทรสงคราม', GEO_ID: 2 },
  { PROVINCE_ID: 61, PROVINCE_CODE: '76', PROVINCE_NAME: 'เพชรบุรี', GEO_ID: 4 },
  { PROVINCE_ID: 62, PROVINCE_CODE: '77', PROVINCE_NAME: 'ประจวบคีรีขันธ์', GEO_ID: 4 },
  { PROVINCE_ID: 63, PROVINCE_CODE: '80', PROVINCE_NAME: 'นครศรีธรรมราช', GEO_ID: 6 },
  { PROVINCE_ID: 64, PROVINCE_CODE: '81', PROVINCE_NAME: 'กระบี่', GEO_ID: 6 },
  { PROVINCE_ID: 65, PROVINCE_CODE: '82', PROVINCE_NAME: 'พังงา', GEO_ID: 6 },
  { PROVINCE_ID: 66, PROVINCE_CODE: '83', PROVINCE_NAME: 'ภูเก็ต', GEO_ID: 6 },
  { PROVINCE_ID: 67, PROVINCE_CODE: '84', PROVINCE_NAME: 'สุราษฎร์ธานี', GEO_ID: 6 },
  { PROVINCE_ID: 68, PROVINCE_CODE: '85', PROVINCE_NAME: 'ระนอง', GEO_ID: 6 },
  { PROVINCE_ID: 69, PROVINCE_CODE: '86', PROVINCE_NAME: 'ชุมพร', GEO_ID: 6 },
  { PROVINCE_ID: 70, PROVINCE_CODE: '90', PROVINCE_NAME: 'สงขลา', GEO_ID: 6 },
  { PROVINCE_ID: 71, PROVINCE_CODE: '91', PROVINCE_NAME: 'สตูล', GEO_ID: 6 },
  { PROVINCE_ID: 72, PROVINCE_CODE: '92', PROVINCE_NAME: 'ตรัง', GEO_ID: 6 },
  { PROVINCE_ID: 73, PROVINCE_CODE: '93', PROVINCE_NAME: 'พัทลุง', GEO_ID: 6 },
  { PROVINCE_ID: 74, PROVINCE_CODE: '94', PROVINCE_NAME: 'ปัตตานี', GEO_ID: 6 },
  { PROVINCE_ID: 75, PROVINCE_CODE: '95', PROVINCE_NAME: 'ยะลา', GEO_ID: 6 },
  { PROVINCE_ID: 76, PROVINCE_CODE: '96', PROVINCE_NAME: 'นราธิวาส', GEO_ID: 6 },
  { PROVINCE_ID: 77, PROVINCE_CODE: '97', PROVINCE_NAME: 'บึงกาฬ', GEO_ID: 3 },
]
const VehicleTypes = [
  'CAR',
  'EV',
  'MOTO',
  'SUV',
  'VAN',
  'SEDAN',
  'HATCHBACK',
  'MOTOHYBRID',
  'EVTAXI',
  'EVTUKTUK',
  'EVSCOOTER',
  'P2P',
  'PICKUP',
]

interface IStationList {
  stationid: number
  stationcode: string
}

function AddVehicle(): JSX.Element {
  const [isPreviewed, setPreviewed] = useState(false)
  const [stationList, setStationList] = useState<IStationList[]>([])
  const [isLoadStation, setIsLoadStation] = useState(true)
  const authContext = useContext(AuthContext)
  const [fleetOwner] = useState([
    { name: 'Chic Car Rent', id: 202665 },
    { name: 'Drive Car Rental', id: 8269 },
    { name: 'Budget Car Rent', id: 192125 },
    { name: 'Hertz Car Rent', id: 255113 },
    { name: 'Banpu Next', id: 255117 },
    { name: 'HAUP', id: 0 },
    { name: 'CUSTOM', id: '' },
  ])

  const [form] = Form.useForm()
  const onFinish = (value: any) => {
    delete value.fleetOwnerId
    if (isPreviewed) {
      // alert('Submitted')
      console.log('form submitted : ', value)
      console.log('AuthContext:', authContext)
      addVehicle(authContext, value)
        .then((result) => {
          alert(`Insert vehicle success, vehicleId: ${result?.vehicleId}`)
          setPreviewed(false)
        })
        .catch((error) => {
          console.log('ERROR:', error)
          alert(`[ERROR] Can't insert vehicle, ${error}`)
        })
      return
    }
    // alert('Previewed')
    setPreviewed(true)
    console.log('previewed : ', value)
  }

  useEffect(() => {
    if (isLoadStation) {
      getStationList(authContext)
        .then((result) => {
          console.log('stationList:', result)
          setStationList([...result.data])
        })
        .then(() => {
          setIsLoadStation(false)
        })
    }
  }, [])

  return (
    <>
      <Form
        name="basic"
        layout="vertical"
        onChange={() => setPreviewed(false)}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        form={form}
      >
        <div className="grid-form">
          <div className="input-group">
            <Form.Item label="Available fleet" name="fleetOwnerId">
              <Select
                id="fleetOwnerId"
                showSearch
                optionFilterProp="children"
                onChange={(e) => {
                  form.setFieldsValue({ ownerUserId: e })
                  setPreviewed(false)
                }}
              >
                {fleetOwner.map((option) => (
                  <Option key={option.id} value={option.id}>
                    {option.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div className="input-group">
            <Form.Item
              label="Owner User ID"
              name="ownerUserId"
              rules={[{ required: true, message: 'This field is required!' }]}
            >
              <Input
                type="number"
                id="ownerUserId"
                onChange={(e) => form.setFieldsValue({ fleetOwnerId: '' })}
              />
            </Form.Item>
          </div>
          <div className="input-group input-group--right">
            <Form.Item
              label="Vehicle Code"
              name="vehicleCode"
              rules={[{ required: true, message: 'This field is required!' }]}
            >
              <Input type="text" id="vehicleCode" />
            </Form.Item>
          </div>

          <div className="input-group input-group--left">
            <Form.Item
              label="Station"
              name="stationId"
              rules={[{ required: true, message: 'This field is required!' }]}
            >
              <Select id="stationId" showSearch optionFilterProp="children">
                {!isLoadStation &&
                  stationList.map((option) => (
                    <Option key={option.stationid} value={option.stationid}>
                      {option.stationcode}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </div>
          <div className="input-group">
            <Form.Item
              label="Group ID"
              name="groupId"
              rules={[{ required: true, message: 'This field is required!' }]}
            >
              <Input type="number" min="1" id="groupId" />
            </Form.Item>
          </div>
          <div className="input-group">
            <Form.Item
              label="Service Type"
              name="vehicleSystem"
              rules={[{ required: true, message: 'This field is required!' }]}
            >
              <Radio.Group
                // defaultValue="auto"
                style={{ width: '100%' }}
                buttonStyle="solid"
              >
                <Radio.Button value="MANUAL">Car Rental</Radio.Button>
                <Radio.Button value="HAUP">Carsharing</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </div>
          <div className="input-group">
            <Form.Item
              label="Brand"
              name="vehicleBrand"
              rules={[{ required: true, message: 'This field is required!' }]}
            >
              <Select id="vehicleBrand" showSearch>
                {vehicleList.map((vehicle) => (
                  <Option key={vehicle.name} value={vehicle.name}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <span>{vehicle.name}</span>
                      <img src={vehicle.logo} height="25px" style={{ marginLeft: 'auto' }} />
                    </div>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div className="input-group">
            <Form.Item
              label="Type"
              name="vehicleType"
              rules={[{ required: true, message: 'This field is required!' }]}
            >
              <Select id="vehicleType" showSearch optionFilterProp="children">
                {VehicleTypes.map((option) => (
                  <Option key={option} value={option}>
                    {option}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div className="input-group input-group--right">
            <Form.Item
              label="Model"
              name="vehicleModel"
              rules={[{ required: true, message: 'This field is required!' }]}
            >
              <Input id="vehicleModel" />
            </Form.Item>
          </div>
          <div className="input-group">
            <Form.Item
              label="Vehicle Grade (cc)"
              name="vehicleGrade"
              rules={[{ required: true, message: 'This field is required!' }]}
            >
              <Select id="vehicleGrade" showSearch optionFilterProp="children">
                {vehicleGrade.map((option) => (
                  <Option key={option} value={option}>
                    {option}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div className="input-group">
            <Form.Item
              label="Transmission Type"
              name="transmissionType"
              rules={[{ required: true, message: 'This field is required!' }]}
            >
              <Radio.Group
                // defaultValue="auto"
                style={{ width: '100%' }}
                buttonStyle="solid"
              >
                <Radio.Button value="MANUAL">Manual</Radio.Button>
                <Radio.Button value="AUTO">Automatic</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </div>
          <div className="input-group">
            <Form.Item
              label="Seats ex. 4 , 4-5"
              name="vehicleSeat"
              rules={[{ required: true, message: 'This field is required!' }]}
            >
              <Input type="tel" id="vehicleSeat" pattern="[\d]{0,2}?-?[\d]{0,2}?" />
            </Form.Item>
          </div>
          <div className="input-group">
            <Form.Item
              label="Year"
              name="vehicleYear"
              rules={[{ required: true, message: 'This field is required!' }]}
            >
              <Input type="number" min="1990" max={new Date().getFullYear()} id="vehicleYear" />
            </Form.Item>
          </div>
          <div className="input-group">
            <Form.Item
              label="License Number"
              name="licenseNumber"
              rules={[{ required: true, message: 'This field is required!' }]}
            >
              <Input id="licenseNumber" />
            </Form.Item>
          </div>
          <div className="input-group">
            <Form.Item
              label="Province"
              name="licenseProvince"
              rules={[{ required: true, message: 'This field is required!' }]}
            >
              <Select id="licenseProvince" showSearch optionFilterProp="children">
                {provinceList.map((province) => (
                  <Option key={province.PROVINCE_ID} value={province.PROVINCE_NAME}>
                    {province.PROVINCE_NAME}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div className="input-group input-group--right">
            <Form.Item
              label="Color"
              name="vehicleColor"
              rules={[{ required: false, message: 'This field is required!' }]}
              initialValue="WHITE"
            >
              <div className="color-picker">
                {colorList.map((color) => (
                  <input type="radio" name="vehicleColor" id={color} value={color} key={color} />
                ))}
                {colorList.map((color) => (
                  <label key={color} htmlFor={color} className={`color color--${color}`}></label>
                ))}
              </div>
            </Form.Item>
          </div>
        </div>
        {/* <h4>Vehicle Code Preview</h4>
        <p>FleetOwner - VehicleModel - Station - Number</p>
        {state.fleetOwner && state.vehicleModel && state.station && (
          <p>
            {state.fleetOwner}-{state.vehicleModel}-{state.station}-01
          </p>
        )} */}
        <button type="reset" className="btn btn--secondary">
          clear
        </button>
        {isPreviewed ? (
          <>
            <button
              type="submit"
              className="btn btn--light ml-small"
              disabled
              style={{ cursor: 'not-allowed' }}
            >
              preview
            </button>
            <button type="submit" className="btn btn--primary ml-small">
              submit
            </button>
          </>
        ) : (
          <>
            <button type="submit" className="btn btn--primary ml-small">
              preview
            </button>
            <button
              type="submit"
              className="btn btn--light ml-small"
              disabled
              style={{ cursor: 'not-allowed' }}
            >
              submit
            </button>
          </>
        )}
      </Form>
    </>
  )
}

export default AddVehicle
