import { CSSProperties, useEffect, useRef, useState } from 'react'

interface Props {
  imgSrc: string
  ratio: [number, number]
  imgStyle?: Omit<CSSProperties, 'height'>
}
export const AspectRatioImage: React.FC<Props> = ({ imgSrc, ratio, imgStyle }): JSX.Element => {
  const [ratioWidth, ratioHeight] = ratio
  const imgRef = useRef<HTMLImageElement>(null)
  const [calculatedHeight, setCalculatedHeight] = useState<number>()
  useEffect(() => {
    if (imgRef?.current?.offsetWidth && ratio) {
      const height = (imgRef?.current?.offsetWidth / ratioWidth) * ratioHeight
      setCalculatedHeight(height)
    } else {
      setTimeout(() => {
        if (imgRef?.current?.offsetWidth && ratio) {
          const height = (imgRef?.current?.offsetWidth / ratioWidth) * ratioHeight
          setCalculatedHeight(height)
        }
      }, 200)
    }
  }, [imgRef?.current?.offsetWidth, ratio])

  return (
    <img
      ref={imgRef}
      src={imgSrc}
      width="100%"
      style={{
        height: calculatedHeight,
        objectFit: 'cover',
        maxWidth: '100vw',
        maxHeight: '100vh',
        ...imgStyle,
      }}
    />
  )
}
