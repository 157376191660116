import { useEffect } from 'react'
import config from '../../config'
import { useAuth } from '../../contexts/Auth'
import * as freshchatService from '../../services/freshchat'

const FRESHCHAT_TOKEN = config?.credentials?.freshchat?.TOKEN || ''

const freshchatInitParams = {
  token: FRESHCHAT_TOKEN,
  host: 'https://wchat.freshchat.com',
}
const freshchatConfigParams = {
  config: {
    headerProperty: {
      //If you have multiple sites you can use the appName and appLogo to overwrite the values.
      appName: 'Haup',
      appLogo: 'https://static.wixstatic.com/media/622723_9818e7906eec4b84b2474c0b9496adc3~mv2.png',
      backgroundColor: '#0045a0',
      foregroundColor: '#ffffff',
      hideChatButton: true,
    },
  },
}

export function openFcWidget() {
  const w: any = window
  if (w?.fcWidget) {
    if (w.fcWidget?.isOpen() !== true) {
      w.fcWidget.open()
    }
  }
}

interface FreshChatModuleProps {
  children: React.ReactNode
}

const FreshChatModule = ({ children }: FreshChatModuleProps): JSX.Element => {
  const { user, userLoggedIn } = useAuth() as { user: any; userLoggedIn: any }
  const w: any = window

  useEffect(() => {
    if (!userLoggedIn?.userId) {
      w.fcWidget.init({
        ...freshchatInitParams,
        ...freshchatConfigParams,
      })
      return
    }
    freshchatService.getRestoreId(userLoggedIn).then((restoreData) => {
      if (!w.fcWidget) {
        return
      }
      const userInfo = {
        externalId: userLoggedIn.userId,
        restoreId: restoreData?.restoreId,
        firstName: user.name ? user.name : user.username,
        lastName: user.lastname,
      }

      w.fcWidget.init({
        ...freshchatInitParams,
        ...freshchatConfigParams,
        ...userInfo,
      })

      w.fcWidget.on('user:created', function (resp: any) {
        const status = resp && resp.status
        const data = resp && resp.data
        if (status === 200) {
          if (data.restoreId) {
            freshchatService.createRestoreId(userLoggedIn, data.restoreId)
          }
        }
      })
    })
  }, [userLoggedIn?.userId])

  return <>{children}</>
}

FreshChatModule.openWidget = openFcWidget

export default FreshChatModule
