import { CheckCircleOutlined, CloseOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import {
  Affix,
  Button,
  Col,
  Divider as AntdDivider,
  Image,
  Input,
  Modal,
  Row,
  Space,
  Tag,
} from 'antd'
import moment, { isMoment } from 'moment'
import React, { ReactNode, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { theme } from '../../../config/style'
import { ReservationStatus } from '../../../containers/Host/ReservationList'
import i18n from '../../../i18n'
import carPlaceholder from '../../../static/img/placeholder-car.png'

const RightArrowSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path d="M512 256c0-141.4-114.6-256-256-256S0 114.6 0 256c0 141.4 114.6 256 256 256S512 397.4 512 256zM265.9 382.8C259.9 380.3 256 374.5 256 368v-64H160c-17.67 0-32-14.33-32-32v-32c0-17.67 14.33-32 32-32h96v-64c0-6.469 3.891-12.31 9.875-14.78c5.984-2.484 12.86-1.109 17.44 3.469l112 112c6.248 6.248 6.248 16.38 0 22.62l-112 112C278.7 383.9 271.9 385.3 265.9 382.8z" />
  </svg>
)
export const NoticeBox = styled.div`
  border: 1px solid ${theme.haupBlue};
  padding: 1rem;
  margin: 1rem 0;
  text-align: center;
  color: ${theme.haupBlue};
  font-weight: normal;
  border-radius: 15px;
`
export const CustomTag = styled(Tag)`
  border-radius: 5px;
  border: 0;
  padding: 2px 12px;
`
export const borderColor = '#e6e6e6'

export const Divider = styled(AntdDivider)`
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  margin: 8px 0;
  ${(props) =>
    props.dashed &&
    `
    background: none;
    border-color: rgba(0, 0, 0, 0.06);
    border-style: dashed;
    border-width: 1px 0 0;
  `}
`
export const StyledSelect = styled.select`
  border-radius: 5px;
  padding: 1rem;
  background: transparent;
  color: ${theme.haupBlue};
  border: 1px solid ${theme.haupBlue};
`
export const StyledInput = styled(Input)`
  padding: 0.75rem;
`
export const StyledButton = styled(Button)`
  ${(props) => props.type === 'primary' && `background: ${theme.haupBlue};border: 0;`}
`
export const TextFramed = styled.span`
  font-size: xx-small;
  padding: 7px;
  font-weight: medium;
  background: #ebf0f7;
  color: ${theme.haupBlue};
`
export const colorHaupBlue: React.CSSProperties = { color: theme.haupBlue }
export const colorTextPrimary: React.CSSProperties = { color: theme.textPrimary }
export const fontLarge: React.CSSProperties = { fontSize: 'large' }
export const fontLarger: React.CSSProperties = { fontSize: 'larger' }
export const fontSmaller: React.CSSProperties = { fontSize: 'smaller' }
export const fontLighter: React.CSSProperties = { fontWeight: 'lighter' }
export const fontLight: React.CSSProperties = { fontWeight: 300 }
export const fontBolder: React.CSSProperties = { fontWeight: 'bolder' }
export const floatRight: React.CSSProperties = { float: 'right' }
export const linkStyle: React.CSSProperties = {
  color: theme.haupBlue,
  textDecoration: 'underline',
  fontWeight: 400,
}
export const hexCloudWhite = '#fafafa'
export const hexSmokedWhite = '#f7f7f7'
export const DividerDiv = styled.div`
  margin-top: 8px;
`
export const PaddedDiv = styled.div`
  padding: 1rem;
  background-color: ${hexCloudWhite};
`
export function format(date?: string | Date | moment.Moment): string {
  if (!date) {
    return ''
  }
  if (isMoment(date)) {
    return date.locale(i18n.resolvedLanguage).format('ddd DD MMMM, YYYY HH:mm')
  }
  return moment(date).locale(i18n.resolvedLanguage).format('ddd DD MMMM, YYYY HH:mm')
}

interface AcceptancePanelProps {
  onRef?: (ref: HTMLDivElement | null) => any
  onRejectClick?: () => any
  onConfirmClick?: () => any
  title?: string | ReactNode
}
export const AcceptancePanel = ({
  onRef,
  onRejectClick,
  onConfirmClick,
  title,
}: AcceptancePanelProps) => {
  const { t } = useTranslation(['COMMON', 'RESERVATION_DETAIL'])
  return (
    <Affix offsetBottom={0}>
      <div
        style={{
          color: theme.textPrimary,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: '#fafafa',
          borderTop: `1px solid ${borderColor}`,
        }}
        ref={onRef ? (ref) => onRef(ref) : undefined}
        className="pad-content"
      >
        <div style={{ textAlign: 'center' }}>
          <p style={{ ...colorHaupBlue, ...fontSmaller }}>{title}</p>
          <Row gutter={8} style={{ marginTop: '1rem' }}>
            <Col span={12}>
              <Button
                shape="round"
                block
                style={{ border: `1px solid ${theme.haupBlue}`, color: theme.haupBlue }}
                onClick={onRejectClick}
              >
                {t('COMMON:DECLINE')}
              </Button>
            </Col>
            <Col span={12}>
              <Button
                shape="round"
                block
                type="primary"
                style={{ background: theme.haupBlue, border: '0' }}
                onClick={onConfirmClick}
              >
                {t('COMMON:CONFIRM')}
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </Affix>
  )
}

interface RejectPanelProps {
  onCloseButtonClick?: () => any
  onRejectConfirmClick?: (reason: string) => any
}
export const RejectPanel = ({ onCloseButtonClick, onRejectConfirmClick }: RejectPanelProps) => {
  const { t } = useTranslation(['COMMON', 'RESERVATION_DETAIL'])
  const [page, setPage] = useState<0 | 1>(0)
  const [selectedReason, setSelectedReason] = useState<string>()
  const [reason, setReason] = useState<string>()
  const rejectReason = useMemo(() => `${selectedReason}: ${reason}`, [selectedReason, reason])
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        background: '#00000040',
        zIndex: 100,
      }}
    >
      <div
        style={{
          textAlign: 'center',
          position: 'absolute',
          background: '#fff',
          bottom: 0,
          padding: '1rem',
          width: '100%',
          borderRadius: '15px 15px 0 0',
        }}
      >
        <div style={{ textAlign: 'right' }}>
          <CloseOutlined style={{ marginLeft: 'auto' }} onClick={onCloseButtonClick} />
        </div>
        <div style={{ maxWidth: '400px', margin: '0 auto 3rem auto' }}>
          {page === 0 ? (
            <>
              <Space direction="vertical">
                <p style={{ ...fontLarger, ...colorHaupBlue }}>
                  {t('RESERVATION_DETAIL:REJECT.PLEASE_PROVIDE_REASON_TEXT')}
                </p>
                <StyledSelect
                  style={{ width: '100%' }}
                  placeholder={t('RESERVATION_DETAIL:REJECT.PLEASE_SELECT_REASON')}
                  onChange={(e) => setSelectedReason(e.target.value)}
                  value={selectedReason}
                >
                  <optgroup label={t('RESERVATION_DETAIL:REJECT.PLEASE_PROVIDE_REASON_TEXT')} />
                  <option value={undefined} selected hidden>
                    {t('RESERVATION_DETAIL:REJECT.PLEASE_PROVIDE_REASON_TEXT')}
                  </option>
                  <option value={'REASON_INEEDACAR'}>
                    {t('RESERVATION_DETAIL:REJECT.REASON_INEEDACAR')}
                  </option>
                  <option value={`REASON_IDONTTRUST`}>
                    {t('RESERVATION_DETAIL:REJECT.REASON_IDONTTRUST')}
                  </option>
                  <option value={'REASON_OTHER'}>
                    {t('RESERVATION_DETAIL:REJECT.REASON_OTHER')}
                  </option>
                </StyledSelect>
                <StyledInput
                  placeholder={t('RESERVATION_DETAIL:REJECT.REASON_NOTE')}
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                />
                <StyledButton
                  type="primary"
                  shape="round"
                  disabled={selectedReason ? false : true}
                  block
                  onClick={() => setPage(1)}
                >
                  {t('COMMON:NEXT')}
                </StyledButton>
              </Space>
            </>
          ) : (
            <Space direction="vertical" className="pad-content">
              <p style={{ ...fontLarger, ...colorHaupBlue }}>
                {t('RESERVATION_DETAIL:REJECT.REJECT_CONFIRM_PROMPT')}
              </p>
              <p style={{ textAlign: 'left' }}>
                {t('RESERVATION_DETAIL:REJECT.REJECT_CONFIRM_PROMPT_HELPTEXT')}
              </p>
              <Row gutter={8}>
                <Col span={12}>
                  <StyledButton shape="round" block type="default" onClick={() => setPage(0)}>
                    {t('COMMON:CANCEL')}
                  </StyledButton>
                </Col>
                <Col span={12}>
                  <StyledButton
                    shape="round"
                    block
                    type="primary"
                    onClick={
                      onRejectConfirmClick ? () => onRejectConfirmClick(rejectReason) : undefined
                    }
                  >
                    {t('COMMON:CONFIRM')}
                  </StyledButton>
                </Col>
              </Row>
            </Space>
          )}
        </div>
      </div>
    </div>
  )
}

interface LRProps {
  left?: React.ReactNode
  right?: React.ReactNode
  containerStyle?: React.CSSProperties
  leftStyle?: React.CSSProperties
  rightStyle?: React.CSSProperties
}
export const LR = ({ left, right, containerStyle, leftStyle, rightStyle }: LRProps) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      ...containerStyle,
    }}
  >
    <span style={{ maxWidth: '50%', ...leftStyle }}>{left}</span>
    <span style={{ ...rightStyle }}>{right}</span>
  </div>
)

export function popConfirmedModal(delay = 60000): Promise<boolean> {
  return new Promise((resolve) => {
    const ModalContent = () => {
      const { t } = useTranslation(['COMMON', 'RESERVATION_DETAIL'])
      return (
        <div style={{ textAlign: 'center' }}>
          <Space direction="vertical">
            <CheckCircleOutlined style={{ ...colorHaupBlue, fontSize: '3rem' }} />
            <p style={{ ...fontLarger, ...colorHaupBlue }}>
              {t('RESERVATION_DETAIL:CONFIRMED_MODAL.A')}
            </p>
            <p style={{ ...colorTextPrimary }}>{t('RESERVATION_DETAIL:CONFIRMED_MODAL.B')}</p>
            <p style={{ ...colorTextPrimary }}>
              {t('RESERVATION_DETAIL:CONFIRMED_MODAL.C')}{' '}
              <a href="https://haupcar.page.link/cancellationpolicy" target="_blank">
                https://haupcar.page.link/cancellationpolicy
              </a>
            </p>
            <StyledButton
              type="primary"
              shape="round"
              block
              onClick={() => {
                modal.destroy()
                resolve(true)
              }}
            >
              {t('COMMON:DONE')}
            </StyledButton>
          </Space>
        </div>
      )
    }
    const modal = Modal.success({
      icon: null,
      content: <ModalContent />,
      okText: 'Done',
      okButtonProps: {
        hidden: true,
      },
    })
    setTimeout(() => {
      modal.destroy()
      resolve(true)
    }, delay)
  })
}

export async function popConfirmAcceptReservation(): Promise<boolean> {
  return new Promise((resolve) => {
    const ModalContent = () => {
      const { t } = useTranslation(['COMMON', 'RESERVATION_DETAIL'])
      return (
        <div style={{ textAlign: 'center' }}>
          <Space
            direction="vertical"
            style={{ width: '100%', textAlign: 'center', justifyContent: 'center' }}
          >
            <QuestionCircleOutlined style={{ ...colorHaupBlue, fontSize: '3rem' }} />
            <p style={{ ...fontLarger, ...colorHaupBlue }}>
              {t('RESERVATION_DETAIL:ACCEPT_PROMPT_MODAL.A')}
            </p>
            <Row gutter={16}>
              <Col span={12}>
                <StyledButton
                  shape="round"
                  type="ghost"
                  block
                  onClick={() => {
                    modal.destroy()
                    resolve(false)
                  }}
                >
                  {t('COMMON:CANCEL')}
                </StyledButton>
              </Col>
              <Col span={12}>
                <StyledButton
                  shape="round"
                  type="primary"
                  block
                  onClick={() => {
                    modal.destroy()
                    resolve(true)
                  }}
                >
                  {t('COMMON:CONFIRM')}
                </StyledButton>
              </Col>
            </Row>
          </Space>
        </div>
      )
    }

    const modal = Modal.info({
      icon: null,
      content: <ModalContent />,
      okButtonProps: {
        hidden: true,
      },
    })
  })
}

export function popRejectedModal(delay = 10000): Promise<boolean> {
  return new Promise((resolve) => {
    const ModalContent = () => {
      const { t } = useTranslation(['COMMON', 'RESERVATION_DETAIL'])
      return (
        <div style={{ textAlign: 'center' }}>
          <Space direction="vertical">
            <CheckCircleOutlined style={{ ...colorHaupBlue, fontSize: '3rem' }} />
            <p style={{ ...fontLarger, ...colorHaupBlue }}>
              {t('RESERVATION_DETAIL:REJECTED_MODAL.A')}
            </p>
            <StyledButton
              shape="round"
              type="primary"
              block
              onClick={() => {
                modal.destroy()
                resolve(true)
              }}
            >
              {t('COMMON:DONE')}
            </StyledButton>
          </Space>
        </div>
      )
    }
    const modal = Modal.success({
      icon: null,
      content: <ModalContent />,
      okText: 'Done',
      okButtonProps: {
        hidden: true,
      },
    })
    setTimeout(() => {
      modal.destroy()
      resolve(true)
    }, delay)
  })
}

interface ICarImageProps {
  imageSrc: string
  overlayText: React.ReactNode
}
export const CarImage = ({ imageSrc, overlayText }: ICarImageProps) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <div style={{ position: 'relative' }}>
        <span
          style={{
            position: 'absolute',
            top: 5,
            left: 5,
            background: theme.haupBlue,
            padding: '2px 10px',
            color: 'white',
            borderRadius: '5px',
            zIndex: 1,
            fontSize: 'xx-small',
          }}
        >
          {overlayText}
        </span>
        <Image
          src={imageSrc}
          preview={false}
          fallback={carPlaceholder}
          placeholder={carPlaceholder}
        />
      </div>
    </div>
  )
}

interface IDurationBoxProps {
  startDate?: Date | string
  endDate?: Date | string
}
export const DurationBox = (props: IDurationBoxProps) => {
  const { t, i18n } = useTranslation(['RESERVATION_DETAIL', 'COMMON'])
  const startDate = props.startDate
    ? moment(props.startDate).locale(i18n.resolvedLanguage).format('DD MMM YY, H:MM')
    : ''
  const endDate = props.endDate
    ? moment(props.endDate).locale(i18n.resolvedLanguage).format('DD MMM YY, H:MM')
    : ''
  return (
    <Row
      gutter={10}
      align="middle"
      style={{
        border: `1px solid ${theme.textPrimary}50`,
        padding: '0.5rem',
        textAlign: 'center',
        borderRadius: '13px',
      }}
    >
      <Col span={11}>
        <p style={{ ...fontBolder, ...fontLarger }}>{t('RESERVATION_DETAIL:DURATION.START')}</p>
        <p>{startDate}</p>
      </Col>
      <Col span={2}>
        <RightArrowSvg />
      </Col>
      <Col span={11}>
        <p style={{ ...fontBolder, ...fontLarger }}>{t('RESERVATION_DETAIL:DURATION.END')}</p>
        <p>{endDate}</p>
      </Col>
    </Row>
  )
}

export function getStatusColor(status?: string | ReservationStatus) {
  const orange = '#c48610'
  const green = '#008100'
  const blue = theme.haupBlue
  const black = theme.textPrimary
  const red = '#AD0606'
  if (!status) {
    return black
  }
  if (status === ReservationStatus.REQUEST) {
    return orange
  }
  if (status === ReservationStatus.COMPLETE) {
    return green
  }
  if (['CANCEL', 'REJECT'].includes(status)) {
    return red
  }
  return blue
}

interface IDecoratedReservationStatusProps {
  reservationstate?: ReservationStatus
  floatRight?: boolean
}
export const DecoratedReservationStatus = (props: IDecoratedReservationStatusProps) => {
  const { t } = useTranslation(['COMMON', 'RESERVATION_STATUSES'])
  const statusInThai = {
    [ReservationStatus.ALL]: t('COMMON:ALL'),
    [ReservationStatus.CANCEL]: t('RESERVATION_STATUSES:CANCEL'),
    [ReservationStatus.COMPLETE]: t('RESERVATION_STATUSES:COMPLETE'),
    [ReservationStatus.DRIVE]: t('RESERVATION_STATUSES:DRIVE'),
    [ReservationStatus.FINISH]: t('RESERVATION_STATUSES:FINISH'),
    [ReservationStatus.REJECT]: t('RESERVATION_STATUSES:REJECT'),
    [ReservationStatus.REQUEST]: t('RESERVATION_STATUSES:REQUEST'),
    [ReservationStatus.RESERVE]: t('RESERVATION_STATUSES:RESERVE'),
  }
  const status = props.reservationstate ? statusInThai[props.reservationstate] : ''
  if (props.floatRight === true) {
    return (
      <div>
        <span>{t('COMMON:STATUS')}</span>
        <span
          style={{
            float: 'right',
            color: getStatusColor(props.reservationstate),
          }}
        >
          {status}
        </span>
      </div>
    )
  }
  return (
    <div>
      {t('COMMON:STATUS')}:{' '}
      <span style={{ color: getStatusColor(props?.reservationstate) }}>{status}</span>
    </div>
  )
}
