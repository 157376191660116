import { Col, Layout, Row } from 'antd'
import React from 'react'
import Clock from 'react-live-clock'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

interface IHeader {
  headerText: string
  username: string
}

const { Header } = Layout

const HeaderText = styled.h1`
  color: white;
`

class App extends React.Component<IHeader, IHeader> {
  headerText: string
  username: string
  constructor(props: IHeader) {
    super(props)
    this.headerText = props.headerText
    this.username = props.username
    this.state = { headerText: this.headerText, username: this.username }
  }

  componentDidMount() {
    this.username = this.props.username
  }

  componentDidUpdate() {
    if (this.state.username !== this.props.username) {
      this.setState({ username: this.props.username })
    }
  }

  render(): JSX.Element {
    const { headerText, username } = this.state
    return (
      <Header
        className="headerMenu"
        style={{ position: 'sticky', width: '100%', top: '0', zIndex: 1 }}
      >
        <Row>
          <Col span={12} style={{}}>
            <HeaderText>{headerText}</HeaderText>
          </Col>
          <Col className="rightNav" span={12}>
            <p>
              Logged in as: {username}{' '}
              <Link to="/logout" style={{ color: 'navy' }}>
                (Logout)
              </Link>
            </p>
            <p>
              <Clock
                format={'dddd, MMMM DD, yyyy | HH:mm'}
                ticking={true}
                timezone={'Asia/Bangkok'}
              />
            </p>
          </Col>
        </Row>
      </Header>
    )
  }
}

export default App
