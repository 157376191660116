import { PushpinOutlined, RightOutlined } from '@ant-design/icons'
import { Col, Divider, Row, Space } from 'antd'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { theme } from '../../../config/style'
import i18n from '../../../i18n'
import { IReservationListData } from '../../../services/Host'
import { CarImage, CustomTag, DecoratedReservationStatus, LR } from './ReservationDetailComponents'

function format(date: Date) {
  return moment(date)
    .locale(i18n.resolvedLanguage || 'en')
    .format('DD/MM/YYYY HH:mm')
}

export function THB(number?: number) {
  if (number === undefined) {
    return ''
  }
  if (isNaN(Number(number))) {
    return 'INVALID'
  }
  return new Intl.NumberFormat('th-TH', { style: 'currency', currency: 'THB' }).format(number)
}

export const Card = styled.div`
  border-radius: 15px;
  background: white;
  box-shadow: lightgrey 0px 3px 6px;
  padding: 1rem;
`

interface IReservationListItemProps extends IReservationListData {
  [k: string]: any
}
export const ReservationListItem: React.FC<IReservationListItemProps> = (props) => {
  const { t } = useTranslation([
    'COMMON',
    'RESERVATION_DETAIL',
    'RESERVATION_STATUSES',
    'RESERVATION_CATEGORY',
  ])
  const categories = props.category.split(',').filter((s) => !!s)
  const isMaTag = categories.some((c) => c === 'MA')
  const isExternalTag = categories.some((c) => c === 'EXTERNAL')
  const reservation = props
  const price = {
    estimatedprice: reservation?.estimatedprice || 0,
    chargetotal: reservation?.chargetotal || 0,
    extra_chargetotal: reservation?.extra_chargetotal || 0,
    hourprice: reservation?.hourprice || 0,
    distance_price: reservation?.distance_price || 0,
    discount: reservation?.discount || 0,
    fullprice: reservation?.fullprice || 0,
    totalprice: reservation?.totalprice || 0,
    bundleprice: reservation?.bundleprice || 0,
    latehourprice: reservation?.latehourprice || 0,
    actualprice: reservation?.actualprice || 0,
    getHourPrice: function () {
      return this.hourprice
    },
    getDistancePrice: function () {
      return this.bundleprice
    },
    getSubTotalPrice: function () {
      return this.hourprice + this.bundleprice
    },
    getExcessDistancePrice: function () {
      return this.distance_price
    },
    getManualPriceDiscount: function () {
      return this.totalprice - this.actualprice
    },
    getTotalPrice: function () {
      if (['FINISH', 'COMPLETE'].includes(reservation?.reservationstate || '')) {
        return this.totalprice
      }
      return this.estimatedprice
    },
    getEstimatedPrice: function () {
      if (['FINISH', 'COMPLETE'].includes(reservation?.reservationstate || '')) {
        return this.totalprice
      }
      return this.estimatedprice
    },
  }

  return (
    <Card style={{ color: theme.textPrimary }}>
      <Row gutter={16}>
        <Col span={10}>
          <CarImage
            imageSrc={
              props?.vehicle_picture ||
              `https://ws.haupcar.com/mobile/vehicleimage?vehicleid=${props?.vehicleid}`
            }
            overlayText={props?.verifiedtype}
          />
        </Col>
        <Col span={14}>
          <Space direction="vertical" size="small" style={{ width: '100%' }}>
            <div>
              <div
                style={{
                  color: theme.haupBlue,
                  fontSize: 'larger',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <span>
                  {t('COMMON:RESERVATION_NO')} {props.reservationno}
                </span>
                <span>
                  <RightOutlined />
                </span>
              </div>
              <div>
                {props.vehicleModel} {props.licensenumber}
              </div>
              <div>
                <LR left={`${t('COMMON:FROM')}:`} right={format(props.reservestarttime)} />
                <LR left={`${t('COMMON:TO')}:`} right={format(props.reservestoptime)} />
              </div>
            </div>
            <Space>
              <PushpinOutlined />
              <p style={{ fontSize: '0.6rem' }}>{props.stationname}</p>
            </Space>
          </Space>
        </Col>
      </Row>
      <Divider style={{ borderTop: '1px solid rgba(0, 0, 0, 0.06)', margin: '8px 0' }} />
      <Row>
        <Col span={24}>
          <div style={{ color: theme.haupBlue }}>
            {isMaTag ? (
              <div>
                <span>{t('RESERVATION_CATEGORY:MA')}</span>
                <span style={{ float: 'right' }}>
                  <CustomTag color="red">MA</CustomTag>
                </span>
              </div>
            ) : isExternalTag ? (
              <div>
                <span>{t('RESERVATION_CATEGORY:EXTERNAL')}</span>
                <span style={{ float: 'right' }}>
                  <CustomTag color="red">EXTERNAL</CustomTag>
                </span>
              </div>
            ) : (
              <div>
                <span>{t('RESERVATION_DETAIL:PRICE.ESTIMATED_PRICE')}</span>
                <span style={{ float: 'right' }}>{THB(price.getEstimatedPrice())}</span>
              </div>
            )}
          </div>
        </Col>
      </Row>
      <Divider style={{ borderTop: '1px solid rgba(0, 0, 0, 0.06)', margin: '8px 0' }} />
      <Row>
        <Col span={24}>
          <DecoratedReservationStatus reservationstate={props.reservationstate} floatRight={true} />
        </Col>
      </Row>
    </Card>
  )
}
