import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { Layout, notification, Radio, Row, Skeleton, Spin } from 'antd'
import moment from 'moment'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import * as Partnerservice from '../../services/partners'
import { AuthContext } from './../../contexts/Auth'
import { BlackoutDateHeader } from './backdateheader'
import { CarsType, ReservationMonthType } from './BlackoutDateType'
import styles from './css/backoutdate.module.css'
import { BlackoutDateFilter } from './Filter'
import { FullCalendar } from './fullcalendar'

const { Content } = Layout

const StickBottomDiv = styled.div<{ top?: number }>`
  ${(props) => `
  padding: 1rem;
  position: absolute;
  top: ${props.top ?? 0}px;
  bottom: 0;
  background-color: white;
  z-index: 10;
  width: 100%;
  box-shadow: 0 1rem 3rem rgb(0 0 0 / 18%);
  -webkit-box-shadow: 0 1rem 3rem rgb(0 0 0 / 18%);
  -moz-box-shadow: 0 1rem 3rem rgb(0 0 0 / 18%);
`}
`

interface CheckFilter {
  open: boolean
  reset: boolean
}

const BlackoutDate = (props: any) => {
  const MySwal = withReactContent(Swal)
  const Auth = useContext(AuthContext)
  const history = useHistory()
  const location = useLocation<any>()
  const [clickDate, setClickDate] = useState<Date[]>([])
  const [titlePage, setTitlePage] = useState<string>('Calendar')
  const [monthCurrent, setMonthCurrent] = useState<string>(moment(new Date()).format('MM-YYYY'))
  const [carsHost, setCarsHost] = useState<any[]>([])
  const [dataReservation, setDataReservation] = useState<ReservationMonthType[]>([])
  const [dataEventsforcalendar, setDataEventsforcalendar] = useState<ReservationMonthType[]>([])
  const [dataListEvents, setDataListEvents] = useState<ReservationMonthType[]>([])
  const [goToback, setGotoback] = useState<boolean>(false)
  const [radioCehck, setRadioCehck] = useState<number | undefined>()
  const [vehicleId, setVehicleId] = useState<number | undefined>()
  const [monthActve, setMonthActive] = useState<Date | undefined>()
  const [loading, setLoading] = useState<boolean>(false)
  const [clickFilter, setCilckFilter] = useState<CheckFilter>({
    open: false,
    reset: true,
  })
  const [height, setHeight] = useState<any>({
    handleHeight: false,
    box_lists_events: false,
    box_list_data_events: false,
  })
  const [windowHeight, setWindowHeight] = useState(window.innerHeight)
  const [upperPartHeight, setUpperPartHeight] = useState(600)
  const upperPartRef = useRef<HTMLDivElement>(null)
  const lowerPartHeight = useMemo(() => {
    return windowHeight - upperPartHeight
  }, [windowHeight, upperPartHeight])
  const eventWindowTop = useMemo(() => {
    return ((windowHeight / 100) * 70 - lowerPartHeight) * -1
  }, [windowHeight, lowerPartHeight])

  const handleHeight = (): void => {
    if (height.handleHeight) {
      // GetData()
      // handleBack()
      setHeight({
        handleHeight: false,
        box_lists_events: false,
        box_list_data_events: false,
      })
    } else {
      setHeight({
        handleHeight: true,
        box_lists_events: true,
        box_list_data_events: true,
      })
    }
  }
  const handleClick = (): void => {
    history.push({
      pathname: '/host/blackoutdate/create',
      state: {
        monthCurrent: monthCurrent,
        dateclick: clickDate,
      },
    })
  }

  const handleBack = (): void => {
    setHeight({
      handleHeight: false,
      box_lists_events: false,
      box_list_data_events: false,
    })
    setClickDate([])
    setTitlePage('Calendar')
    setGotoback(false)
    setRadioCehck(undefined)
    history.push('/host/blackoutdate')
  }
  const handleDelete = async (e: React.MouseEvent) => {
    e.preventDefault
    const getreserve = await Partnerservice.GetReserveId({ id: radioCehck }, Auth)
    const {
      data: {
        data: { reservedhours, starttime, stoptime },
      },
    } = getreserve
    const isAllDay = reservedhours?.length === 0 ? true : false
    const dateFrom = `${moment(starttime).format('DD MMM YYYY')} ${
      !isAllDay ? reservedhours[0]?.start : ''
    }`
    const dateTo = `${moment(stoptime).format('DD MMM YYYY')} ${
      !isAllDay ? reservedhours[0]?.stop : ''
    }`
    await MySwal.fire({
      title: <strong>Delete Reservation</strong>,
      html: (
        <i>
          By deleting this reservation, the car will be available for renters to make a reservation
          via the application between {dateFrom} - {dateTo}. Would you like to confirm?
        </i>
      ),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#0046A0',
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel',
    }).then(async (result) => {
      if (result?.isConfirmed) {
        await setLoading(true)
        await setRadioCehck(undefined)
        const result = await Partnerservice.DeleteReserve({ vehiclereservedno: radioCehck }, Auth)
        if (result?.status === 200) {
          const resultMonth = await Partnerservice.getReservationMonth(
            moment(location?.state?.dataSend?.starttime).format('MM-YYYY'),
            Auth,
          )
          if (resultMonth?.status === 200) {
            await hanldeClickDate
            history.push({
              pathname: '/host/blackoutdate/',
              state: {
                checkconfirm: true,
                dataSend: getreserve?.data?.data,
              },
            })
          }
        }
      }
    })
  }
  const GetData = async () => {
    setLoading(true)
    setClickDate([])
    setTitlePage('Calendar')
    setGotoback(false)

    setCilckFilter({
      ...clickFilter,
      open: false,
      reset: true,
    })
    if (location.state && location?.state?.checkconfirm === false) {
      setHeight({
        handleHeight: true,
        box_lists_events: true,
        box_list_data_events: true,
      })
      const resultMonth = await Partnerservice.getReservationMonth(
        moment(location?.state?.dataSend?.starttime).format('MM-YYYY'),
        Auth,
      )
      if (resultMonth?.status === 200) {
        const datas = resultMonth.data.data?.filter((val: ReservationMonthType) => {
          if (
            moment(val?.reservestarttime).format('YYYY-MM-DD') ===
            moment(location?.state?.dataSend?.starttime).format('YYYY-MM-DD')
          ) {
            return val
          }
        })
        setGotoback(false)
        setMonthActive(location?.state?.dataSend?.starttime)
        setDataReservation(datas)
        setDataListEvents(resultMonth?.data?.data)
        GruopByEventShowCalendar(resultMonth?.data.data)
        setLoading(false)
      }
    } else {
      const resultMonth = await Partnerservice.getReservationMonth(monthCurrent, Auth)
      if (resultMonth?.status === 200) {
        setDataListEvents(resultMonth?.data.data)
        setDataReservation(resultMonth?.data.data)
        GruopByEventShowCalendar(resultMonth?.data.data)
        setLoading(false)
      }
    }
  }
  const GruopByEventShowCalendar = (datas: any) => {
    const groupsdate = datas?.reduce((r: any, a: any) => {
      r[moment(a.reservestarttime).format('YYYY-MM-DD')] =
        r[moment(a.reservestarttime).format('YYYY-MM-DD')] || []
      r[moment(a.reservestarttime).format('YYYY-MM-DD')].push(a)
      return r
    }, {})

    const guropsvechleid = Object.values(groupsdate).map((val: any) => {
      return val.reduce((r: any, a: any) => {
        r[a.vehicleid] = r[a.vehicleid] || []
        r[a.vehicleid].push(a)
        return r
      }, {})
    })
    const evensts: any = []
    Object.values(guropsvechleid).map((val: any) => {
      return Object.values(val).map((data: any) => {
        evensts.push(data.pop())
      })
    })
    setDataEventsforcalendar(evensts)
  }

  const GetCarHost = async () => {
    try {
      const resultCars = await Partnerservice.getCarsHost(Auth)
      if (resultCars?.status === 200) {
        return resultCars?.data.data
      }
    } catch (error) {
      return notification['warning']({
        message: 'Something error.',
      })
    }
  }
  const CheckConFirm = async () => {
    setHeight({
      handleHeight: true,
      box_lists_events: true,
      box_list_data_events: true,
    })
    if (location.state.checkconfirm) {
      setLoading(true)
      setTitlePage('Manage Reservation')
      if (location.state) {
        if (location.state.dataSend) {
          const resultMonth = await Partnerservice.getReservationMonth(
            moment(location.state.dataSend.starttime).format('MM-YYYY'),
            Auth,
          )
          if (resultMonth.status === 200) {
            const datas = resultMonth.data.data?.filter((val: ReservationMonthType) => {
              if (
                moment(val.reservestarttime).format('YYYY-MM-DD') ===
                moment(location.state.dataSend.starttime).format('YYYY-MM-DD')
              ) {
                return val
              }
            })
            setGotoback(true)
            setMonthActive(location.state.dataSend.starttime)

            setTimeout(() => {
              setDataReservation(datas)
              setLoading(false)
            }, 300)
          }
        }
      }
    }
  }

  const handleFilter = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    if (clickFilter.reset) {
      setCilckFilter({
        ...clickFilter,
        open: true,
        reset: true,
      })
    } else {
      setCilckFilter({
        ...clickFilter,
        open: false,
        reset: true,
      })
      GetData()
    }
  }

  const hanldeClickDate = (): void => {
    const datas: any = []
    dataListEvents?.filter((val: ReservationMonthType) => {
      if (
        moment(val.reservestarttime).format('DDMMYYYY') ===
        moment(clickDate?.slice().shift()).format('DDMMYYYY')
      ) {
        datas.push(val)
      }
    })
    if (clickDate.length > 0) {
      setLoading(true)
      if (datas.length <= 0) {
        // handleHeight()
        setTitlePage('Calendar')
        setGotoback(false)
        setDataReservation(datas)
        setLoading(false)
      } else {
        // handleHeight()
        setTitlePage('Manage Reservation')
        setGotoback(true)
        setDataReservation(datas)
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    GetCarHost().then((result) => {
      setCarsHost(result)
    })
    if (location.state !== undefined) {
      if (location.state.checkconfirm === false) {
        GetData()
      }
    } else {
      GetData()
    }
  }, [monthCurrent])

  useEffect(() => {
    setRadioCehck(undefined)
    if (location.state !== undefined) {
      if (location.state.checkconfirm === false) {
        hanldeClickDate()
      }
    } else {
      hanldeClickDate()
    }
  }, [clickDate])

  useEffect(() => {
    if (location.state !== undefined) {
      if (location.state.checkconfirm === true) {
        CheckConFirm()
      }
    }
  }, [location.state])

  useEffect(() => {
    GetData()
    setTimeout(() => {
      const height = upperPartRef.current?.offsetHeight
      if (height) {
        setUpperPartHeight(height)
      }
    }, 200)
  }, [])
  return (
    <Spin tip="Loading..." spinning={loading}>
      {!clickFilter.open ? (
        <div className={`${styles.fix_height}`}>
          <div id="upper-part" ref={upperPartRef}>
            <BlackoutDateHeader
              title={titlePage}
              gotoback={goToback}
              handleBack={clickFilter.open ? handleBack : () => history.replace('/host/dashboard')}
            />

            <Content
              className={`container ${styles.container_box}`}
              style={{ overflow: 'initial' }}
            >
              <FullCalendar
                getEvents={dataEventsforcalendar}
                checkDayOfWeek={false}
                setClickDate={setClickDate}
                clickDate={clickDate}
                setMonthCurrent={setMonthCurrent}
                carsHost={carsHost}
                monthActve={monthActve}
                setMonthActive={setMonthActive}
                page={'list'}
              />
            </Content>
          </div>
          <div id="lower-part" style={{ position: 'relative', height: lowerPartHeight ?? 500 }}>
            {loading ? (
              <Skeleton active />
            ) : (
              <StickBottomDiv top={height.handleHeight ? eventWindowTop : 0}>
                <div className={`${styles.box_half_circle}`}>
                  <button className={`${styles.half_circle}`} onClick={() => handleHeight()}>
                    {height.handleHeight ? <DownOutlined /> : <UpOutlined />}
                  </button>
                </div>
                <div style={{ maxHeight: lowerPartHeight }}>
                  <Row className={styles.row_title_events}>
                    <div className={`${styles.box_title_events}`}>
                      <label className={`${styles.title_events}`}>
                        {!clickDate.length && `All `}Event(s)
                      </label>
                    </div>
                    <div className={`${styles.box_btn_filter}`}>
                      <button
                        type="button"
                        className={`${styles.btn_filter}`}
                        onClick={(e) => handleFilter(e)}
                      >
                        {clickFilter.reset === true ? 'Filter' : 'Reset Filter'}
                      </button>
                    </div>
                  </Row>
                  <div
                    style={{
                      height: height.handleHeight
                        ? (windowHeight / 100) * 70 - 120
                        : lowerPartHeight - 120,
                      overflow: 'scroll',
                    }}
                  >
                    {dataReservation.map((val) => {
                      const cars = carsHost.filter(
                        (car: CarsType) => car.vehicleId === val.vehicleid,
                      )

                      const car = cars.shift()
                      return (
                        <ListEvents
                          clickDate={clickDate}
                          data={val}
                          setRadioCehck={setRadioCehck}
                          radioCehck={radioCehck}
                          car={car}
                          setVehicleId={setVehicleId}
                        />
                      )
                    })}
                  </div>
                  <div style={{ height: 'fit-content', margin: '8px 0' }}>
                    {radioCehck ? (
                      <button
                        className={`${styles.btn_bottom} ${styles.btn_delete} ${
                          radioCehck !== undefined ? '' : styles.disabled
                        }`}
                        onClick={handleDelete}
                        type="button"
                        disabled={radioCehck !== undefined ? false : true}
                      >
                        Delete
                      </button>
                    ) : location.state !== undefined && location.state.checkconfirm ? (
                      <button onClick={handleClick} className={`${styles.btn_bottom}`}>
                        New Reservation
                      </button>
                    ) : (
                      <button onClick={handleClick} className={`${styles.btn_bottom}`}>
                        New Reservation
                      </button>
                    )}
                  </div>
                </div>
              </StickBottomDiv>
            )}
          </div>
        </div>
      ) : (
        <BlackoutDateFilter
          carsHost={carsHost}
          setDataReservation={setDataReservation}
          dataReservation={dataReservation}
          setCilckFilter={setCilckFilter}
          clickFilter={clickFilter}
          monthActve={monthActve}
          monthCurrent={monthCurrent}
          setMonthActive={setMonthActive}
        />
      )}
    </Spin>
  )
}

const ListEvents = (props: any) => {
  const CehckAllDay = () => {
    let textTimereserve = 'All Day'
    const start = moment(props?.data.reservestarttime).format('HH:mm')
    const stop = moment(props?.data.reservestoptime).format('HH:mm')

    if (start !== '00:00' || stop !== '00:00') {
      textTimereserve = start + ' - ' + stop
    }
    return textTimereserve
  }

  const handleRadio = (event: any) => {
    props?.setRadioCehck(event.target.value)
    if (event.target.value === props?.data.vehicleReservedNo) {
      props?.setVehicleId(props?.data.vehicleId)
    }
  }

  const clickrow = (vehicleReservedNo: number) => {
    if (props?.clickDate.length > 0) {
      props?.setRadioCehck(vehicleReservedNo)
      if (vehicleReservedNo === props?.data.vehicleReservedNo) {
        props?.setVehicleId(props?.data.vehicleId)
      }
    }
  }

  return (
    <>
      <div
        className={
          props?.data.reservationStatus !== 'My Reservation'
            ? `${styles.list_data_check}`
            : `${styles.list_data}`
        }
      >
        <Row className={`${styles.mt_5}`}>
          {props?.clickDate?.length > 0 ? (
            <div className={`${styles.box_radio_event}`}>
              <Radio
                name="selectevents"
                value={props?.data.vehicleReservedNo}
                onChange={handleRadio}
                checked={props?.radioCehck === props.data.vehicleReservedNo}
                style={{ color: '#0046A0' }}
                disabled={props?.data.reservationStatus === 'My Reservation' ? false : true}
              />
            </div>
          ) : (
            <div
              className={`${styles.border_left} ${
                props?.data?.reservationStatus === 'My Reservation' ? '' : styles.border_opacity
              }`}
            ></div>
          )}

          <div
            className={`${styles.box_name} ${
              props?.clickDate?.length > 0 ? styles.check_date_width : ''
            }`}
            onClick={() => clickrow(props?.data.vehicleReservedNo)}
          >
            <label className={`${styles.title_name}`}>{props?.data.reservationStatus}</label>
            <p className={`${styles.dis_name}`}>{props?.data.name}</p>
          </div>
          <div
            className={`${styles.box_car} ${
              props?.clickDate.length > 0 ? styles.check_date_width : ''
            }`}
            onClick={() => clickrow(props?.data.vehicleReservedNo)}
          >
            <div className={`${styles.car_name}`}>
              <div
                className={`${styles.circle_car}`}
                style={{ background: props?.car?.hostColor }}
              ></div>
              <label className={`${styles.title_name}`}>{props?.data?.vehicleModel}</label>
            </div>
            <p className={`${styles.car_registration}`}>{props?.car?.licenseNumber}</p>
          </div>
          <div
            className={`${styles.box_date} ${
              props?.clickDate?.length > 0 ? styles.check_date_width : ''
            }`}
            onClick={() => clickrow(props?.data?.vehicleReservedNo)}
          >
            <p className={`${styles.date_reserve}`}>
              {moment(props?.data?.reservestarttime).format('DD MMM YY')}
            </p>
            <p className={`${styles.reserve_allday}`}>{CehckAllDay()}</p>
          </div>
        </Row>
      </div>
    </>
  )
}

export default BlackoutDate
