import { Layout } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import React from 'react'
import '../../static/css/_WebView.less'

export default class WebView extends React.Component {
  render() {
    return (
      <Layout>
        <Layout>
          <Content style={{ backgroundColor: 'white' }}>{this.props.children}</Content>
        </Layout>
      </Layout>
    )
  }
}
