import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Alert, Button, Col, DatePicker, Divider, Form, Modal, Row, Select, Space } from 'antd'
import moment from 'moment'
import React from 'react'
import NewReservationService from '../../services/NewReservation'
import { getUserList } from '../../services/user'
import { AuthContext } from './../../contexts/Auth'
import EstimateDetail from './EstimateDetail'
import ReservationSummary from './ReservationSummary'

const { confirm } = Modal
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
}

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
}

const { Option } = Select
const { RangePicker } = DatePicker

const rangeConfig = {
  rules: [{ type: 'array' as const, required: true, message: 'Please select time!' }],
}

function transformStationList(stationLists: any[]) {
  const result = stationLists.map((item) => {
    return { label: item.stationcode, value: item.stationid }
  })
  return result
}

function transformUserList(userLists: any[]) {
  const result = userLists.map((item) => {
    return { label: item.email, value: item.userid }
  })
  return result
}

function transformVehicleList(vehicleLists: any[]) {
  const result = vehicleLists.map((item) => {
    return {
      label: item.vehiclecode,
      value: item.vehicleid,
      vehiclebrand: item.vehiclebrand,
      vehiclemodel: item.vehiclemodel,
    }
  })
  return result
}

function transformBundleList(bundleLists: any[]) {
  const result = bundleLists.map((item) => {
    return {
      label: item.bundlecode,
      value: item.bundleid,
      bundle_price: item.bundle_price,
      bundle_freedistance: item.bundle_minfreedistance,
      bundle_distancerate: item.bundle_distancerate,
    }
  })
  return result
}

interface IMessageBoxProp {
  message: string
  title: string
}

const MessageBox = (props: IMessageBoxProp) => {
  const { message, title } = props
  return <Alert message={title} description={message} type="error" />
}

class AllFleetReservation extends React.Component {
  reservationService = new NewReservationService(this.context)
  baseState: any
  static contextType = AuthContext

  showConfirm = () => {
    confirm({
      title: 'Confirm to make a reservation?',
      icon: <ExclamationCircleOutlined />,
      content: this.state.confirmErrorMessage,
      onOk: () => {
        return new Promise((resolve, reject) => {
          this.createReservation()
            .then((result: any) => {
              resolve(result)
            })
            .catch((error: any) => {
              this.setState({
                confirmErrorMessage: error,
                error: {
                  display: true,
                  title: `Can't confirm reservation`,
                  message: error,
                },
              })
              Modal.destroyAll()

              reject(error)
            })
        })
      },
    })
  }

  initialEstimateState = {
    username: '',
    station: '',
    stationLink: '',
    vehicle: '',
    reservationStartTime: '',
    reservationStopTime: '',
    payment: '',
    price: {
      hourPrice: 0,
      bundlePrice: 0,
      estimatePrice: 0,
    },
    bundle: {
      bundle_distance_km: 0,
      bundlecode: '',
      bundle_distancerate: 0,
    },
  }

  initialError = {
    display: false,
    title: '',
    message: '',
  }

  state = {
    stationList: [],
    vehicleList: [],
    bundleList: [],
    paymentList: [],
    userList: [],
    selectedUserId: null,
    selectedStartTime: '',
    selectedStopTime: '',
    selectedStationId: null,
    selectedVehicleId: undefined,
    selectedBundleId: undefined,
    selectedPaymentId: undefined,
    estimate: this.initialEstimateState,
    display: {
      selectVehicle: false,
      reservationDetail: false,
      findAvailableBtn: false,
      estimateBtn: false,
      reserveBtn: false,
      loadingUserList: true,
      loadingFindAvailableBtn: false,
      loadingEstimateBtn: false,
      loadingReserveBtn: false,
      loadingConfirmReserveBtn: false,
      summaryReserve: false,
      estimateDetail: false,
      selectBundleList: false,
      selectVehicleList: false,
    },
    summary: {
      reservationNo: 0,
      reservationStatus: '',
      userName: '',
      reserveStartTime: '',
      reserveStopTime: '',
      stationCode: '',
      stationLink: '',
      vehicleCode: '',
      licenseNo: '',
      vehicleModel: '',
      bundleCode: '',
      bundleDistance: 0,
      bundleRate: 0,
      hourPrice: 0,
      distancePrice: 0,
      estimatePrice: 0,
    },
    error: this.initialError,
    confirmErrorMessage: '',
  }

  getUserList = (search = '') => {
    getUserList(this.context, search).then((result) => {
      const _user = transformUserList(result)
      this.setState({ userList: _user, display: { ...this.state.display, loadingUserList: false } })
    })
  }

  enableFindAvailableBtn = () => {
    const { selectedUserId, selectedStartTime, selectedStopTime } = this.state
    if (selectedUserId && selectedStartTime && selectedStopTime) {
      this.setState({ display: { ...this.state.display, findAvailableBtn: true } })
    }
  }

  getAvailableStation = () => {
    this.setState({
      stationList: [],
      vehicleList: [],
      bundleList: [],
      paymentList: [],
      display: { ...this.state.display, loadingFindAvailableBtn: true },
      error: this.initialError,
    })
    this.reservationService
      .getStationList({
        startTime: this.state.selectedStartTime,
        stopTime: this.state.selectedStopTime,
        userId: this.state.selectedUserId,
      })
      .then((result) => {
        const _station = transformStationList(result)
        this.setState({
          stationList: _station,
          display: { ...this.state.display, selectVehicle: true, loadingFindAvailableBtn: false },
        })
      })
      .catch((error) => {
        this.setState({
          display: { ...this.state.display, loadingFindAvailableBtn: false },
          error: {
            display: true,
            title: `Can't get station list`,
            message: error,
          },
        })
      })
  }

  getAvailableVehicle = (stationId: number) => {
    this.setState({
      display: {
        ...this.state.display,
        selectVehicleList: false,
        selectBundleList: false,
        selectPaymentList: false,
      },
      selectedVehicleId: undefined,
      selectedBundleId: undefined,
      selectedPaymentId: undefined,
      vehicleList: [],
      bundleList: [],
      paymentList: [],
      error: this.initialError,
    })
    const param = {
      userId: this.state.selectedUserId,
      startTime: this.state.selectedStartTime,
      stopTime: this.state.selectedStopTime,
      stationId: stationId,
    }
    this.reservationService
      .getAvailableVehicleList(param)
      .then((result) => {
        this.setState({
          vehicleList: transformVehicleList(result),
          display: { ...this.state.display, selectVehicleList: true },
        })
      })
      .catch((error) => {
        this.setState({ error: { display: true, title: `Can't get vehicle list`, message: error } })
      })
  }

  getPackageList = (vehicleId: number) => {
    this.setState({
      bundleList: [],
      display: { ...this.state.display, selectBundleList: false },
      selectedBundleId: undefined,
      error: this.initialError,
    })
    const param = {
      userId: this.state.selectedUserId,
      startTime: this.state.selectedStartTime,
      stopTime: this.state.selectedStopTime,
      stationId: this.state.selectedStationId,
      vehicleId: vehicleId,
    }
    this.reservationService
      .getEstimateReservation(param)
      .then((result) => {
        this.setState({
          bundleList: transformBundleList(result.bundleList),
          paymentList: result.paymentList,
          display: { ...this.state.display, selectBundleList: true },
        })
      })
      .catch((error) => {
        this.setState({ error: { display: true, title: `Can't get bundle list`, message: error } })
      })
  }

  getEstimateReservation = () => {
    this.setState({
      display: { ...this.state.display, loadingEstimateBtn: true },
      estimate: this.initialEstimateState,
      error: this.initialError,
    })
    const param = {
      userId: this.state.selectedUserId,
      startTime: this.state.selectedStartTime,
      stopTime: this.state.selectedStopTime,
      stationId: this.state.selectedStationId,
      vehicleId: this.state.selectedVehicleId,
      bundleId: this.state.selectedBundleId,
      paymentId: this.state.selectedPaymentId,
    }
    this.reservationService
      .getEstimateReservation(param)
      .then((result) => {
        this.setState({
          display: {
            ...this.state.display,
            loadingEstimateBtn: false,
            reserveBtn: true,
            estimateDetail: true,
          },
        })
        const estimate = {
          username: result?.userDetail?.username,
          station: result?.stationDetail?.stationcode,
          stationLink: result?.stationDetail?.link,
          vehicle: result?.vehicleDetail?.vehiclecode,
          reservationStartTime: result?.reservestarttime,
          reservationStopTime: result?.reservestoptime,
          payment: result?.payment,
          price: {
            hourPrice: result?.priceDetail?.hourprice.toFixed(2),
            bundlePrice: result?.priceDetail?.bundleprice.toFixed(2),
            estimatePrice: result?.priceDetail?.estimatedprice.toFixed(2),
          },
          bundle: {
            bundle_distance_km: result?.bundle_distance_km,
            bundlecode: result?.bundleDetail?.bundlecode,
            bundle_distancerate: result?.bundleDetail?.override_distancerate,
          },
        }
        this.setState({ bundleList: result.bundleList, paymentList: result.paymentList, estimate })
      })
      .catch((error) => {
        this.setState({
          error: { display: true, title: `Can't get estimate reservation`, message: error },
        })
      })
  }

  createReservation = (): Promise<any> => {
    return new Promise((resolve, reject) => {
      this.setState({ display: { ...this.state.display, loadingConfirmReserveBtn: true } })
      const param = {
        userId: this.state.selectedUserId,
        startTime: this.state.selectedStartTime,
        stopTime: this.state.selectedStopTime,
        stationId: this.state.selectedStationId,
        vehicleId: this.state.selectedVehicleId,
        bundleId: this.state.selectedBundleId,
        paymentId: this.state.selectedPaymentId,
      }
      this.reservationService
        .confirmReservation(param)
        .then((result) => {
          this.setState({
            display: {
              ...this.state.display,
              loadingEstimateBtn: false,
              summaryReserve: true,
            },
          })
          const summary = {
            reservationNo: result?.reservationno,
            reservationStatus: result?.confirm_action,
            userName: result?.userDetail?.username,
            reserveStartTime: result?.reservestarttime,
            reserveStopTime: result?.reservestoptime,
            stationCode: result?.stationDetail?.stationcode,
            stationLink: result?.stationDetail?.link,
            vehicleCode: result?.vehicleDetail?.vehiclecode,
            licenseNo: result?.vehicleDetail?.licensenumber,
            vehicleModel: result?.vehicleDetail?.vehiclemodel,
            bundleCode: result?.bundleDetail?.bundlecode,
            bundleDistance: result?.bundle_distance_km,
            bundleRate: result?.bundleDetail?.override_distancerate,
            hourPrice: result?.priceDetail?.hourprice.toFixed(2),
            distancePrice: result?.priceDetail?.bundleprice.toFixed(2),
            estimatePrice: result?.priceDetail?.estimatedprice.toFixed(2),
          }
          this.setState({ summary })
          resolve(result)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  handleSearchUser = (search: string) => {
    console.log(search)
    console.log(search.length)
    if (search.length >= 3) {
      this.getUserList(search)
    }
  }

  componentDidMount() {
    this.getUserList()
    this.baseState = this.state
  }

  resetPage = () => {
    this.setState(this.baseState)
    this.getUserList()
  }

  render() {
    if (this.state.display.summaryReserve) {
      return <ReservationSummary reservationData={this.state.summary} onReset={this.resetPage} />
    } else {
      return (
        <Row>
          <Col flex="auto">
            {this.state.error.display && (
              <div>
                <MessageBox title={this.state.error.title} message={this.state.error.message} />
                <br />
              </div>
            )}

            <Form {...layout} name="basic" initialValues={{ remember: true }}>
              <Form.Item label="User" required>
                <Select
                  showSearch
                  style={{ maxWidth: 400 }}
                  placeholder="Select a person"
                  optionFilterProp="children"
                  onChange={(userid) => {
                    this.setState({ selectedUserId: userid })
                    this.enableFindAvailableBtn()
                  }}
                  loading={this.state.display.loadingUserList}
                  disabled={this.state.display.loadingUserList}
                  onSearch={this.handleSearchUser}
                >
                  {this.state.userList &&
                    this.state.userList.map((option: any) => (
                      <>
                        <Option key={option.value} value={option.value}>
                          {option.label}
                        </Option>
                      </>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item name="range-time-picker" label="Select Time" {...rangeConfig}>
                <RangePicker
                  showTime
                  format="YYYY-MM-DD HH:mm"
                  onChange={async (e) => {
                    const startTime = e?.[0]?.format('YYYY-MM-DD HH:mm:00')
                    const stopTime = e?.[1]?.format('YYYY-MM-DD HH:mm:00')
                    await this.setState({
                      selectedStartTime: startTime,
                      selectedStopTime: stopTime,
                    })
                    this.enableFindAvailableBtn()
                  }}
                  hideDisabledOptions={true}
                  minuteStep={15}
                  disabledDate={(e) => {
                    return e < moment().subtract(5, 'minutes')
                  }}
                  disabledTime={(selectedTime, timeType) => {
                    interface Iop {
                      disabledMinutes?: any
                      disabledHours?: any
                    }
                    const returnObj: Iop = {}

                    if (timeType === 'start' || timeType === 'end') {
                      const notSelectTime = selectedTime === null
                      const isToday =
                        moment(selectedTime).format('YYYY-MM-DD') ===
                          moment().format('YYYY-MM-DD') || notSelectTime

                      const isCurrentHour =
                        moment(selectedTime).get('hour') === moment().get('hour')
                      const currentHour = moment().get('hour')
                      const currentMinute = moment().get('minute')
                      const disabledHours: number[] = []
                      const disabledMinutes: number[] = []
                      if (isToday) {
                        for (let i = 0; i < currentHour; i++) {
                          disabledHours.push(i)
                        }

                        if (isCurrentHour) {
                          for (let i = 0; i < currentMinute; i++) {
                            disabledMinutes.push(i)
                          }
                        }
                        returnObj.disabledHours = () => disabledHours
                      }
                      returnObj.disabledMinutes = () => disabledMinutes
                    }
                    return returnObj
                  }}
                />
              </Form.Item>
              <Form.Item {...tailLayout}>
                <Button
                  type="primary"
                  onClick={this.getAvailableStation}
                  loading={this.state.display.loadingFindAvailableBtn}
                  disabled={!this.state.display.findAvailableBtn}
                >
                  Find Available
                </Button>
              </Form.Item>
              <Divider />
              {this.state.display.selectVehicle && (
                <>
                  <Form.Item label="Station List" required>
                    <Select
                      showSearch
                      style={{ maxWidth: 400 }}
                      placeholder="Select a station"
                      optionFilterProp="children"
                      onChange={(e) => {
                        const stationid = parseInt(`${e}`)
                        this.setState({ selectedStationId: e })
                        this.getAvailableVehicle(stationid)
                      }}
                    >
                      {this.state.stationList &&
                        this.state.stationList.map((option: any) => (
                          <Option key={option.value} value={option.value}>
                            {option.label}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label="Available Vehicle" required>
                    <Select
                      showSearch
                      style={{ maxWidth: 400 }}
                      placeholder="Select a vehicle"
                      optionFilterProp="children"
                      value={this.state.selectedVehicleId}
                      onChange={(e) => {
                        const vehicleid = parseInt(`${e}`)
                        this.setState({
                          selectedVehicleId: vehicleid,
                        })
                        this.getPackageList(vehicleid)
                      }}
                      loading={!this.state.display.selectVehicleList}
                      disabled={!this.state.display.selectVehicleList}
                    >
                      {this.state.vehicleList &&
                        this.state.vehicleList.map((option: any) => (
                          <Option key={option.value} value={option.value}>
                            <b>{option.label}</b>
                            <p>
                              {option.vehiclebrand} {option.vehiclemodel}
                            </p>
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label="Distance Package" required>
                    <Select
                      showSearch
                      style={{ maxWidth: 400 }}
                      placeholder="Select a distance package"
                      optionFilterProp="children"
                      value={this.state.selectedBundleId}
                      onChange={(e) => {
                        const bundleId = parseInt(`${e}`)
                        this.setState({ selectedBundleId: bundleId })
                      }}
                      loading={!this.state.display.selectBundleList}
                      disabled={!this.state.display.selectBundleList}
                    >
                      {this.state.bundleList &&
                        this.state.bundleList.map((option: any) => (
                          <Option key={option.value} value={option.value}>
                            <b>{option.label}</b>
                            <p>
                              Price: {option.bundle_price}, Included distance:
                              {option.bundle_freedistance} km, Price rate:
                              {option.bundle_distancerate} ฿/km
                            </p>
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label="Payment" required>
                    <Select
                      showSearch
                      style={{ maxWidth: 400 }}
                      placeholder="Select a payment"
                      optionFilterProp="children"
                      value={this.state.selectedPaymentId}
                      onChange={(e) => {
                        const paymentId = parseInt(`${e}`)
                        this.setState({
                          selectedPaymentId: paymentId,
                          display: { ...this.state.display, estimateBtn: true },
                        })
                      }}
                      loading={!this.state.display.selectBundleList}
                      disabled={!this.state.display.selectBundleList}
                    >
                      {this.state.paymentList &&
                        this.state.paymentList.map((option: any) => (
                          <Option key={option.paymentno} value={option.paymentno}>
                            {option.title}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                  <Form.Item {...tailLayout}>
                    <Space>
                      <Button
                        onClick={this.getEstimateReservation}
                        disabled={!this.state.display.estimateBtn}
                        loading={this.state.display.loadingEstimateBtn}
                      >
                        Estimate
                      </Button>
                      <Button
                        type="primary"
                        disabled={!this.state.display.reserveBtn}
                        loading={this.state.display.loadingReserveBtn}
                        onClick={this.showConfirm}
                      >
                        Reserve
                      </Button>
                    </Space>
                  </Form.Item>
                </>
              )}
            </Form>
          </Col>
          <Col flex="300px">
            {this.state.display.estimateDetail && (
              <EstimateDetail reservationDetail={this.state.estimate} />
            )}
          </Col>
        </Row>
      )
    }
  }
}

export default AllFleetReservation
