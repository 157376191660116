import Icon from '@ant-design/icons'
import { Layout, Menu } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import LogoHaupLong from './logo2.png'
import LogoHaup from './logo3.png'

const { Sider } = Layout

const { SubMenu } = Menu

type SubMenuItem = {
  title: string
  key: string
  link: string
}

type Logo = {
  icon: string
  width: string
}

type MenuItem = {
  key: string
  title: string
  link: string
  icon: React.ComponentType<React.SVGProps<SVGSVGElement>>
  subMenu?: SubMenuItem[]
}

interface ISideMenu {
  collapsed: boolean
  menus: MenuItem[]
}

interface ISideMenuState extends ISideMenu {
  logo: Logo
}

function generateMenu(menu: MenuItem[]): JSX.Element[] {
  const menus: JSX.Element[] = []
  menu.map((item) => {
    if (item?.subMenu) {
      const childMenu: JSX.Element[] = []
      item.subMenu.map((ite) => {
        childMenu.push(
          <Menu.Item key={ite.title}>
            <Link to={ite.link}>{ite.title}</Link>
          </Menu.Item>,
        )
      })
      menus.push(
        <SubMenu
          key={item.key}
          icon={<Icon component={item.icon} style={{ fontSize: '32px' }} />}
          title={item.title}
        >
          {childMenu}
        </SubMenu>,
      )
    } else {
      menus.push(
        <Menu.Item
          key={item.title}
          icon={<Icon component={item.icon} style={{ fontSize: '32px' }} />}
        >
          <Link to={item.link}>{item.title}</Link>
        </Menu.Item>,
      )
    }
  })
  return menus
}

class SideMenu extends React.Component<ISideMenu, ISideMenuState> {
  logoList = {
    long: { icon: LogoHaupLong, width: '120px' },
    short: { icon: LogoHaup, width: '40ox' },
  }
  menuItems
  constructor(props: ISideMenu) {
    super(props)
    const logo = this.props.collapsed ? this.logoList.short : this.logoList.long
    this.state = { collapsed: this.props.collapsed, menus: this.props.menus, logo }
    this.menuItems = generateMenu(this.props.menus)
  }

  setLogo(collapsed: boolean): void {
    if (collapsed) {
      this.setState({ logo: this.logoList.short })
    } else {
      this.setState({ logo: this.logoList.long })
    }
  }

  onCollapse = (collapsed: boolean): void => {
    this.setState({ collapsed })
    this.setLogo(collapsed)
  }

  onBreakpoint = (al: any): void => {
    console.log(al)
  }

  render(): JSX.Element {
    const { collapsed } = this.state
    return (
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={this.onCollapse}
        onBreakpoint={this.onBreakpoint}
        style={{ background: '#15499b', height: '100vh', position: 'sticky', top: '0', left: '0' }}
      >
        <div className="logo" style={{ height: '40px' }}>
          <img src={this.state.logo.icon} width={this.state.logo.width} />
        </div>
        <Menu
          theme="dark"
          defaultSelectedKeys={['1']}
          mode="inline"
          style={{ background: '#15499b' }}
        >
          {this.menuItems}
        </Menu>
      </Sider>
    )
  }
}

export default SideMenu
