import React from 'react'
import { Redirect } from 'react-router'

class Overview extends React.Component {
  render(): JSX.Element {
    return (
      <>
        <Redirect to="/reservation/new/all" />
        <p>Overview Page</p>
      </>
    )
  }
}

export default Overview
