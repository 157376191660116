import { Tag } from 'antd'
import React from 'react'

const ReservationStatus: React.FC<{ reservationState: string }> = ({ reservationState }) => {
  let colorCode = '#d5dbe4'
  switch (reservationState) {
    case 'REQUEST':
      colorCode = '#33C8F0'
      break
    case 'RESERVE':
      colorCode = 'green'
      break
    case 'CANCEL':
      colorCode = '#FF3E3E'
      break
    case 'REJECT':
      colorCode = '#FDCA68'
      break
    case 'COMPLETE':
      colorCode = '#108ee9'
      break
  }
  return <Tag color={colorCode}>{reservationState}</Tag>
}

export default ReservationStatus
