import { Button, Form, Input, Layout, Select, Space, Table, Tag } from 'antd'
import React from 'react'
import { AuthContext } from './../../contexts/Auth'
import { getStationList } from './../../services/station'
import { getVehicleList } from './../../services/vehiclelist'
import VehicleDetail from './VehicleDetail'

const { Content } = Layout
const { Option } = Select
const { Search } = Input

const columns = [
  {
    title: 'Status',
    dataIndex: 'vehicleStatus',
    render: (vehicleStatus: string) => renderVehicleTag(vehicleStatus),
  },
  {
    title: 'License Plate',
    dataIndex: 'vehicleLicense',
  },
  {
    title: 'Vehicle Code',
    dataIndex: 'vehicleCode',
  },
  {
    title: 'Origin Station',
    dataIndex: 'vehicleOrigin',
  },
  {
    title: 'Current Station',
    dataIndex: 'vehicleCurrent',
  },
  {
    title: 'Lastest Note',
    dataIndex: 'vehicleNote',
  },
]

const renderVehicleTag = (vehicleStatus: string) => {
  let color = 'default'
  if (vehicleStatus === 'MA') {
    color = 'red'
  }
  if (vehicleStatus === 'SERVICE') {
    color = 'blue'
  }
  return <Tag color={color}>{vehicleStatus}</Tag>
}

function transformReservationData(rawData: any[], stationList: IStationList[]) {
  return rawData.map((item) => {
    const {
      reservationno,
      vehiclestatus,
      licensenumber,
      vehiclecode,
      vehicletype,
      logtime,
      latitude,
      longitude,
      enginestate,
      vehicleid,
    } = item
    const transformedData = {
      key: vehicleid,
      vehicleStatus: vehiclestatus,
      vehicleLicense: licensenumber,
      vehicleCode: vehiclecode,
      vehicleOrigin: reservationno,
      vehicleCurrent: reservationno,
      vehicleNote: reservationno,
      vehicleModel: vehicletype,
      vehicleColor: reservationno,
      vehicleAction: logtime,
      gpsstatus: reservationno,
      gpstime: reservationno,
      latitude: latitude,
      longitude: longitude,
      engine: enginestate,
      doorlock: reservationno,
      vehiclegroup: reservationno,
      price: reservationno,
      originstation: reservationno,
      currentstation: reservationno,
      bookingno: reservationno,
      username: reservationno,
      bookingstart: reservationno,
      bookingstop: reservationno,
    }
    return transformedData
  })
}

interface IStationList {
  stationid: number
  stationcode: string
}

interface IVehicleList {
  vehicleid: number
  vehiclecode: string
  licensenumber: string
  latitude: string
  longitude: string
  vehiclestatus: string
  logtime: string
  reservationno: number
  ma_tags: string
  speed: number
  heading: number
  enginestate: string
  vehicletype: string
}

class Vehicle extends React.Component {
  transformedData: any
  state = {
    vehicleDetail: null,
    siderCollapsed: true,
    stationList: [],
    vehicleList: [],
    isLoadStation: true,
    isLoadVehicle: true,
  }
  static contextType = AuthContext

  getVehicleDetail(rowIndex: any): void {
    this.setState({ vehicleDetail: this.transformedData[rowIndex] })
  }

  componentDidMount() {
    if (this.state.isLoadStation) {
      getStationList(this.context)
        .then((result: { data: any }) => {
          this.setState({ stationList: [...result.data] })
        })
        .then(() => {
          this.setState({ isLoadStation: false })
        })
    }
    if (this.state.isLoadVehicle) {
      this.getVehiclePage({})
    }
  }

  getVehiclePage = (filter: any): void => {
    getVehicleList(this.context, filter)
      .then((result: { data: any }) => {
        console.log(result)
        this.setState({ vehicleList: result.data })
        this.transformedData = transformReservationData(result.data, this.state.stationList)
      })
      .then(() => {
        console.log('ok')
        this.setState({ isLoadVehicle: false })
      })
  }

  getFilteredVehicle = (data: any): void => {
    //console.dir(data, { depth: null })
    this.getVehiclePage(data)
  }

  render() {
    return (
      <Layout>
        <Layout>
          <Content style={{ backgroundColor: 'white' }}>
            <Form layout="inline" onFinish={this.getFilteredVehicle}>
              <Space wrap>
                <Form.Item name="search">
                  <Search placeholder="Search" allowClear style={{ width: 200 }} />
                </Form.Item>
                <Form.Item name="vehicleStatus" label="Status">
                  <Select defaultValue="ALL" style={{ width: 120 }}>
                    <Option value="ALL">ALL</Option>
                    <Option value="SERVICE">SERVICE</Option>
                    <Option value="MA">MA</Option>
                  </Select>
                </Form.Item>
                <Form.Item name="stationId" label="Station">
                  <Select
                    showSearch
                    defaultValue="ALL-STATION"
                    style={{ width: 150 }}
                    optionFilterProp="children"
                  >
                    <Option value="ALL">ALL-STATION</Option>
                    {!this.state.isLoadStation &&
                      this.state.stationList.map((option: IStationList) => (
                        <Option key={option.stationid} value={option.stationid}>
                          {option.stationcode}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
                <Button type="primary" htmlType="submit">
                  Search
                </Button>
                <Button>Export</Button>
              </Space>
            </Form>
            <br />
            <Table
              columns={columns}
              dataSource={this.transformedData}
              style={{ margin: 10 }}
              onRow={(record, rowIndex) => {
                return {
                  onClick: () => {
                    this.getVehicleDetail(rowIndex)
                    this.setState({ siderCollapsed: false })
                  }, // click row
                }
              }}
            />
          </Content>
        </Layout>
        {!this.state.siderCollapsed && <VehicleDetail data={this.state.vehicleDetail} />}
      </Layout>
    )
  }
}

export default Vehicle
