import { Layout, notification, Spin, Switch, TimePicker } from 'antd'
import _ from 'lodash'
import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory, useLocation } from 'react-router-dom'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import * as Partnerservice from '../../services/partners'
import { AuthContext } from './../../contexts/Auth'
import { BlackoutDateHeader } from './backdateheader'
import {
  CarsType,
  FormSubmitType,
  ReservationMonthType,
  selectTime,
  Time,
} from './BlackoutDateType'
import { CardListCars } from './cardlistcar'
import styles from './css/backoutdate.module.css'
import { FullCalendar } from './fullcalendar'
const { Content } = Layout

const FormReservation = (props: any) => {
  const MySwal = withReactContent(Swal)
  const Auth = useContext(AuthContext)
  const history = useHistory()
  const location = useLocation<any>()
  const count = 2
  const [clickDate, setClickDate] = useState<Date[]>()
  const [clickdatelast, setClickDatelast] = useState<Date[]>()
  const [dataListEventsCalendar, setDataListEventsCalendar] = useState<ReservationMonthType[]>([])
  const [dataListEvents, setDataListEvents] = useState<ReservationMonthType[]>([])
  const [titlePage, setTitlePage] = useState<string>('New Reservation')
  const [monthCurrent, setMonthCurrent] = useState<string>(moment(new Date()).format('MM-YYYY'))
  const [checkAllday, setCheckAllday] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [checkDayOfWeek, setCheckDayOfWeek] = useState<boolean>(false)
  const [selectCar, setSelectCar] = useState<number | undefined>()
  const [dateSendForm, setDateSendForm] = useState<Date[] | Date | undefined>()
  const [listCars, setListCars] = useState<CarsType[] | null | any>([])
  const [reservedhours, setReservedhours] = useState<Time[]>([])
  const [monthActve, setMonthActive] = useState<Date | undefined>()
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<FormSubmitType>()
  const [selectTime, setSelectTime] = useState<selectTime>({
    timeone: {
      start: null,
      end: null,
      opne: false,
    },
    timetwo: {
      start: null,
      end: null,
      opne: false,
    },
    timethree: {
      start: null,
      end: null,
      opne: false,
    },
    timefour: {
      start: null,
      end: null,
      opne: false,
    },
  })
  const handleAllday = (checked: boolean) => {
    setValue('allDay', checked)
    setCheckAllday(checked)
    setSelectTime({
      timeone: {
        start: null,
        end: null,
        opne: false,
      },
      timetwo: {
        start: null,
        end: null,
        opne: false,
      },
      timethree: {
        start: null,
        end: null,
        opne: false,
      },
      timefour: {
        start: null,
        end: null,
        opne: false,
      },
    })
  }
  const handleDayOfWeeky = (checked: boolean) => {
    setCheckDayOfWeek(checked)
    setClickDate([])
  }

  const checkTimeSelect = (time: any, num: number) => {
    let result = { status: true, message: '' }
    if (time !== null) {
      Object.values(selectTime).filter((val: any, key: number) => {
        if (key !== num) {
          if (val.start && val.end) {
            if (time[0].format('HH:mm') >= val.start || val.end <= time[1].format('HH:mm')) {
              if (time[0].format('HH:mm') <= val.end || val.end >= time[0].format('HH:mm'))
                result = { status: false, message: 'Select Time Overlap' }
            }
          }
        }
      })
    }
    if (clickDate) {
      clickDate.map((date: Date) => {
        const dateformat = moment(date).format('YYYY-MM-DD')
        if (time) {
          const timestart = time[0].format('HH:mm')
          const datetime = moment(dateformat + ' ' + timestart).format('YYYY-MM-DD HH:mm')
          const datetimecurrent = moment(new Date()).format('YYYY-MM-DD HH:mm')
          if (datetime < datetimecurrent) {
            result = {
              status: false,
              message: 'please select a future date and time',
            }
          }
        }
      })
    }
    return result
  }

  const SelectTime = (time: any, num: number) => {
    const checkdatetime = checkTimeSelect(time, num - 1)
    switch (num) {
      case 1:
        if (checkdatetime.status) {
          if (time !== null) {
            setSelectTime({
              ...selectTime,
              timeone: {
                start: time !== null ? time[0].format('HH:mm') : null,
                end: time !== null ? time[1].format('HH:mm') : null,
                opne: true,
              },
            })
            reservedhours.splice(0, 1)
            setReservedhours((reservedhours) => [
              ...reservedhours,
              {
                start: time !== null ? time[0].format('HH:mm') : null,
                stop: time !== null ? time[1].format('HH:mm') : null,
              },
            ])
          } else {
            if (selectTime.timefour.start) {
              setSelectTime({
                ...selectTime,
                timeone: {
                  start: selectTime.timetwo.start,
                  end: selectTime.timetwo.end,
                  opne: true,
                },
                timetwo: {
                  start: selectTime.timethree.start,
                  end: selectTime.timethree.end,
                  opne: true,
                },
                timethree: {
                  start: selectTime.timefour.start,
                  end: selectTime.timefour.end,
                  opne: false,
                },
                timefour: {
                  start: null,
                  end: null,
                  opne: false,
                },
              })
            } else if (selectTime.timethree.start) {
              setSelectTime({
                ...selectTime,
                timeone: {
                  start: selectTime.timetwo.start,
                  end: selectTime.timetwo.end,
                  opne: true,
                },
                timetwo: {
                  start: selectTime.timethree.start,
                  end: selectTime.timethree.end,
                  opne: true,
                },
                timethree: {
                  start: null,
                  end: null,
                  opne: false,
                },
              })
            } else if (selectTime.timetwo.start) {
              setSelectTime({
                ...selectTime,
                timeone: {
                  start: selectTime.timetwo.start,
                  end: selectTime.timetwo.end,
                  opne: true,
                },
                timetwo: {
                  start: null,
                  end: null,
                  opne: true,
                },
              })
            } else {
              setSelectTime({
                ...selectTime,
                timeone: {
                  start: selectTime.timetwo.start,
                  end: selectTime.timetwo.end,
                  opne: true,
                },
              })
            }

            reservedhours.splice(0, 1)
          }
        } else {
          return notification['warning']({
            message: checkdatetime.message,
          })
        }

        break
      case 2:
        if (checkdatetime.status) {
          if (time !== null) {
            setSelectTime({
              ...selectTime,
              timetwo: {
                start: time[0].format('HH:mm'),
                end: time[1].format('HH:mm'),
                opne: true,
              },
            })
            reservedhours.splice(1, 1)
            setReservedhours((reservedhours) => [
              ...reservedhours,
              {
                start: time !== null ? time[0].format('HH:mm') : null,
                stop: time !== null ? time[1].format('HH:mm') : null,
              },
            ])
          } else {
            if (selectTime.timefour.start) {
              setSelectTime({
                ...selectTime,
                timetwo: {
                  start: selectTime.timethree.start,
                  end: selectTime.timethree.end,
                  opne: true,
                },
                timethree: {
                  start: selectTime.timefour.start,
                  end: selectTime.timefour.end,
                  opne: true,
                },
                timefour: {
                  start: null,
                  end: null,
                  opne: false,
                },
              })
            } else if (selectTime.timethree.start) {
              setSelectTime({
                ...selectTime,
                timetwo: {
                  start: selectTime.timethree.start,
                  end: selectTime.timethree.end,
                  opne: true,
                },
                timethree: {
                  start: null,
                  end: null,
                  opne: false,
                },
              })
            } else {
              setSelectTime({
                ...selectTime,
                timetwo: {
                  start: null,
                  end: null,
                  opne: false,
                },
              })
            }
            reservedhours.splice(1, 1)
          }
        } else {
          return notification['warning']({
            message: checkdatetime.message,
          })
        }

        break
      case 3:
        if (checkdatetime.status) {
          if (time !== null) {
            setSelectTime({
              ...selectTime,
              timethree: {
                start: time[0].format('HH:mm'),
                end: time[1].format('HH:mm'),
                opne: true,
              },
            })
            reservedhours.splice(2, 1)
            setReservedhours((reservedhours) => [
              ...reservedhours,
              {
                start: time[0].format('HH:mm'),
                stop: time[1].format('HH:mm'),
              },
            ])
          } else {
            if (selectTime.timefour.start) {
              setSelectTime({
                ...selectTime,
                timethree: {
                  start: selectTime.timefour.start,
                  end: selectTime.timefour.end,
                  opne: true,
                },
                timefour: {
                  start: null,
                  end: null,
                  opne: false,
                },
              })
            } else {
              setSelectTime({
                ...selectTime,
                timethree: {
                  start: null,
                  end: null,
                  opne: false,
                },
              })
            }
            reservedhours.splice(2, 1)
          }
        } else {
          return notification['warning']({
            message: checkdatetime.message,
          })
        }
        break
      case 4:
        if (checkdatetime.status) {
          if (time !== null) {
            setSelectTime({
              ...selectTime,
              timefour: {
                start: time[0].format('HH:mm'),
                end: time[1].format('HH:mm'),
                opne: true,
              },
            })
            reservedhours.splice(3, 1)
            setReservedhours((reservedhours) => [
              ...reservedhours,
              {
                start: time[0].format('HH:mm'),
                stop: time[1].format('HH:mm'),
              },
            ])
          } else {
            setSelectTime({
              ...selectTime,
              timefour: {
                start: null,
                end: null,
                opne: false,
              },
            })

            reservedhours.splice(3, 1)
          }
        } else {
          return notification['warning']({
            message: checkdatetime.message,
          })
        }
        break
      default:
        break
    }
  }

  const handleDeletSelectTime = (num: number) => {
    switch (num) {
      case 2:
        setSelectTime({
          ...selectTime,
          timetwo: {
            start: selectTime.timethree.start,
            end: selectTime.timethree.end,
            opne: selectTime.timethree.opne,
          },
          timethree: {
            start: selectTime.timefour.start,
            end: selectTime.timefour.end,
            opne: selectTime.timefour.opne,
          },
          timefour: {
            start: null,
            end: null,
            opne: false,
          },
        })
        break
      case 3:
        setSelectTime({
          ...selectTime,
          timethree: {
            start: selectTime.timefour.start,
            end: selectTime.timefour.end,
            opne: selectTime.timefour.opne,
          },
          timefour: {
            start: null,
            end: null,
            opne: false,
          },
        })
        break
      case 4:
        setSelectTime({
          ...selectTime,
          timefour: {
            start: null,
            end: null,
            opne: false,
          },
        })
        break
      default:
        break
    }
  }

  const GetCars = async () => {
    const resultCars = await Partnerservice.getCarsHost(Auth)
    if (resultCars?.status === 200) setListCars(resultCars.data.data)
  }

  const GetDataEdit = async (id: string) => {
    const result = await Partnerservice.GetReserveId({ id: id }, Auth)
    await Partnerservice.ClearUndo({ vehicleid: result.data.data.vehicleid }, Auth)
    if (result?.status === 200) {
      if (result?.data?.data) {
        setClickDate(result?.data.data.reserveddates)
        setMonthActive(result?.data?.data.starttime)
        setCheckAllday(result?.data?.data.reservedhours.length > 0 ? false : true)
        setSelectCar(result?.data?.data.vehicleid)
        const data = result?.data?.data

        if (result.data?.data.reservedhours.length > 0) {
          switch (result?.data?.data.reservedhours.length) {
            case 1:
              setSelectTime({
                ...selectTime,
                timeone: {
                  start: data.reservedhours[0].start,
                  end: data.reservedhours[0].stop,
                  opne: true,
                },
              })
              reservedhours.splice(1, 1)
              setReservedhours((reservedhours) => [
                ...reservedhours,
                {
                  start: data.reservedhours[0].start,
                  stop: data.reservedhours[0].stop,
                },
              ])
              break
            case 2:
              setSelectTime({
                ...selectTime,
                timeone: {
                  start: data.reservedhours[0].start,
                  end: data.reservedhours[0].stop,
                  opne: true,
                },
                timetwo: {
                  start: data.reservedhours[1].start,
                  end: data.reservedhours[1].stop,
                  opne: true,
                },
              })
              setReservedhours((reservedhours) => [
                ...reservedhours,
                {
                  start: data.reservedhours[0].start,
                  stop: data.reservedhours[0].stop,
                },
                {
                  start: data.reservedhours[1].start,
                  stop: data.reservedhours[1].stop,
                },
              ])
              break
            case 3:
              setSelectTime({
                ...selectTime,
                timeone: {
                  start: data.reservedhours[0].start,
                  end: data.reservedhours[0].stop,
                  opne: true,
                },
                timetwo: {
                  start: data.reservedhours[1].start,
                  end: data.reservedhours[1].stop,
                  opne: true,
                },
                timethree: {
                  start: data.reservedhours[2].start,
                  end: data.reservedhours[2].stop,
                  opne: true,
                },
              })
              setReservedhours((reservedhours) => [
                ...reservedhours,
                {
                  start: data.reservedhours[0].start,
                  stop: data.reservedhours[0].stop,
                },
                {
                  start: data.reservedhours[1].start,
                  stop: data.reservedhours[1].stop,
                },
                {
                  start: data.reservedhours[2].start,
                  stop: data.reservedhours[2].stop,
                },
              ])
              break
            case 4:
              setSelectTime({
                ...selectTime,
                timeone: {
                  start: data.reservedhours[0].start,
                  end: data.reservedhours[0].stop,
                  opne: true,
                },
                timetwo: {
                  start: data.reservedhours[1].start,
                  end: data.reservedhours[1].stop,
                  opne: true,
                },
                timethree: {
                  start: data.reservedhours[2].start,
                  end: data.reservedhours[2].stop,
                  opne: true,
                },
                timefour: {
                  start: data.reservedhours[3].start,
                  end: data.reservedhours[3].stop,
                  opne: true,
                },
              })
              setReservedhours((reservedhours) => [
                ...reservedhours,
                {
                  start: data.reservedhours[0].start,
                  stop: data.reservedhours[0].stop,
                },
                {
                  start: data.reservedhours[1].start,
                  stop: data.reservedhours[1].stop,
                },
                {
                  start: data.reservedhours[3].start,
                  stop: data.reservedhours[3].stop,
                },
              ])
              break
            default:
              break
          }
        } else {
          setSelectTime({
            timeone: {
              start: null,
              end: null,
              opne: false,
            },
            timetwo: {
              start: null,
              end: null,
              opne: false,
            },
            timethree: {
              start: null,
              end: null,
              opne: false,
            },
            timefour: {
              start: null,
              end: null,
              opne: false,
            },
          })
        }
      }
    }
  }
  const GetDatas = async () => {
    const resultMonth = await Partnerservice.getReservationMonth(monthCurrent, Auth)
    if (resultMonth?.status === 200) {
      setDataListEvents(resultMonth.data.data)
      GruopByEventShowCalendar(resultMonth.data.data)
    }
  }

  const GruopByEventShowCalendar = (datas: any) => {
    const groupsdate = datas?.reduce((r: any, a: any) => {
      r[moment(a.reservestarttime).format('YYYY-MM-DD')] =
        r[moment(a.reservestarttime).format('YYYY-MM-DD')] || []
      r[moment(a.reservestarttime).format('YYYY-MM-DD')].push(a)
      return r
    }, {})

    const guropsvechleid = Object.values(groupsdate).map((val: any) => {
      return val.reduce((r: any, a: any) => {
        r[a.vehicleid] = r[a.vehicleid] || []
        r[a.vehicleid].push(a)
        return r
      }, {})
    })
    const evensts: any = []
    Object.values(guropsvechleid).map((val: any) => {
      return Object.values(val).map((data: any) => {
        evensts.push(data.slice(-1).pop())
      })
    })

    setDataListEventsCalendar(evensts)
  }

  const diff = (start: string, end: string) => {
    const startsplit: any = start?.split(':')
    const endsplit: any = end?.split(':')
    const startDate = new Date(0, 0, 0, startsplit[0], startsplit[1], 0)
    const endDate = new Date(0, 0, 0, endsplit[0], endsplit[1], 0)
    let diff = endDate.getTime() - startDate.getTime()
    let hours = Math.floor(diff / 1000 / 60 / 60)
    diff -= hours * 1000 * 60 * 60
    const minutes = Math.floor(diff / 1000 / 60)

    // If using time pickers with 24 hours format, add the below line get exact hours
    if (hours < 0) hours = hours + 24

    return Number(hours + '.' + minutes)
  }

  const onSubmit = async (data: FormSubmitType) => {
    let check = true
    let summaryhours = 24
    let Reservedid = 0
    if (location.state) {
      if (location.state.id) {
        Reservedid = location.state.id
      }
    }
    dataListEvents.filter((val: any) => {
      if (val.vehicleid === Number(selectCar)) {
        let reservesstop = moment(val.reservestoptime).format('HH:mm')
        if (reservesstop === '00:00') {
          reservesstop = '23:59'
        }
        clickDate?.filter((date: Date) => {
          if (
            moment(date).format('YYYY-MM-DD') === moment(val.reservestarttime).format('YYYY-MM-DD')
          ) {
            if (
              moment(val.reservestarttime).format('HH:mm') === '00:00' &&
              reservesstop === '23:59'
            ) {
              if (Reservedid !== val.vehicleReservedNo) {
                check = false
              }
            }
            if (checkAllday) {
              if (Reservedid !== val.vehicleReservedNo) {
                if (val) {
                  check = false
                }
              }
            } else {
              if (reservedhours.length > 0) {
                reservedhours.filter((time: Time) => {
                  if (Reservedid !== val.vehicleReservedNo) {
                    if (
                      time.start >= moment(val.reservestarttime).format('HH:mm') ||
                      moment(val.reservestarttime).format('HH:mm') <= time.stop
                    ) {
                      if (time.start <= reservesstop || reservesstop >= time.stop) {
                        check = false
                      }
                    }
                  }
                })
              }
            }
          }
        })
      }
    })

    if (!checkAllday) {
      if (reservedhours.length > 0) {
        const timecount = reservedhours.map((val: any) => {
          return diff(val.start, val.stop)
        })
        summaryhours = _.sum(timecount)
      }
    }
    // console.log(sumtime)

    if (!check)
      return notification['warning']({
        message: 'Reservation Date or Time Overlap',
      })

    if (!selectCar)
      return notification['warning']({
        message: 'Please Select Car',
      })
    if (!clickDate)
      return notification['warning']({
        message: 'Please Select Date',
      })
    if (!checkAllday) {
      if (reservedhours.length < 1)
        return notification['warning']({
          message: 'Please Select Time',
        })
    }

    const date: string[] = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    const starttime = clickDate?.slice().shift()
    const stoptime = clickDate?.slice().pop()
    const reserveddows = clickDate?.map((val: Date) => {
      const newdate = new Date(moment(val).format('YYYY-MM-DD'))
      return date[Number(newdate.getDay())]
    })

    if (location.state !== undefined && location.state.edit) {
      const dataSend = {
        vehiclereservedno:
          location.state !== undefined && location.state.edit ? location.state.id : 0,
        vehicleid: Number(selectCar),
        starttime: starttime,
        stoptime: stoptime,
        reservedhours: checkAllday === true ? [] : reservedhours,
        summaryhours: summaryhours,
        reserveddows: [''],
        reserveddates: clickDate?.map((val: Date) => {
          return moment(val).format('YYYY-MM-DD')
        }),
        confirm: false,
      }
      const result = await Partnerservice.editReserve(dataSend, Auth)
      if (result?.status === 200) {
        await setLoading(false)
        await MySwal.fire({
          title: <strong>Success</strong>,
          html: (
            <i>
              Your car has been reserved at the selected date, the car will be shown as unavailable
              to rent for renters on the application.
            </i>
          ),
          confirmButtonColor: '#0046A0',
          icon: 'success',
          confirmButtonText: 'Continue',
        })
        history.push({
          pathname: '/host/blackoutdate/',
          state: {
            checkconfirm: true,
            dataSend: dataSend,
          },
        })
      }
    } else {
      const dataSend: any = {
        vehiclereservedno:
          location.state !== undefined && location.state.edit ? location.state.id : 0,
        vehicleid: Number(selectCar),
        starttime: starttime,
        stoptime: stoptime,
        reservedhours: checkAllday === true ? [] : reservedhours,
        summaryhours: summaryhours,
        reserveddows: [''],
        reserveddates: clickDate?.map((val: Date) => {
          return moment(val).format('YYYY-MM-DD')
        }),
      }
      await setLoading(true)
      const result: any = await Partnerservice.createReserve(dataSend, Auth)
      if (result?.data?.result !== 'CONFLICTVEHICLERESERVED') {
        await setLoading(false)
        await MySwal.fire({
          title: <strong>Success</strong>,
          html: (
            <i>
              Your car has been reserved at the selected date, the car will be shown as unavailable
              to rent for renters on the application
            </i>
          ),
          confirmButtonColor: '#0046A0',
          icon: 'success',
          confirmButtonText: 'Continue',
        })
        history.push({
          pathname: '/host/blackoutdate/',
          state: {
            checkconfirm: false,
            dataSend: dataSend,
          },
        })
      }
      if (result?.data?.result === 'CONFLICTVEHICLERESERVED') {
        const splitStr = result?.data?.message || ''
        await setLoading(false)
        await MySwal.fire({
          title: <strong>Are you sure?</strong>,
          html: <i>{splitStr}</i>,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#0046A0',
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
        }).then(async (result) => {
          if (result.isConfirmed) {
            await setLoading(true)
            dataSend.confirm = true
            await Partnerservice.createReserve(dataSend, Auth)
            await setLoading(false)
            MySwal.fire({
              title: <strong>Success</strong>,
              html: (
                <i>
                  Your car has been reserved at the selected date, the car will be shown as
                  unavailable to rent for renters on the application
                </i>
              ),
              confirmButtonColor: '#0046A0',
              icon: 'success',
              confirmButtonText: 'Continue',
            })
            history.push({
              pathname: '/host/blackoutdate/',
              state: {
                checkconfirm: false,
                dataSend: dataSend,
              },
            })
          }
        })
      }
    }
  }

  const handleBack = (): void => {
    history.push('/host/blackoutdate')
  }

  const handleReset = (e: any) => {
    e.preventDefault
    if (location?.state !== undefined && location?.state?.edit) {
      GetDatas()
      GetDataEdit(location?.state?.id)
    } else {
      setClickDate([])
      setSelectCar(undefined)
      setCheckAllday(false)
      setCheckDayOfWeek(false)
      setSelectTime({
        timeone: {
          start: null,
          end: null,
          opne: false,
        },
        timetwo: {
          start: null,
          end: null,
          opne: false,
        },
        timethree: {
          start: null,
          end: null,
          opne: false,
        },
        timefour: {
          start: null,
          end: null,
          opne: false,
        },
      })
    }
  }

  const handleSelectDate = (): void => {
    if (clickDate && clickDate?.length > 0) {
      clickDate.filter((date: Date) => {
        reservedhours.filter((time: any) => {
          const dateformat = moment(date).format('YYYY-MM-DD')
          const timestart = time?.start
          const datetime = moment(dateformat + ' ' + timestart)?.format('YYYY-MM-DD HH:mm')
          const datetimecurrent = moment(new Date())?.format('YYYY-MM-DD HH:mm')
          if (datetime < datetimecurrent) {
            if (location?.state !== undefined && location?.state?.edit) {
              GetDatas()
              GetDataEdit(location?.state?.id)
            } else {
              setClickDate([])
            }
            return notification['warning']({
              message: 'please select a future date and time',
            })
          }
        })
      })
    }
  }

  useEffect(() => {
    handleSelectDate()
  }, [clickDate])

  useEffect(() => {
    if (location?.state !== undefined && location?.state?.edit) {
      setTitlePage('Edit Reservation')
      GetDataEdit(location.state?.id)
    } else if (
      (location?.state !== undefined && location?.state?.dateclick?.length > 0) ||
      location?.state?.monthCurrent
    ) {
      if (location?.state?.dateclick?.length > 0) {
        setMonthActive(location?.state?.dateclick[0])
      } else {
        const changetodate = moment('01-' + location?.state?.monthCurrent, 'DD-MM-YYYY')
        setMonthActive(new Date(moment(changetodate).format('YYYY-MM-DD')))
      }
      setClickDate(location?.state?.dateclick)
    }
  }, [])

  useEffect(() => {
    GetDatas()
    GetCars()
  }, [monthCurrent])

  return (
    <>
      <div className={`${styles.fix_height_100}`}>
        <Spin tip="Loading..." spinning={loading}>
          <form className={styles.form_data} onSubmit={handleSubmit(onSubmit)}>
            <BlackoutDateHeader
              title={titlePage}
              gotoback={true}
              btn_resrt={true}
              handleBack={handleBack}
              handleReset={handleReset}
            />

            <div className={`${count > 1 ? styles.box_list_cars : styles.box_list_car}`}>
              <CardListCars
                setSelectCar={setSelectCar}
                selectCar={selectCar}
                listCars={listCars}
                register={register}
              />
            </div>
            <Content className="container" style={{ padding: '20px 0px 0', overflow: 'initial' }}>
              <div className={styles.allday_box}>
                <div className={styles.box_label_switch}>
                  <label
                    className={`${styles.lable_switch} ${checkAllday ? styles.checked_allday : ''}`}
                  >
                    All Day
                  </label>
                </div>
                <div className={styles.box_switch}>
                  <Switch
                    {...register('allDay')}
                    checked={checkAllday}
                    className={`switch_haup`}
                    onChange={handleAllday}
                  />
                </div>
              </div>
              <div className={styles.daysfoweek}>
                <div className={styles.box_label_switch}>
                  <label
                    className={`${styles.lable_switch} ${
                      checkDayOfWeek ? styles.check_daysfoweek : ''
                    }`}
                  >
                    Days of Week
                  </label>
                </div>
                <div className={styles.box_switch}>
                  <Switch
                    checked={checkDayOfWeek}
                    className={`switch_haup`}
                    onChange={handleDayOfWeeky}
                  />
                </div>
              </div>
            </Content>
            <div className={styles.container}>
              <FullCalendar
                checkDayOfWeek={checkDayOfWeek}
                getEvents={dataListEventsCalendar}
                clickclear={true}
                setClickDate={setClickDate}
                setMonthCurrent={setMonthCurrent}
                clickDate={clickDate}
                setDateSendForm={setDateSendForm}
                dateSendForm={dateSendForm}
                carsHost={listCars}
                monthCurrent={monthCurrent}
                monthActve={monthActve}
                setMonthActive={setMonthActive}
                setClickDatelast={setClickDatelast}
                clickdatelast={clickdatelast}
                page={'form'}
              />
            </div>
            <div className={`${styles.box_select_time} ${checkAllday ? styles.disabled : ''}`}>
              <div className={styles.container}>
                <div className={styles.box_icon_clock}>
                  <img
                    className={styles.ico_clock}
                    src="/image/clock_haupcar.svg"
                    alt="ico_clock"
                  />
                  <div className={`${styles.box_title_start_enf_time}`}>
                    <div className={`${styles.box_title_time}`}>
                      <label className={styles.title_start_time}>Start time</label>
                    </div>
                    <div className={`${styles.box_title_time}`}>
                      <label className={styles.title_end_time}>End time</label>
                    </div>
                  </div>
                </div>
                {/* one */}
                <div className={styles.select_time_picker}>
                  <div className={styles.box_btn_select_time}>
                    <TimePicker.RangePicker
                      disabled={checkAllday}
                      inputReadOnly={true}
                      format={'HH:mm'}
                      autoFocus={true}
                      suffixIcon
                      onChange={(time) => SelectTime(time, 1)}
                      className={styles.timepicker_ant}
                      minuteStep={5}
                      value={[
                        selectTime.timeone.start !== null
                          ? moment(selectTime.timeone.start, 'HH:mm')
                          : null,
                        selectTime.timeone.end !== null
                          ? moment(selectTime.timeone.end, 'HH:mm')
                          : null,
                      ]}
                    />
                  </div>
                </div>
                {/* end one */}
                {/* two */}
                <div
                  className={`${styles.select_time_picker} ${
                    selectTime.timeone.opne ? styles.show_box_time : styles.none_box_time
                  } `}
                >
                  <div className={`${styles.box_btn_select_time}  ${styles.select_time_long}`}>
                    <TimePicker.RangePicker
                      format={'HH:mm'}
                      disabled={checkAllday}
                      inputReadOnly={true}
                      suffixIcon
                      onChange={(time) => SelectTime(time, 2)}
                      className={styles.timepicker_ant}
                      minuteStep={5}
                      value={[
                        selectTime.timetwo.start !== null
                          ? moment(selectTime.timetwo.start, 'HH:mm')
                          : null,
                        selectTime.timetwo.end !== null
                          ? moment(selectTime.timetwo.end, 'HH:mm')
                          : null,
                      ]}
                    />
                    <div
                      className={`${styles.box_icon_delete} ${
                        selectTime.timetwo.opne ? '' : styles.disabled
                      }`}
                      onClick={() => handleDeletSelectTime(2)}
                    >
                      <img src="/image/trash.svg" className={styles.icon_trash} alt="trash" />
                    </div>
                  </div>
                </div>
                {/* end two */}
                {/* three */}
                <div
                  className={`${styles.select_time_picker} ${
                    selectTime.timetwo.opne ? styles.show_box_time : styles.none_box_time
                  } `}
                >
                  <div className={`${styles.box_btn_select_time}  ${styles.select_time_long}`}>
                    <TimePicker.RangePicker
                      format={'HH:mm'}
                      disabled={checkAllday}
                      suffixIcon
                      onChange={(time) => SelectTime(time, 3)}
                      inputReadOnly={true}
                      className={styles.timepicker_ant}
                      minuteStep={5}
                      value={[
                        selectTime.timethree.start !== null
                          ? moment(selectTime.timethree.start, 'HH:mm')
                          : null,
                        selectTime.timethree.end !== null
                          ? moment(selectTime.timethree.end, 'HH:mm')
                          : null,
                      ]}
                    />
                    <div
                      className={`${styles.box_icon_delete} ${
                        selectTime.timethree.opne ? '' : styles.disabled
                      }`}
                      onClick={() => handleDeletSelectTime(3)}
                    >
                      <img src="/image/trash.svg" className={styles.icon_trash} alt="trash" />
                    </div>
                  </div>
                </div>
                {/* end three */}
                {/* four */}
                <div
                  className={`${styles.select_time_picker} ${
                    selectTime.timethree.opne ? styles.show_box_time : styles.none_box_time
                  }`}
                >
                  <div className={`${styles.box_btn_select_time}  ${styles.select_time_long}`}>
                    <TimePicker.RangePicker
                      format={'HH:mm'}
                      disabled={checkAllday}
                      suffixIcon
                      onChange={(time) => SelectTime(time, 4)}
                      inputReadOnly={true}
                      className={styles.timepicker_ant}
                      minuteStep={5}
                      value={[
                        selectTime.timefour.start !== null
                          ? moment(selectTime.timefour.start, 'HH:mm')
                          : null,
                        selectTime.timefour.end !== null
                          ? moment(selectTime.timefour.end, 'HH:mm')
                          : null,
                      ]}
                    />
                    <div
                      className={`${styles.box_icon_delete} ${
                        selectTime.timefour.opne ? '' : styles.disabled
                      }`}
                      onClick={() => handleDeletSelectTime(4)}
                    >
                      <img src="/image/trash.svg" className={styles.icon_trash} alt="trash" />
                    </div>
                  </div>
                </div>
                {/* end four */}
              </div>
            </div>

            <div className={`${styles.box_btn_submit}`}>
              <button className={`${styles.btn_submit}`} type="submit">
                Save
              </button>
            </div>
          </form>
        </Spin>
      </div>
    </>
  )
}

export default FormReservation
