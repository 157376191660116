import axios from 'axios'
import config from '../../config'

const { authService } = config

interface ICredential {
  username: string
  password: string
}

export async function getUserToken(credential: ICredential): Promise<any> {
  return new Promise((resolve, reject) => {
    axios
      .post(`${authService.URL}/token/login`, {
        username: credential.username,
        password: credential.password,
        project: authService.AUTH_PROJECT,
        deviceType: 'WEBSITE',
      })
      .then(function (response) {
        const { data } = response
        const { accessToken } = data
        if (accessToken) {
          resolve(data)
        } else {
          reject(data)
        }
      })
      .catch(function (error) {
        console.log(error)
        reject(error)
      })
  })
}

export async function checkValidToken(accessToken: string): Promise<any> {
  return new Promise((resolve, reject) => {
    axios
      .post(`${authService.URL}/token/verify`, {
        accessToken,
        project: authService.AUTH_PROJECT,
      })
      .then(function (response) {
        const { data } = response
        const { token } = data
        if (token) {
          resolve(token)
        } else {
          reject(token)
        }
      })
      .catch(function (error) {
        const httpStatus = error?.response?.status
        if (httpStatus === 401) {
          resolve(false)
        }
        reject(error)
      })
  })
}

export async function logout(accessToken: string | null): Promise<any> {
  return new Promise((resolve, reject) => {
    if (!accessToken) {
      reject(false)
    }
    axios
      .get(`${authService.URL}/token/logout`, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then(() => {
        resolve(true)
      })
      .catch(() => {
        reject(false)
      })
  })
}

export async function tokenLogin(exchangeToken: string): Promise<any> {
  return new Promise((resolve, reject) => {
    axios
      .post(`${authService.URL}/token/exchange`, {
        exchangeToken,
      })
      .then(function (response) {
        resolve(response.data)
      })
      .catch(function (error) {
        console.log(error)
        reject(error)
      })
  })
}
