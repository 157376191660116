import axios from 'axios'
import config from '../../config'

const { restfulService } = config

interface IAuthContext {
  user: any
  userLoggedIn: any
}

export enum ReservationType {
  FLEET = 'fleet',
  CORPORATE = 'corporate',
}

export enum RequestAction {
  accept = 'accept',
  reject = 'reject',
}

export enum ControlAction {
  lock = 'lock',
  unlock = 'unlock',
}

export async function getReservationList(
  authContext: IAuthContext,
  reservationType: ReservationType,
  filterSearch: any,
): Promise<any> {
  return new Promise((resolve, reject) => {
    const userToken = authContext?.userLoggedIn?.userToken
    const page = filterSearch?.page ? filterSearch.page : 1
    const pageSize = filterSearch?.pageSize ? filterSearch.pageSize : 50
    axios
      .get(`${restfulService.URL}/reservation/${reservationType}`, {
        headers: {
          authorization: userToken,
        },
        params: {
          page,
          results: pageSize,
          reservationNo: filterSearch.reservationNo,
          reservationStartDate: filterSearch.reservationStartDate
            ? filterSearch.reservationStartDate.format('YYYY-MM-DD')
            : null,
          reservationStatus:
            filterSearch.reservationStatus === 'ALL' ? null : filterSearch.reservationStatus,
          reservationStopDate: filterSearch.reservationStopDate
            ? filterSearch.reservationStopDate.format('YYYY-MM-DD')
            : null,
          stationId: filterSearch.stationId === 'ALL' ? null : filterSearch.stationId,
          vehicle: filterSearch.vehicle === 'ALL' ? null : filterSearch.vehicle,
        },
      })
      .then(function (response) {
        const { data } = response
        if (data) {
          resolve(data)
        } else {
          reject(data)
        }
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export async function manageRequestReservation(
  authContext: IAuthContext,
  action: RequestAction,
  reservationNo: number | string,
): Promise<any> {
  return new Promise((resolve, reject) => {
    const userToken = authContext?.userLoggedIn?.userToken
    axios
      .post(
        `${restfulService.URL}/partners/request/${action}`,
        { reservationNo },
        {
          headers: {
            authorization: userToken,
          },
        },
      )
      .then(function (response) {
        const { result } = response?.data
        console.log('result', result)
        if (result) {
          resolve(result)
        } else {
          reject(result)
        }
      })
      .catch(function (error) {
        const { message, name } = error?.response?.data
        reject({ name, message })
      })
  })
}

export async function controlVehicle(
  authContext: IAuthContext,
  action: ControlAction,
  reservationNo: number | string,
  vehicleId: number | string,
): Promise<any> {
  return new Promise((resolve, reject) => {
    const userToken = authContext?.userLoggedIn?.userToken
    axios
      .post(
        `${restfulService.URL}/partners/reservation/control/${action}`,
        { reservationNo, vehicleId },
        {
          headers: {
            authorization: userToken,
          },
        },
      )
      .then(function (response) {
        const { result } = response?.data
        console.log('result', result)
        if (result) {
          resolve(result)
        } else {
          reject(result)
        }
      })
      .catch(function (error) {
        const { message, name } = error?.response?.data
        reject({ name, message })
      })
  })
}

export async function getDriveReservation(
  authContext: IAuthContext,
  reservationNo: number | string,
): Promise<any> {
  return new Promise((resolve, reject) => {
    const userToken = authContext?.userLoggedIn?.userToken
    axios
      .get(`${restfulService.URL}/partners/reservation/drive`, {
        headers: {
          authorization: userToken,
        },
        params: {
          reservationNo,
        },
      })
      .then(function (response) {
        const { result } = response?.data
        console.log('result', response.data.data)
        if (result) {
          resolve(response?.data?.data)
        } else {
          reject(result)
        }
      })
      .catch(function (error) {
        const { message, name } = error?.response?.data
        reject({ name, message })
      })
  })
}
