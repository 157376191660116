import { Button, Descriptions, Divider, Result } from 'antd'
import React from 'react'

interface IReservationSummaryProp {
  reservationData: IReservationData
  onReset: any
}

interface IReservationData {
  reservationNo: number
  reservationStatus: string
  userName: string
  reserveStartTime: string
  reserveStopTime: string
  stationCode: string
  stationLink: string
  vehicleCode: string
  licenseNo: string
  vehicleModel: string
  bundleCode: string
  bundleDistance: number
  bundleRate: number
  hourPrice: number
  distancePrice: number
  estimatePrice: number
}

const ReservationSummary = (props: IReservationSummaryProp) => {
  const { reservationData, onReset } = props
  const {
    reservationNo,
    reservationStatus,
    userName,
    reserveStartTime,
    reserveStopTime,
    stationCode,
    stationLink,
    vehicleCode,
    licenseNo,
    vehicleModel,
    bundleCode,
    bundleDistance,
    bundleRate,
    hourPrice,
    distancePrice,
    estimatePrice,
  } = reservationData
  return (
    <div>
      <Result
        status="success"
        title="Confirm reservation success"
        subTitle={`Reservation number: ${reservationNo}`}
        extra={[
          <Button type="primary" key="console" onClick={() => onReset()}>
            Create another reservation
          </Button>,
        ]}
      />
      <Descriptions
        title="Reservation Summary"
        bordered
        column={{ xxl: 2, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }}
        labelStyle={{ fontWeight: 'bold' }}
      >
        <Descriptions.Item label="Reservation No.">{reservationNo}</Descriptions.Item>
        <Descriptions.Item label="Reserve Status">{reservationStatus}</Descriptions.Item>
        <Descriptions.Item label="User">{userName}</Descriptions.Item>
        <Descriptions.Item label="Time">
          <p>From: {reserveStartTime}</p>
          <p>To: {reserveStopTime}</p>
        </Descriptions.Item>
        <Descriptions.Item label="Pickup location">
          <b>{stationCode}</b>
          <p>
            <a href={stationLink} target="_blank">
              (Location)
            </a>
          </p>
        </Descriptions.Item>
        <Descriptions.Item label="Vehicle">
          <p>{vehicleCode}</p>
          <p>license number: {licenseNo}</p>
          <p>Brand: {vehicleModel}</p>
        </Descriptions.Item>
        <Descriptions.Item label="Distance Package">
          <p>{bundleCode}</p>
          <p>Included distance: {bundleDistance} km</p>
          <p>Rate: {bundleRate} ฿/km</p>
        </Descriptions.Item>
        <Descriptions.Item label="Price">
          <p>Hour Price: {hourPrice} ฿</p>
          <p>Distance Price: {distancePrice} ฿</p>
          <p>Estimate Price: {estimatePrice} ฿</p>
        </Descriptions.Item>
      </Descriptions>
      <Divider />
      <Button type="link" onClick={() => onReset()}>
        Back to new reservation
      </Button>
    </div>
  )
}

export default ReservationSummary
