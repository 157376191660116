import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import en from './translations/en.json'
import th from './translations/th.json'

const resources = {
  en,
  th,
}

i18n.use(initReactI18next).init({
  resources,
  lng: 'th',
  fallbackLng: 'en',
  debug: false,
  supportedLngs: ['en', 'th'],
})

export default i18n
