import { LeftOutlined } from '@ant-design/icons'
import { Row } from 'antd'
import { Link, useHistory } from 'react-router-dom'
import { MobileTitle } from '../../components/Dashboard/MobileTitle'
import styles from './css/backoutdate.module.css'

export const BlackoutDateHeader = (props: any) => {
  const history = useHistory()
  if (props.useOld === true) {
    return (
      <div className={`${styles.header}`}>
        <Row>
          <div className={`${styles.header_box_left}`}>
            {props.gotoback ? (
              <Link to="#" onClick={props.handleBack} className={styles.go_to_back}>
                <LeftOutlined className={`${styles.icon_go_to_back}`} />
              </Link>
            ) : (
              ''
            )}
          </div>
          <div className={`${styles.header_box_center}`}>
            <label className={`${styles.title_page}`}>{props.title}</label>
          </div>
          <div className={`${styles.header_box_right}`}>
            {props.btn_resrt ? (
              <button
                type="button"
                className={`${styles.btn_reset}`}
                onClick={(e) => props.handleReset(e)}
              >
                reset
              </button>
            ) : (
              ''
            )}
          </div>
        </Row>
      </div>
    )
  }

  return (
    <MobileTitle
      title={props.title}
      onBack={props.handleBack}
      extra={
        props.btn_resrt ? (
          <button
            type="button"
            className={`${styles.btn_reset}`}
            onClick={(e) => props.handleReset(e)}
          >
            reset
          </button>
        ) : undefined
      }
    />
  )
}
