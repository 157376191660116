import * as dotenv from 'dotenv'
dotenv.config()

const service = {
  NAME: process.env.REACT_APP_SERVICE_NAME || 'haup-partners',
}

const authService = {
  URL: process.env.REACT_APP_TS_AUTHENTICATION_URL,
  AUTH_PROJECT: process.env.REACT_APP_AUTH_PROJECT,
}

const restfulService = {
  URL: process.env.REACT_APP_TS_RESTFUL_API_URL,
}

const credentials = {
  freshchat: {
    TOKEN: process.env.REACT_APP_FRESHCHAT_TOKEN,
  },
}

const config = {
  service,
  authService,
  restfulService,
  credentials,
}

export default config
