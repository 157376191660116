import { SearchOutlined } from '@ant-design/icons'
import { Button, Card, Col, Layout, Row, Typography } from 'antd'
import React from 'react'
import styled from 'styled-components'

const { Sider } = Layout

const { Title } = Typography

const RightCol = styled(Col)`
  text-align: right;
  word-wrap: break-word;
`

interface IReservationDetail {
  data: any
}

interface IRenderDetailCard {
  key: string
  vehicleCode: string
  vehicleLicense: string
  vehicleModel: string
  vehicleColor: string
  vehicleStatus: string
  vehicleAction: string
}

interface IRenderGpsCard {
  key: string
  gpsstatus: string
  gpstime: string
  latitude: string
  longitude: string
  engine: string
  doorlock: string
}

interface IRenderStationCard {
  key: string
  vehiclegroup: string
  price: string
  originstation: string
  currentstation: string
}

interface IRenderBookingCard {
  key: string
  bookingno: string
  username: string
  bookingstart: string
  bookingstop: string
}

const RenderDetailCard: React.FC<{ data: IRenderDetailCard }> = (data) => {
  return (
    <>
      <Title level={3}>{`${data.data.vehicleCode}`}</Title>
      <Title level={4}>{`${data.data.vehicleLicense}`}</Title>
      <Card title="Vehicle Detail" style={{ width: 370 }}>
        <Row>
          <Col span={12}>
            <p>Model</p>
          </Col>
          <RightCol span={12}>
            <p>{data.data.vehicleModel}</p>
          </RightCol>
        </Row>
        <Row>
          <Col span={12}>
            <p>Color / Seat</p>
          </Col>
          <RightCol span={12}>
            <p>{data.data.vehicleColor}</p>
          </RightCol>
        </Row>
        <Row>
          <Col span={12}>
            <p>Status</p>
          </Col>
          <RightCol span={12}>
            <p>{data.data.vehicleStatus}</p>
          </RightCol>
        </Row>
        <Row>
          <Col span={12}>
            <p>Recent Action</p>
          </Col>
          <RightCol span={12}>
            <p>{data.data.vehicleAction}</p>
          </RightCol>
        </Row>
        <Row>
          <Col span={12}>
            <p>Reservation</p>
          </Col>
          <RightCol span={12}>
            <Button icon={<SearchOutlined />}>List</Button>
          </RightCol>
        </Row>
        <Row>
          <Col span={12}>
            <p>Note</p>
          </Col>
          <RightCol span={12}>
            <Button icon={<SearchOutlined />}>List</Button>
          </RightCol>
        </Row>
      </Card>
    </>
  )
}

const RenderGpsCard: React.FC<{ data: IRenderGpsCard }> = (data) => {
  /*
  let color = ''
  const { gpsstatus } = data
  if (gpsstatus === 'OFFLINE') {
    color = 'rgb(255, 0, 0)'
  }
  */
  return (
    <>
      <Card title="GPS" style={{ width: 370 }}>
        <Row>
          <Col span={12}>
            <p>GPS Status</p>
          </Col>
          <RightCol span={12}>
            <p /*color={color}*/>{data.data.key}</p>
          </RightCol>
        </Row>
        <Row>
          <Col span={12}>
            <p>GPS Time</p>
          </Col>
          <RightCol span={12}>
            <p>{data.data.key}</p>
          </RightCol>
        </Row>
        <Row>
          <Col span={12}>
            <p>Location</p>
          </Col>
          <RightCol span={12}>
            <p>
              {data.data.latitude} , {data.data.longitude}
            </p>
          </RightCol>
        </Row>
        <Row>
          <Col span={12}>
            <p>Engine</p>
          </Col>
          <RightCol span={12}>
            <p>{data.data.engine}</p>
          </RightCol>
        </Row>
        <Row>
          <Col span={12}>
            <p>Doorlock</p>
          </Col>
          <RightCol span={12}>
            <p>{data.data.key}</p>
          </RightCol>
        </Row>
      </Card>
    </>
  )
}

const RenderStationCard: React.FC<{ data: IRenderStationCard }> = (data) => {
  return (
    <>
      <Card title="Station" style={{ width: 370 }}>
        <Row>
          <Col span={12}>
            <p>Vehicle Group</p>
          </Col>
          <RightCol span={12}>
            <p>{data.data.key}</p>
          </RightCol>
        </Row>
        <Row>
          <Col span={12}>
            <p>Price</p>
          </Col>
          <RightCol span={12}>
            <p>{data.data.key}</p>
          </RightCol>
        </Row>
        <Row>
          <Col span={12}>
            <p>Host Station</p>
          </Col>
          <RightCol span={12}>
            <p>{data.data.originstation}</p>
          </RightCol>
        </Row>
        <Row>
          <Col span={12}>
            <p>Current Station</p>
          </Col>
          <RightCol span={12}>
            <p>{data.data.currentstation}</p>
          </RightCol>
        </Row>
        <Row>
          <Col span={12}>
            <p>Current Station</p>
          </Col>
          <RightCol span={12}>
            <p>{data.data.currentstation}</p>
          </RightCol>
        </Row>
      </Card>
    </>
  )
}

const RenderBookingCard: React.FC<{ data: IRenderBookingCard }> = (data) => {
  return (
    <>
      <Card title="Booking Info" style={{ width: 370 }}>
        <Row>
          <Col span={12}>
            <p>Booking No.</p>
          </Col>
          <RightCol span={12}>
            <p>{data.data.key}</p>
          </RightCol>
        </Row>
        <Row>
          <Col span={12}>
            <p>Username</p>
          </Col>
          <RightCol span={12}>
            <p>{data.data.key}</p>
          </RightCol>
        </Row>
        <Row>
          <Col span={12}>
            <p>Booking Start Time</p>
          </Col>
          <RightCol span={12}>
            <p>{data.data.bookingstart}</p>
          </RightCol>
        </Row>
        <Row>
          <Col span={12}>
            <p>Booking Stop Time</p>
          </Col>
          <RightCol span={12}>
            <p>{data.data.bookingstop}</p>
          </RightCol>
        </Row>
      </Card>
    </>
  )
}

class VehicleDetail extends React.Component<IReservationDetail> {
  render() {
    const gpsStatus = this.props?.data?.key
    return (
      <>
        <Sider style={{ backgroundColor: 'white' }} width="350px">
          <RenderDetailCard data={this.props.data} />
          <RenderGpsCard data={this.props.data} />
          <RenderStationCard data={this.props.data} />
          {gpsStatus === 'OFFLINE' && <RenderBookingCard data={this.props.data} />}
        </Sider>
      </>
    )
  }
}

export default VehicleDetail
