import React from 'react'
import { RouteComponentProps } from 'react-router'
import { AuthProvider } from '../../contexts/Auth'

class AuthenticatedPage extends React.Component<RouteComponentProps> {
  render(): JSX.Element {
    return (
      <>
        <AuthProvider {...this.props}>{this.props.children}</AuthProvider>
      </>
    )
  }
}

export default AuthenticatedPage
