import { ExclamationCircleFilled } from '@ant-design/icons'
import { Badge, Button, Col, Modal, Progress, Row, Space, Tooltip, Typography, message } from 'antd'
import moment from 'moment'
import React from 'react'
import { Trans, WithTranslation, withTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { THB } from '../../../components/Dashboard/ReservationList/ReservationListItem'
import { MetricBadge, WideMetricBadge } from '../../../components/MetricBadge'
import { theme } from '../../../config/style'
import { AuthContext } from '../../../contexts/Auth'
import i18n from '../../../i18n'
import {
  changeVehicleAvailability,
  countReservations,
  getHostProfile,
  getHostVehicle,
  getReview,
} from '../../../services/Host'
import { PendingReservationSvg, ReservedReservationSvg } from '../../../static/img/icon/reservation'
import Calendar from './Calendar'
import Profile from './Profile'
import Review from './Review'
import Vehicle from './Vehicle'
import './style.css'

const StyledButton = styled(Button)`
  border-radius: 18rem;
`

const SecondaryButton = styled.button`
  background-color: ${theme.haupBlue};
  border: 0;
  color: white;
  width: 100%;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 100px;
`

const SecondaryContainer = styled.div`
  background-color: ${theme.haupBlueSecondary};
  color: ${theme.haupBlue};
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;
  padding: 1rem;
  height: 100%;
  & * {
    color: ${theme.haupBlue};
  }
  & h1 {
    font-size: 1.8rem;
  }
  & p {
    font-size: 0.9rem;
    font-weight: 500;
  }
`

interface MatchParams {
  reservationNo: string
}

interface Props extends RouteComponentProps<MatchParams> {
  prop?: any
}

type MoreProps = Props & WithTranslation

export interface IReservationDetail {
  reservationNo: number
  reservationState: string
  renterName: string
  renterScore: number
  vehicleLicense: string
  vehicleModel: string
  vehicleScore: number
  vehicleReviews: number
  vehiclePicture: string
  reserveStartTime: string
  reserveStopTime: string
  hostStationName: string
  hostStationLink: string
  dstStationName: string
  dstStationLink: string
  estimatePrice: number
  note: string
}

function mapReviewData(reviewList: any) {
  return reviewList.map((item: any) => {
    return {
      name: item?.user?.name,
      comment: item?.text,
      licenseNumber: item?.licenseNumber,
      vehicleModel: item?.vehicleModel,
      reserveStartTime: '',
      reserveStopTime: '',
      reservationNo: item?.reservationNo,
      reply: item?.reply,
    }
  })
}

function mapVehicleData(vehicleList: any) {
  return vehicleList.map((item: any) => {
    const {
      vehicleModel,
      vehicleYear,
      licenseNumber,
      charge,
      picUrl,
      card_color,
      host_color,
      charge_color,
      verifiedType,
      verifiedType_color,
      verifiedType_text_color,
      nextReservation,
      insuranceExpiredate,
      insuranceExpire_color,
      vehicleId,
      availability,
    } = item
    const stationName = item?.station.name
    return {
      vehicleModel,
      vehicleYear,
      licenseNumber,
      hostStationName: stationName,
      battery: charge,
      image: picUrl,
      cardColor: card_color,
      vehicleColor: host_color,
      batteryColor: charge_color,
      verifiedType,
      verifiedType_color,
      verifiedType_text_color,
      nextReservation,
      insuranceExpiredate,
      insuranceExpire_color,
      vehicleId,
      availability,
    }
  })
}

class HostReservation extends React.Component<MoreProps> {
  static contextType = AuthContext
  state = {
    userData: {
      name: '',
      lastName: '',
      joinedDate: '',
      rating: 0,
      totalReservation: 0,
    },
    vehicle: {
      starterCars: 0,
      verifiedCars: 0,
      verifiedPro: 0,
      averageServiceTime: 0,
      carsInReservation: 0,
      onService: 0,
      maintenance: 0,
    },
    reservation: {
      declinedRequest: 0,
      messageFromRenter: 0,
      monthReservations: 0,
      pendingRequest: 0,
      responseRate: 0,
      upcomingReservation: 0,
      pendingCount: 0,
      allCount: 0,
      maCount: 0,
      reservedCount: 0,
    },
    reviews: 0,
    currentRevenue: 0,
    reservationList: [],
    reviewList: [],
    vehicleList: [],
  }

  componentDidMount() {
    const reservationNo = this.props?.match?.params?.reservationNo
    this.setState((prev) => ({ ...prev, reservationNo }))
    this.getHostProfile()
    this.getHostVehicle()
    this.getReview()
    this.getReservationCount()

    const w: any = window
    if (w.fcWidget?.isInitialized()) {
      w.fcWidget?.setTags(['host-dashboard'])
    }
  }

  componentWillUnmount(): void {
    const w: any = window
    if (w.fcWidget?.isInitialized()) {
      w.fcWidget?.setTags([''])
    }
  }

  async getReservationCount() {
    const startOfMonth = moment().startOf('month').toISOString()
    const endOfMonth = moment().endOf('month').toISOString()
    const allCount = await countReservations(this.context, {
      reservationStatus: 'ALL',
      dateFrom: startOfMonth,
      dateTo: endOfMonth,
    })
    const pendingCount = await countReservations(this.context, {
      reservationStatus: 'REQUEST',
    })
    const maCount = await countReservations(this.context, {
      reservationStatus: 'RESERVE',
      dateFrom: startOfMonth,
      dateTo: endOfMonth,
      reservationCategory: 'MA',
    })
    const reservedCount = await countReservations(this.context, {
      reservationStatus: 'RESERVE',
    })
    this.setState({
      ...this.state,
      reservation: { ...this.state.reservation, pendingCount, allCount, maCount, reservedCount },
    })
  }

  getHostProfile() {
    getHostProfile(this.context).then((result) => {
      const { name, lastName, metric, startServiceDate } = result
      const {
        averageRating,
        completeReservations,
        currentRevenue,
        declinedRequest,
        messageFromRenter,
        monthReservations,
        pendingRequest,
        responseRate,
        upcomingReservation,
      } = metric
      this.setState({
        ...this.state,
        userData: {
          name,
          lastName,
          joinedDate: startServiceDate,
          rating: averageRating ? averageRating.toFixed(1) : 0,
          totalReservation: completeReservations,
        },
        reservation: {
          ...this.state.reservation,
          declinedRequest,
          messageFromRenter,
          monthReservations,
          pendingRequest,
          responseRate,
          upcomingReservation,
        },
        currentRevenue,
      })
    })
  }

  getHostVehicle() {
    getHostVehicle(this.context).then((result) => {
      const {
        totalReview,
        starterCars,
        verifiedCars,
        verifiedPro,
        averageServiceTime,
        carsInReservation,
        onService,
        maintenance,
      } = result?.metric
      this.setState({
        vehicle: {
          starterCars,
          verifiedCars,
          verifiedPro,
          averageServiceTime: Math.round(averageServiceTime),
          carsInReservation,
          onService,
          maintenance,
        },
        reviews: totalReview,
        vehicleList: mapVehicleData(result?.vehicles),
      })
    })
  }

  getReview() {
    getReview(this.context).then((result) => {
      const reviews = result?.review?.docs
      const mappedReviews = mapReviewData(reviews)
      this.setState({
        reviewList: mappedReviews,
      })
    })
  }

  async changeAvailability(turnOn: boolean, vehicleId: number) {
    try {
      const response = await changeVehicleAvailability(this.context, vehicleId, turnOn)
      const newVehicleList: Record<string, unknown>[] = [...this.state.vehicleList]
      for (const v of response) {
        const index = this.state.vehicleList.findIndex((ve: any) => ve.vehicleId === v.vehicleId)
        newVehicleList[index].availability = v
      }
      this.setState((prev) => ({ ...prev, vehicleList: newVehicleList }))
    } catch (error: any) {
      message.error(error?.response?.data?.message || error?.message || error)
    }
  }

  render() {
    const currentMonth = moment().locale(i18n.resolvedLanguage).format('MMM')
    const currentYear = moment().year
    const lastDayInMonth = moment().locale(i18n.resolvedLanguage).endOf('month').format('DD')
    const t = this.props.t
    const vehicleList = this.state.vehicleList

    const onChangeAvailability = (turnOn: boolean, vehicleId: number) => {
      const vehicle: any = vehicleList[vehicleList.findIndex((v: any) => v.vehicleId === vehicleId)]
      if (!vehicle.availability.changeable) {
        return
      }
      Modal.confirm({
        icon: null,
        content: (
          <Space direction="vertical">
            <div style={{ width: '100%', textAlign: 'center' }}>
              <ExclamationCircleFilled style={{ fontSize: '40px' }} />
            </div>
            <Typography.Title level={5} style={{ textAlign: 'center' }}>
              {turnOn === true
                ? t('HOST_DASHBOARD:VEHICLE_CARD.TURNON_MODAL_HEAD')
                : t('HOST_DASHBOARD:VEHICLE_CARD.TURNOFF_MODAL_HEAD')}
            </Typography.Title>
            <Typography.Paragraph style={{ width: '100%', textAlign: 'center' }}>
              {turnOn === true
                ? t('HOST_DASHBOARD:VEHICLE_CARD.TURNON_MODAL_CONTENT')
                : t('HOST_DASHBOARD:VEHICLE_CARD.TURNOFF_MODAL_CONTENT')}
            </Typography.Paragraph>
          </Space>
        ),
        onOk: () => this.changeAvailability(turnOn, vehicleId),
        okText: t('COMMON:OK'),
        cancelText: t('COMMON:CANCEL'),
      })
    }

    return (
      <div
        style={{ height: '100%', backgroundColor: 'rgb(207 210 215 / 47%)', fontSize: 'smaller' }}
      >
        <Row justify="center">
          <Col flex="0 1 500px">
            <Profile
              userData={{ ...this.state.userData, reviews: this.state.reviews }}
              vehicle={this.state.vehicle}
            />
            <Row
              className="pad-content"
              style={{ backgroundColor: 'white', color: theme.textPrimary }}
            >
              <Col span={12}>
                <p>{t('HOST_DASHBOARD:CURRENT_REVENUE')}</p>
                <p>
                  (01 {currentMonth} - {lastDayInMonth} {currentMonth})
                </p>
              </Col>
              <Col
                span={12}
                style={{
                  textAlign: 'right',
                  fontSize: '1.5rem',
                  color: theme.haupBlue,
                  fontWeight: 500,
                }}
              >
                {THB(this.state.currentRevenue)}
              </Col>
            </Row>
            <div style={{ background: 'white', padding: '0 1rem' }}>
              <div style={{ borderTop: '1px solid #00000010' }} />
            </div>

            <Row className="white-bg pad-content">
              <Col span={24}>
                <h1 className="section-header" hidden>
                  {currentMonth}
                </h1>

                <Row hidden>
                  {/** Month and service time */}
                  <Col flex="auto">
                    <Tooltip title="3 done / 3 in progress / 4 to do">
                      <Progress
                        percent={this.state.vehicle.averageServiceTime}
                        success={{ percent: this.state.vehicle.averageServiceTime }}
                        style={{ height: '20px' }}
                      />
                    </Tooltip>
                  </Col>
                  <Col
                    flex="40px"
                    style={{ textAlign: 'right', color: '#80A3CF', fontSize: '0.7rem' }}
                  >
                    {t('HOST_DASHBOARD:SERVICE_TIME')}
                  </Col>
                </Row>

                <Row
                  hidden
                  // 2row
                  // gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                  gutter={16}
                  style={{ textAlign: 'center', marginTop: '1rem' }}
                  justify="space-around"
                >
                  <Col span={12}>
                    <WideMetricBadge
                      value={this.state.reservation.pendingCount}
                      label={<Trans i18nKey="HOST_DASHBOARD:STATUS_PENDING" />}
                      style={{ color: 'black' }}
                      dotVisible
                      link={{
                        to: {
                          pathname: '/host/dashboard/reservation',
                          search: '?reservationStatus=REQUEST',
                        },
                      }}
                    />
                  </Col>
                  <Col span={12}>
                    <WideMetricBadge
                      value={this.state.reservation.upcomingReservation}
                      label={<Trans i18nKey={'HOST_DASHBOARD:STATUS_RESERVE'} />}
                      style={{ color: 'black' }}
                      link={{
                        to: {
                          pathname: '/host/dashboard/reservation',
                          search: '?reservationStatus=RESERVE',
                        },
                      }}
                    />
                  </Col>
                </Row>

                <Row
                  hidden
                  // 2row
                  // gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                  gutter={16}
                  style={{ textAlign: 'center', marginTop: '16px' }}
                  justify="space-around"
                >
                  <Col span={12}>
                    <WideMetricBadge
                      value={this.state.reservation.maCount}
                      label={<Trans i18nKey={'HOST_DASHBOARD:STATUS_MA'} />}
                      style={{ color: 'black' }}
                      link={{
                        to: {
                          pathname: '/host/dashboard/reservation',
                          search: '?reservationStatus=RESERVE&reservationCategory=MA',
                        },
                      }}
                    />
                  </Col>
                  <Col span={12}>
                    <Link to={{ pathname: '/host/dashboard/reservation' }}>
                      <WideMetricBadge
                        value={this.state.reservation.allCount}
                        label={<Trans i18nKey={'HOST_DASHBOARD:STATUS_ALL'} />}
                        style={{ color: 'black' }}
                      />
                    </Link>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={12}>
                    <Link
                      to={{
                        pathname: '/host/dashboard/reservation',
                        search: '?reservationStatus=REQUEST',
                      }}
                    >
                      <SecondaryContainer>
                        <div>
                          <PendingReservationSvg style={{ fontSize: '30px' }} />
                        </div>
                        <div style={{ marginTop: '3rem' }}>
                          <Badge dot count={this.state.reservation.pendingCount}>
                            <h1>{this.state.reservation.pendingCount}</h1>
                          </Badge>
                        </div>
                        <div>
                          <p>{t('HOST_DASHBOARD:STATUS_PENDING_LONG')}</p>
                        </div>
                      </SecondaryContainer>
                    </Link>
                  </Col>
                  <Col span={12}>
                    <Link
                      to={{
                        pathname: '/host/dashboard/reservation',
                        search: '?reservationStatus=RESERVE&monthYear=all',
                      }}
                    >
                      <SecondaryContainer>
                        <div>
                          <ReservedReservationSvg style={{ fontSize: '30px' }} />
                        </div>
                        <div style={{ marginTop: '3rem' }}>
                          <Badge dot count={0}>
                            <h1>{this.state.reservation.reservedCount}</h1>
                          </Badge>
                        </div>
                        <div>
                          <p>{t('HOST_DASHBOARD:STATUS_RESERVE_LONG')}</p>
                        </div>
                      </SecondaryContainer>
                    </Link>
                  </Col>
                </Row>
              </Col>
            </Row>
            {/*car status*/}
            <Row className="pad-content" hidden>
              <Col span={24} style={{ textAlign: 'center' }}>
                <h1 className="section-header">{t('HOST_DASHBOARD:CAR_STATUS')}</h1>

                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ textAlign: 'center' }}>
                  <Col span={24}>
                    <Space>
                      <Col>
                        <MetricBadge
                          value={this.state.reservation.upcomingReservation}
                          label={t('HOST_DASHBOARD:CARS_UPCOMING_RESERVATION')}
                        />
                      </Col>
                      <Col>
                        <MetricBadge
                          value={this.state.vehicle.carsInReservation}
                          label={t('HOST_DASHBOARD:CARS_IN_RESERVATION')}
                        />
                      </Col>
                      <Col>
                        <MetricBadge
                          value={this.state.vehicle.onService}
                          label={t('HOST_DASHBOARD:CARS_IN_SERVICE')}
                        />
                      </Col>
                      <Col>
                        <MetricBadge
                          value={this.state.vehicle.maintenance}
                          label={t('HOST_DASHBOARD:CARS_IN_MAINTENANCE')}
                        />
                      </Col>
                    </Space>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="pad-content white-bg">
              <Col span={24} hidden>
                {this.state.vehicleList.length > 0 && (
                  <Calendar vehicleList={this.state.vehicleList} />
                )}
              </Col>
              <Col span={24}>
                <a href="/host/blackoutdate">
                  <SecondaryButton>{t('HOST_DASHBOARD:MANAGE_MY_CALENDAR')}</SecondaryButton>
                </a>
              </Col>
            </Row>
            <Row className="calendar" gutter={16} style={{ margin: '16px 0' }}>
              {this.state.vehicleList.map((item: any) => {
                return (
                  <Vehicle
                    vehicleData={item}
                    fullSize={this.state.vehicleList.length < 2}
                    onChangeAvailability={(v) => onChangeAvailability(v, item.vehicleId)}
                  />
                )
              })}
            </Row>
            {this.state.reviewList.length > 0 && (
              <Row>
                <Col span={24}>
                  <h3 className="pad-content" style={{ color: '#505050' }}>
                    {t('HOST_DASHBOARD:RECENT_REVIEW')}
                  </h3>

                  {this.state.reviewList.map((item) => (
                    <Review reviewData={item} />
                  ))}
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </div>
    )
  }
}

export default withTranslation()(HostReservation)
