import { Rate, Row } from 'antd'
import { CarsType } from './BlackoutDateType'
import styles from './css/backoutdate.module.css'
export const CardListCars = (props: any) => {
  const handleSelectCar = (e: any) => {
    props.setSelectCar(e.target.value)
  }
  const replaceDes = (text: string) => {
    if (text.length > 40) {
      return text.substring(0, 39)
    }
    return text
  }

  const handleClickCard = (vehicleId: number) => {
    props.setSelectCar(vehicleId)
  }

  return (
    <>
      {props.listCars.length > 0
        ? props.listCars?.map((val: CarsType) => {
            return (
              <>
                <div
                  className={`${styles.cards} ${
                    Number(props.selectCar) === val.vehicleId ? styles.select_car_card : ''
                  }`}
                  onClick={() => handleClickCard(val.vehicleId)}
                >
                  <div className={styles.box_img_car}>
                    <img
                      src={val.picUrl ? val.picUrl : '/image/Car.svg'}
                      className={styles.img_car}
                      alt={val.name}
                    />
                  </div>
                  <div className={styles.box_detail_list_car}>
                    <div className={styles.box_title_car_name}>
                      <label className={styles.title_car_name}>{val.vehicleModel}</label>
                      <div className={styles.dot_card} style={{ background: val.hostColor }}></div>
                    </div>
                    <div className={styles.box_car_registration}>
                      <p className={styles.card_car_registration}>{val.licenseNumber}</p>
                      <p className={styles.card_car_registration}>{val.licenseProvince}</p>
                    </div>
                    <div className={styles.box_rateing}>
                      <Rate
                        allowHalf
                        disabled
                        value={Number(val.rating)}
                        className={styles.rate_car}
                      />
                    </div>
                    <div className={styles.box_location_card}>
                      <Row>
                        <div className={styles.des_location_car}>
                          <img
                            src="/image/icon_location_haupcar.svg"
                            alt="icon_location_haupcar"
                            className={styles.icon_location_haupcar}
                          />
                          <p className={styles.des_location_p}>{replaceDes(val.station?.name)}</p>
                        </div>
                        <div className={styles.box_radio}>
                          <input
                            type="radio"
                            name="vehicleid"
                            value={val.vehicleId}
                            onChange={(e) => handleSelectCar(e)}
                            checked={Number(props.selectCar) === val.vehicleId ? true : false}
                          />
                        </div>
                      </Row>
                    </div>
                  </div>
                </div>
              </>
            )
          })
        : ''}
    </>
  )
}
