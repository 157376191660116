interface CalendarState {
  date: string | Date
}

const initialState: CalendarState = {
  date: '',
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case 'SET_SELECT_DATE':
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}
