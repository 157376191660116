import axios from 'axios'
import config from '../../config'

const { restfulService } = config

interface IAuthContext {
  user: any
  userLoggedIn: any
}

export async function getStationList(authContext: IAuthContext): Promise<any> {
  return new Promise((resolve, reject) => {
    const userToken = authContext?.userLoggedIn?.userToken
    axios
      .get(`${restfulService.URL}/station`, {
        headers: {
          authorization: userToken,
        },
      })
      .then(function (response) {
        const { data } = response
        if (data) {
          resolve(data)
        } else {
          reject(data)
        }
      })
      .catch(function (error) {
        reject(error)
      })
  })
}
