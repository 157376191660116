import { Button, Col, DatePicker, Form, InputNumber, Layout, Row, Select, Space } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { AuthContext } from './../../contexts/Auth'
import { getReservationList, ReservationType } from './../../services/reservation'
import { getStationList } from './../../services/station'
import { getSimpleVehicleList } from './../../services/vehiclelist'
import ReservationDetail from './ReservationDetail'
import ReservationList from './ReservationList'
const { Content } = Layout

const StyledButton = styled(Button)`
  background-color: #17a2b8;
  border-color: #17a2b8;
  &:hover {
    background-color: #3aa8ba;
  }
  &:focus {
    background-color: #17a2b8;
  }
`

const { Option } = Select

function transformStationCode(stationList: IStationList[], stationId: number) {
  const stationcode = stationList.filter((item) => item.stationid === stationId)
  //console.log(stationId, stationcode, stationList)
  if (stationcode.length === 0) {
    return 'undefied'
  }
  return stationcode[0].stationcode
}

function transformReservationData(rawData: any[], stationList: IStationList[]) {
  return rawData.map((item) => {
    // const originStationCode = transformStationCode(stationList, item.stationid)
    // const destinationStationCode =
    //   item.dst_stationid === 0
    //     ? originStationCode
    //     : transformStationCode(stationList, item.dst_stationid)
    const {
      reservationno,
      reservationstate,
      vehicleid,
      vehiclecode,
      reservestarttime,
      reservestoptime,
      category,
      username,
      drivendistance,
      approve_username,
      reservehours,
      freehours,
      chargehours,
      hourrate,
      dayrate,
      latehourrate,
      discount,
      totalprice,
      payment,
      bundlecode,
      bundleprice,
      bundlekm,
      override_distancerate,
      host_stationcode,
      dst_stationcode,
    } = item
    const transformedData = {
      key: reservationno,
      reservationStatus: {
        reserveNo: reservationno,
        reserveStatus: reservationstate,
      },
      vehicle: vehiclecode,
      vehicleid: vehicleid,
      reservationTime: {
        reserveStarttime: reservestarttime,
        reserveStoptime: reservestoptime,
      },
      reservationOrigin: {
        reserveOrigin: host_stationcode,
        reserveDestination: dst_stationcode,
      },
      reservationType: category,
      username: username,
      distance: drivendistance,
      approved: approve_username,
      hours: reservehours,
      freehours: freehours,
      chargehours: chargehours,
      hourrate: hourrate,
      dayrate: dayrate,
      latehourrate: latehourrate,
      discount: discount,
      totalprice: totalprice,
      paymenttype: payment,
      bundlepackage: bundlecode,
      bundleprice: bundleprice,
      bundledistance: bundlekm,
      distancerate: override_distancerate,
    }
    return transformedData
  })
}

interface IStationList {
  stationid: number
  stationcode: string
}

interface IVehicleList {
  vehicleid: number
  vehiclecode: string
}

interface IPagination {
  current: number
  pageSize: number
  total?: number
}

type ReservationState = {
  reservationDetail: any
  siderCollapsed: boolean
  stationList: any[]
  vehicleList: any[]
  reservationList: any[]
  isLoadStation: boolean
  isLoadVehicle: boolean
  isLoadReservation: boolean
  pagination: IPagination
  loading: boolean
}

type ReservationProp = {
  prop: ReservationType
}

class Reservation extends React.Component<ReservationProp, ReservationState> {
  transformedData: any

  state = {
    reservationDetail: null,
    siderCollapsed: true,
    stationList: [],
    vehicleList: [],
    pagination: {
      current: 1,
      pageSize: 50,
    },
    reservationList: [],
    isLoadStation: true,
    isLoadVehicle: true,
    isLoadReservation: true,
    loading: false,
  }
  static contextType = AuthContext

  getReservationDetail = (rowIndex: any): void => {
    console.log('RowIndex', rowIndex)
    this.setState({ reservationDetail: this.transformedData[rowIndex], siderCollapsed: false })
    console.log(this.state)
  }

  componentDidMount() {
    if (this.state.isLoadStation) {
      getStationList(this.context)
        .then((result: { data: any }) => {
          this.setState({ stationList: [...result.data] })
        })
        .then(() => {
          this.setState({ isLoadStation: false })
        })
    }
    if (this.state.isLoadVehicle) {
      getSimpleVehicleList(this.context)
        .then((result: { data: any }) => {
          this.setState({ vehicleList: [...result.data] })
        })
        .then(() => {
          this.setState({ isLoadVehicle: false })
        })
    }
    if (this.state.isLoadReservation) {
      this.getFleetReservation({})
    }
  }

  getFleetReservation = (data: any): void => {
    this.setState({ reservationList: [], loading: true, siderCollapsed: true })
    this.transformedData = []
    getReservationList(this.context, this.props.prop, data)
      .then(
        (reservationlist: {
          data: any
          meta: { page: number; results: number; total: number }
        }) => {
          const { total } = reservationlist.meta
          this.setState({
            reservationList: [...reservationlist.data],
            pagination: { ...this.state.pagination, total: total },
            loading: false,
          })
          this.transformedData = transformReservationData(
            reservationlist.data,
            this.state.stationList,
          )
        },
      )
      .then(() => {
        this.setState({ isLoadReservation: false })
      })
  }

  getSimpleVehicleList = (data: any): void => {
    this.setState({ reservationList: [], loading: true })
    this.transformedData = []
    getReservationList(this.context, this.props.prop, data)
      .then(
        (reservationlist: {
          data: any
          meta: { page: number; results: number; total: number }
        }) => {
          const { total } = reservationlist.meta
          this.setState({
            reservationList: [...reservationlist.data],
            pagination: { ...this.state.pagination, total: total },
            loading: false,
          })
          this.transformedData = transformReservationData(
            reservationlist.data,
            this.state.stationList,
          )
        },
      )
      .then(() => {
        this.setState({ isLoadReservation: false })
      })
  }

  handleTableChange = (pagination: IPagination): void => {
    console.log('pagination', pagination)
    const data = { page: pagination.current, pageSize: pagination.pageSize }
    this.getFleetReservation(data)
    this.setState({ pagination })
  }

  getFilteredReservation = (data: any): void => {
    this.setState({ pagination: { ...this.state.pagination, current: 1 } })
    this.getFleetReservation(data)
  }

  render() {
    return (
      <Row style={{ height: '100%' }} wrap={false}>
        <Col style={{ height: '100%', overflowY: 'auto' }} flex="auto">
          <Content style={{ backgroundColor: 'white' }}>
            <Form layout="inline" onFinish={this.getFilteredReservation}>
              <Space wrap>
                <Button type="primary" disabled>
                  Request 0
                </Button>
                <StyledButton type="primary" href="/reservation/new/all">
                  New Reservation
                </StyledButton>
                <Form.Item name="reservationNo" label="Reservation No.">
                  <InputNumber />
                </Form.Item>
                <Form.Item name="reservationStatus" label="Status">
                  <Select defaultValue="ALL" style={{ width: 120 }}>
                    <Option value="ALL">ALL</Option>
                    <Option value="CANCEL">CANCEL</Option>
                    <Option value="COMPLETE">COMPLETE</Option>
                    <Option value="DRIVE">DRIVE</Option>
                    <Option value="FINISH">FINISH</Option>
                    <Option value="RESERVE">RESERVE</Option>
                    <Option value="REJECT">REJECT</Option>
                    <Option value="REQUEST">REQUEST</Option>
                  </Select>
                </Form.Item>
                <Form.Item name="vehicle" label="Vehicle">
                  <Select
                    showSearch
                    defaultValue="ALL"
                    style={{ width: 150 }}
                    optionFilterProp="children"
                  >
                    <Option value="ALL">ALL</Option>
                    {!this.state.isLoadVehicle &&
                      this.state.vehicleList.map((option: IVehicleList) => (
                        <Option key={option.vehicleid} value={option.vehicleid}>
                          {option.vehiclecode}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
                <Form.Item name="stationId" label="Station">
                  <Select
                    showSearch
                    defaultValue="ALL-STATION"
                    style={{ width: 150 }}
                    optionFilterProp="children"
                  >
                    <Option value="ALL">ALL-STATION</Option>
                    {!this.state.isLoadStation &&
                      this.state.stationList.map((option: IStationList) => (
                        <Option key={option.stationid} value={option.stationid}>
                          {option.stationcode}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
                <Form.Item name="reservationStartDate" label="From">
                  <DatePicker format="YYYY-MM-DD" />
                </Form.Item>
                <Form.Item name="reservationStopDate" label="To">
                  <DatePicker />
                </Form.Item>
                <Button type="primary" htmlType="submit">
                  Search
                </Button>
                <Button>Export</Button>
              </Space>
            </Form>
            <br />
            <ReservationList
              dataSource={this.transformedData}
              getReservationDetail={this.getReservationDetail}
              pagination={this.state.pagination}
              onTableChange={this.handleTableChange}
              loading={this.state.loading}
            />
          </Content>
        </Col>

        {!this.state.siderCollapsed && (
          <Col style={{ height: '100%', overflowY: 'auto' }} flex="none">
            <ReservationDetail data={this.state.reservationDetail} />
          </Col>
        )}
      </Row>
      // </Layout>
    )
  }
}

export default Reservation
