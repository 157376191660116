import Icon, { MessageOutlined, PhoneOutlined } from '@ant-design/icons'
import { Card, Col, Modal, Row, Space, Switch } from 'antd'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import FreshChatModule from '../../../components/FreshChat'
import { AspectRatioImage } from '../../../components/Image/AspectRatioImage'
import { ReactComponent as Battery } from '../../../static/img/battery.svg'
import { ReactComponent as StationPin } from '../../../static/img/icon-haup.svg'
import { ReactComponent as LineIcon } from '../../../static/img/icon/line-msg.svg'

const StationPinIcon = () => <Icon component={StationPin} />
const BatteryIcon = () => <Icon component={Battery} />

interface IVehicleData {
  vehicleModel: string
  vehicleYear: string
  licenseNumber: string
  hostStationName: string
  battery: number
  image: string
  cardColor: string
  vehicleColor: string
  batteryColor: string
  verifiedType: string
  verifiedType_color: string
  verifiedType_text_color: string
  nextReservation: string
  insuranceExpiredate: string
  insuranceExpire_color: string
  vehicleId: number
  availability: {
    available: boolean
    changeable: boolean
    vehicleId: number
  }
}

interface IVehicleProps {
  vehicleData: IVehicleData
  fullSize: boolean
  onChangeAvailability: (turnOn: boolean) => void
  availabilityLoading?: boolean
}

const Wrapper = styled.div<{ grayed: boolean }>`
  width: 100%;
  height: 100%;
  & *:not(.ant-switch-inner, .not-me) {
    font-size: x-small;
    color: #00000073;
  }
`

const DotColor = styled.div<{ color: string }>`
  background-color: ${(props) => props.color || '#00000060'};
  height: 12px;
  width: 12px;
  border-radius: 50%;
`

const VehicleTypeBadge = styled.span.attrs({
  className: 'not-me',
})<{ backgroundColor: string; color: string }>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: ${(props) => props.backgroundColor || '#FFFFFF'};
  color: ${(props) => props.color || '#0046A0'};
  padding: 0px 5px;
  font-size: xx-small;
`

const StationText = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`

const RowWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`

const Vehicle: React.FC<IVehicleProps> = ({
  vehicleData,
  fullSize,
  onChangeAvailability,
  availabilityLoading,
}) => {
  const { t, i18n } = useTranslation()
  const {
    vehicleModel,
    vehicleYear,
    licenseNumber,
    hostStationName,
    battery,
    image,
    cardColor,
    vehicleColor,
    batteryColor,
    verifiedType,
    verifiedType_color,
    verifiedType_text_color,
    nextReservation,
    insuranceExpiredate,
    insuranceExpire_color,
    vehicleId,
    availability,
  } = vehicleData

  const nextReservationStr = nextReservation
    ? `${t('HOST_DASHBOARD:VEHICLE_CARD.NEXT_RESERVATION')}: ${moment(nextReservation).format(
        'DD MMM YYYY',
      )}`
    : ''
  let insuranceExpiredateStr = ''
  if (insuranceExpiredate) {
    const insuranceExpObj = moment(insuranceExpiredate)
    const isValidInsuranceExp = insuranceExpObj.isAfter(moment('2015-01-01 00:00:00'))
    insuranceExpiredateStr = isValidInsuranceExp
      ? `${t('HOST_DASHBOARD:VEHICLE_CARD.INSURANCE_EXPIRY')}: ${moment(insuranceExpiredate).format(
          'DD MMM YYYY',
        )}`
      : ''
  }

  function onClickCustomerService() {
    Modal.info({
      title: 'Customer Service',
      content: (
        <Space direction="vertical" size="small" style={{ marginTop: '16px' }}>
          <div onClick={FreshChatModule.openWidget} style={{ cursor: 'pointer' }}>
            <Space align="center" style={{ color: '#1890ff' }}>
              <MessageOutlined style={{ fontSize: 22, color: '#1890ff' }} /> In-app Chat
            </Space>
          </div>
          <div>
            <Space align="center">
              <LineIcon style={{ width: '22px', height: '22px', color: 'green' }} /> Line
              @haup.partner
            </Space>
          </div>
          <div>
            <Space align="center">
              <PhoneOutlined style={{ fontSize: 22 }} /> Tel: 02-113-1155
            </Space>
          </div>
        </Space>
      ),
    })
  }

  return (
    <Col span={fullSize ? 24 : 21} className="vehicle-card-col">
      <Wrapper grayed={availability?.available === false}>
        <Card
          bordered={false}
          style={{
            height: '100%',
            borderRadius: '10px',
            padding: '5px',
            backgroundColor: availability.available === false ? '#00000010' : undefined,
          }}
          bodyStyle={{
            padding: '5px',
            height: '100%',
            position: 'relative',
          }}
        >
          <RowWrapper>
            <Row gutter={16}>
              <Col span={10}>
                <div style={{ position: 'relative' }}>
                  <VehicleTypeBadge
                    backgroundColor={verifiedType_color}
                    color={verifiedType_text_color}
                  >
                    {verifiedType === 'Instant Key'
                      ? t('VEHICLE_SYSTEM:HAUP')
                      : t('VEHICLE_SYSTEM:MANUAL')}
                  </VehicleTypeBadge>
                  <AspectRatioImage ratio={[4, 3]} imgSrc={image} imgStyle={{ minWidth: '100%' }} />
                </div>
                <StationText style={{ fontSize: 'smaller', marginTop: '5px' }}>
                  <StationPinIcon /> <span>{hostStationName}</span>
                </StationText>
              </Col>
              <Col span={14}>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                >
                  <span style={{ fontWeight: 'bold' }}>
                    {vehicleModel} {vehicleYear}
                  </span>
                  <DotColor color={vehicleColor} />
                </div>
                <div>
                  <span style={{ fontWeight: 'bold' }}>({licenseNumber})</span>
                </div>
                <Space style={{ marginTop: '8px' }}>
                  <span>{t('HOST_DASHBOARD:VEHICLE_CARD.STATUS')}:</span>
                  <Switch
                    checked={availability.available}
                    onChange={onChangeAvailability}
                    checkedChildren={t('HOST_DASHBOARD:VEHICLE_CARD.ONLINE')}
                    unCheckedChildren={t('HOST_DASHBOARD:VEHICLE_CARD.OFFLINE')}
                    disabled={!availability.changeable}
                  />
                </Space>
                {availability.changeable === false && (
                  <div style={{ color: 'red', fontSize: 'smaller' }}>
                    {t('HOST_DASHBOARD:VEHICLE_CARD.DISABLED')}{' '}
                    <span
                      className="not-me"
                      style={{ textDecoration: 'underline' }}
                      onClick={onClickCustomerService}
                    >
                      {t('HOST_DASHBOARD:VEHICLE_CARD.CUSTOMER_SERVICE')}.
                    </span>
                  </div>
                )}
                <div style={{ marginTop: '8px' }}>
                  <span
                    style={{
                      color: insuranceExpire_color,
                      fontSize: 'smaller',
                      whiteSpace: 'pre-wrap',
                      display: 'block',
                    }}
                  >
                    {insuranceExpiredateStr}
                  </span>
                  <span style={{ color: '#0046A0', fontSize: 'smaller', display: 'block' }}>
                    {nextReservationStr}
                  </span>
                </div>
              </Col>
            </Row>
          </RowWrapper>
        </Card>
      </Wrapper>
    </Col>
  )
}

export default Vehicle
