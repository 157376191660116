import Icon from '@ant-design/icons/lib/components/Icon'
import { Badge, Divider } from 'antd'
import React from 'react'
import { Link, LinkProps } from 'react-router-dom'
import { theme } from '../../config/style'

const buttonActive: React.CSSProperties = {
  background: theme.haupBlue,
  color: 'white',
}
const buttonInActive: React.CSSProperties = {
  background: '#fafafa',
  color: theme.textPrimary,
}
const dividerActive: React.CSSProperties = {
  borderLeft: '1px solid rgb(255, 255, 255)',
}

const MetricBadge: React.FC<{ icon?: any; style?: any; value: number; label: string }> = (
  props,
) => {
  const { icon, style, value, label } = props
  return (
    <div
      style={{
        display: 'table-cell',
        justifyContent: 'center',
        alignItems: 'center',
        width: '5rem',
        height: '5rem',
        // border: '0.1rem solid blue',
        borderRadius: '1rem',
        textAlign: 'center',
        verticalAlign: 'middle',
        padding: '0px',
        boxShadow: '0px 3px 6px grey',
        backgroundColor: 'white',
        ...(style && { ...style }),
      }}
    >
      {icon && (
        <Icon component={icon} style={{ position: 'absolute', top: '0.4rem', right: '0.4rem' }} />
      )}
      <p style={{ fontSize: '1.2rem' }}>{value}</p>
      <p style={{ fontSize: '0.7rem' }}>{label}</p>
    </div>
  )
}

const WideMetricBadge: React.FC<{
  icon?: any
  style?: any
  value: number
  label: React.ReactNode
  dotVisible?: boolean
  active?: boolean
  link?: LinkProps
  ref?: React.Ref<HTMLDivElement>
  onActiveRef?: React.Ref<HTMLDivElement>
}> = (props) => {
  const { style, value, label, dotVisible, active, link, ref, onActiveRef } = props
  const Container = ({ children }: { children: React.ReactNode }) => {
    if (link) {
      return <Link {...link}>{children}</Link>
    }
    return <>{children}</>
  }
  return (
    <Container>
      <div
        ref={onActiveRef && active ? onActiveRef : ref ? ref : undefined}
        style={{
          width: '100%',
          borderRadius: '1rem',
          padding: '10px 15px',
          minHeight: '2.75rem',
          height: '100%',
          boxShadow: '0px 3px 6px lightgrey',
          display: 'flex',
          justifyContent: 'space-between',
          ...(active ? { ...buttonActive } : { ...buttonInActive }),
          ...(style && { ...style }),
        }}
      >
        <p style={{ fontSize: '0.8rem', textAlign: 'left', whiteSpace: 'nowrap' }}>{label}</p>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Divider
            type="vertical"
            style={active ? { ...dividerActive } : { borderLeft: '1px solid rgba(0, 0, 0, 0.3)' }}
          />
          <Badge dot count={dotVisible ? value : 0}>
            <p style={{ fontSize: '1.3em', color: active ? 'white' : undefined }}>{value}</p>
          </Badge>
        </div>
      </div>
    </Container>
  )
}

export { MetricBadge, WideMetricBadge }
