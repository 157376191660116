import { Button, Col, Input, Modal, Row } from 'antd'
import React, { useState } from 'react'
import styled from 'styled-components'

const { TextArea } = Input

const StyledButton = styled(Button)`
  border-radius: 18rem;
`

const DoneButton = styled(StyledButton)`
  background-color: #0046a0;
  min-width: 10rem;
  font-size: 1rem;
  font-weight: lighter;
  height: 3rem;
`

const ActionModal: React.FC<{ isModalVisible: boolean; onReject: any }> = ({
  isModalVisible,
  onReject,
}) => {
  const [showModal, setShowModal] = useState(isModalVisible)
  const [rejectReason, setRejectReason] = useState('')
  const [loadingBtn, setLoadingBtn] = useState(false)
  console.log(isModalVisible)
  return (
    <Modal
      title={<h2 style={{ textAlign: 'center', color: '#D81D1D' }}>DECLINE</h2>}
      visible={showModal}
      onCancel={() => setShowModal(false)}
      footer={
        <div style={{ textAlign: 'center' }}>
          <DoneButton
            type="primary"
            key="back"
            loading={loadingBtn}
            onClick={async () => {
              setLoadingBtn(true)
              await onReject(rejectReason)
              setLoadingBtn(false)
              setShowModal(false)
            }}
          >
            Done
          </DoneButton>
        </div>
      }
    >
      <Row>
        <Col>
          <label>Please give us a short reason of declining this request</label>
          <TextArea
            rows={4}
            value={rejectReason}
            onChange={(e) => setRejectReason(e.target.value)}
          />
        </Col>
      </Row>
    </Modal>
  )
}

export default ActionModal
