import { LeftOutlined } from '@ant-design/icons'
import { Col, Row, Typography } from 'antd'
import { theme } from '../../../config/style'

interface IMobileTitleProps {
  backgroundColor?: string
  color?: string
  align?: 'left' | 'right' | 'center'
  fontSize?: string
  onBack?: () => any
  backButtonProps?: Record<string, any>
  extra?: string | React.ReactNode
  footer?: string | React.ReactNode
  title?: string
  subTitle?: string
}

export const MobileTitle: React.FC<IMobileTitleProps> = (props): JSX.Element => {
  return (
    <header
      className="pad-content"
      style={{
        backgroundColor: props.backgroundColor || theme.haupBlue,
        position: 'sticky',
        top: '0',
        zIndex: 99,
        color: props.color || 'white',
        borderBottom: '1px solid rgb(230, 230, 230)',
      }}
    >
      <Row wrap={false}>
        <Col flex="50px">
          {props.onBack && <LeftOutlined onClick={props.onBack} {...props.backButtonProps} />}
        </Col>
        <Col flex="auto">
          <div style={{ textAlign: 'center' }}>
            {props.title && (
              <Typography.Title
                level={1}
                style={{
                  color: props.color || 'white',
                  textAlign: props.align || 'center',
                  width: '100%',
                  fontSize: props.fontSize || 'large',
                  margin: '0px',
                }}
              >
                {props.title}
              </Typography.Title>
            )}
            {props.subTitle && (
              <Typography.Title
                level={2}
                style={{
                  color: props.color || 'white',
                  textAlign: props.align || 'center',
                  width: '100%',
                  fontSize: props.fontSize || 'small',
                  margin: '0px',
                  fontWeight: 300,
                }}
              >
                {props.subTitle}
              </Typography.Title>
            )}
          </div>
        </Col>
        <Col flex="50px" style={{ textAlign: 'right' }}>
          {props.extra && props.extra}
        </Col>
      </Row>
      {props.footer && (
        <Row>
          <Col span={24}>{props.footer}</Col>
        </Row>
      )}
    </header>
  )
}
