import { Descriptions } from 'antd'
import React from 'react'
import styled from 'styled-components'

interface IDescriptionProps {
  title: string
  extra?: JSX.Element
  bordered?: boolean
}

const Description: React.FC<IDescriptionProps> = (props): JSX.Element => {
  const { title, extra, children } = props
  let { bordered } = props
  bordered = bordered ? bordered : false
  const StyledTitle = styled.div`
    color: #445064;
    font-size: 1rem;
    font-weight: normal;
  `
  return (
    <Descriptions
      title={<StyledTitle>{title}</StyledTitle>}
      bordered={bordered}
      column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
      labelStyle={
        bordered
          ? {
              backgroundColor: 'unset',
              padding: '10px 0',
              border: 'unset',
              color: 'navy',
            }
          : {}
      }
      contentStyle={
        bordered
          ? {
              backgroundColor: 'unset',
              padding: 'unset',
              textAlign: 'right',
              fontWeight: 'lighter',
            }
          : {}
      }
      extra={extra ? extra : ''}
    >
      {children}
    </Descriptions>
  )
}

export default Description
