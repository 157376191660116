import { Card, Descriptions, Divider } from 'antd'
import React from 'react'

interface IEstimateDetail {
  reservationDetail: IReservationDetail
}
type PriceDetail = {
  hourPrice: number
  bundlePrice: number
  estimatePrice: number
}
type BundleDetail = {
  bundle_distance_km: number
  bundle_distancerate: number
  bundlecode: string
}
interface IReservationDetail {
  username: string
  station: string
  stationLink: string
  vehicle: string
  reservationStartTime: string
  reservationStopTime: string
  price: PriceDetail
  bundle: BundleDetail
}

const EstimateDetail = (props: IEstimateDetail) => {
  const { reservationDetail } = props
  const {
    username,
    station,
    stationLink,
    vehicle,
    reservationStartTime,
    reservationStopTime,
    price,
    bundle,
  } = reservationDetail
  const { bundle_distance_km, bundle_distancerate, bundlecode } = bundle
  const { hourPrice, bundlePrice, estimatePrice } = price
  return (
    <Card title="Reservation Detail">
      <Descriptions
        title="Detail"
        column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
        size="small"
        labelStyle={{
          backgroundColor: 'unset',
          padding: '10px 0',
          border: 'unset',
          color: 'navy',
        }}
        contentStyle={{
          backgroundColor: 'unset',
          padding: 'unset',
          textAlign: 'right',
        }}
        style={{ border: 'unset' }}
        bordered
      >
        <Descriptions.Item label="User">{username}</Descriptions.Item>
        <Descriptions.Item label="Station">
          <a target="_blank" href={stationLink}>
            {station}
          </a>
        </Descriptions.Item>
        <Descriptions.Item label="Vehicle">{vehicle}</Descriptions.Item>
        <Descriptions.Item label="From">{reservationStartTime}</Descriptions.Item>
        <Descriptions.Item label="to">{reservationStopTime}</Descriptions.Item>
      </Descriptions>
      <Divider />
      <Descriptions
        title="Distance"
        column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
        size="small"
        labelStyle={{
          backgroundColor: 'unset',
          padding: '10px 0',
          border: 'unset',
          color: 'navy',
        }}
        contentStyle={{
          backgroundColor: 'unset',
          padding: 'unset',
          textAlign: 'right',
        }}
        style={{ border: 'unset' }}
        bordered
      >
        <Descriptions.Item label="Package">{bundlecode}</Descriptions.Item>
        <Descriptions.Item label="Included distance">{bundle_distance_km}</Descriptions.Item>
        <Descriptions.Item label="Distance rate">{bundle_distancerate}</Descriptions.Item>
      </Descriptions>
      <Divider />
      <Descriptions
        title="Price"
        column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
        size="small"
        labelStyle={{
          backgroundColor: 'unset',
          padding: '10px 0',
          border: 'unset',
          color: 'navy',
        }}
        contentStyle={{
          backgroundColor: 'unset',
          padding: 'unset',
          textAlign: 'right',
        }}
        style={{ border: 'unset' }}
        bordered
      >
        <Descriptions.Item label="Hour Price">{hourPrice}</Descriptions.Item>
        <Descriptions.Item label="Distance Price">{bundlePrice}</Descriptions.Item>
        <Descriptions.Item label="Total Price">{estimatePrice}</Descriptions.Item>
      </Descriptions>
    </Card>
  )
}

export default EstimateDetail
