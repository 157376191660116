import { SearchOutlined } from '@ant-design/icons'
import { BackTop, Col, Divider, Input, List, message, Row, Skeleton, Space } from 'antd'
import moment from 'moment'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useHistory, useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { MobileTitle } from '../../../components/Dashboard/MobileTitle'
import {
  Card,
  ReservationListItem,
} from '../../../components/Dashboard/ReservationList/ReservationListItem'
import { WideMetricBadge } from '../../../components/MetricBadge'
import { theme } from '../../../config/style'
import { useAuth } from '../../../contexts/Auth'
import {
  countReservations,
  getAllReservations,
  IGetReservationExtended,
  IReservationListData,
  ReservationCategory,
} from '../../../services/Host'
import { useDebounce } from '../../../utils/debounce'
import HostReservationDetail from '../ReservationDetail'

const XScrollBar = styled.div`
  max-width: 100%;
  padding-top: 1rem;
  overflow-x: auto;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`

const CapsuleInput = styled(Input)`
  border-radius: 2rem;
  border: 1px solid ${theme.haupBlue};
  padding: 0.5rem 1rem;
`

export enum ReservationStatus {
  RESERVE = 'RESERVE',
  DRIVE = 'DRIVE',
  FINISH = 'FINISH',
  CANCEL = 'CANCEL',
  COMPLETE = 'COMPLETE',
  REQUEST = 'REQUEST',
  REJECT = 'REJECT',
  ALL = 'ALL',
}

interface IListMeta {
  total: number
  currentPage: number
  nextPage: number
}

type IFilterDateRange = { from: string; to: string } | null
const initFilterDateRange: IFilterDateRange = {
  from: moment().startOf('month').toISOString(),
  to: moment().endOf('month').toISOString(),
}
const initReservationList: IReservationListData[] = []
const initListMeta: IListMeta = { total: 0, currentPage: 0, nextPage: 1 }

function parseMonthYear(monthYear: string) {
  if (!monthYear) {
    return initFilterDateRange
  }
  if (monthYear === 'all') {
    return { from: '', to: '' }
  }
  const [month, year] = monthYear.split('-').map((item) => Number(item))
  if (!month || !year) {
    return initFilterDateRange
  }
  if (isNaN(month) || isNaN(year)) {
    return initFilterDateRange
  }
  if (month < 1 && month > 12) {
    return initFilterDateRange
  }
  const mY = moment()
    .year(year)
    .month(month - 1)
  const from = mY.startOf('month').toISOString()
  const to = mY.endOf('month').toISOString()
  return { from, to }
}

const HostReservationList = () => {
  const { t, i18n } = useTranslation(['RESERVATION_STATUSES', 'RESERVATION_LIST', 'COMMON'])
  const auth = useAuth()
  const location = useLocation()
  const history = useHistory()
  const queryParams = useMemo(() => {
    const searchParams = new URLSearchParams(location.search)
    const object = Object.fromEntries(searchParams)
    return object
  }, [location.search])
  const [reservationList, setReservationList] =
    useState<IReservationListData[]>(initReservationList)
  const [listMeta, setListMeta] = useState<IListMeta>(initListMeta)
  const [listLoading, setListLoading] = useState(false)
  const [pageLoading, setPageLoading] = useState(true)
  const [listCountLoading, setListCountLoading] = useState(false)
  const [reservationCount, setReservationCount] = useState<Record<string, number>>()
  const [searchQuery, setSearchQuery] = useState<string>('')
  const debouncedSearchQuery = useDebounce(searchQuery, 1000)
  const { reservationStatus, reservationCategory, view, hidenav, monthYear } = queryParams
  const isFirstRun = useRef(true)
  const [oldestReservationDate, setOldestReservationDate] = useState<moment.Moment>()
  const listOfMonth = useMemo(() => {
    const currentDate = moment().endOf('month')
    if (!oldestReservationDate) {
      return [{ ...initFilterDateRange }]
    }
    const startDate = moment(oldestReservationDate)
    const results = [{ ...initFilterDateRange }]
    while (startDate.isBefore(currentDate)) {
      results.push({
        from: startDate.startOf('month').toISOString(),
        to: startDate.endOf('month').toISOString(),
      })
      startDate.add(1, 'month')
    }
    return results.sort((a, b) => moment(b.from).valueOf() - moment(a.from).valueOf())
  }, [oldestReservationDate])
  const [filterDateRange, setFilterDateRange] = useState<IFilterDateRange>(
    parseMonthYear(monthYear),
  )
  const filterButtonActivedRef = useRef<HTMLDivElement>(null)
  const isDisableDateFilterTab = ['REQUEST'].includes(reservationStatus)

  async function getOldestReservationDate() {
    const result = await getAllReservations(auth, {
      page: 1,
      results: 1,
      sortBy: 'logtime',
      sortOrder: 'ASC',
    })
    if (!result?.data[0]?.logtime) {
      message.error(`Can't determine date`)
      return
    }
    setOldestReservationDate(moment(result?.data[0]?.logtime))
  }

  async function fetch(init = false) {
    if (listLoading) {
      return
    }
    let requestParams: IGetReservationExtended = {
      results: 5,
      page: listMeta.nextPage,
    }
    if (init === true) {
      requestParams = { ...requestParams, page: 1 }
    }
    if (reservationStatus) {
      requestParams = { ...requestParams, reservationStatus }
      if (reservationStatus === 'REQUEST') {
        requestParams = { ...requestParams, sortBy: 'reservestarttime', sortOrder: 'ASC' }
      }
    }
    if (reservationCategory) {
      requestParams = { ...requestParams, reservationCategory }
    }
    if (debouncedSearchQuery) {
      requestParams = { ...requestParams, query: debouncedSearchQuery }
    }
    if (!['REQUEST'].includes(reservationStatus)) {
      if (filterDateRange?.from && moment(filterDateRange.from).isValid()) {
        requestParams = { ...requestParams, dateFrom: filterDateRange.from }
      }
      if (filterDateRange?.to && moment(filterDateRange.to).isValid()) {
        requestParams = { ...requestParams, dateTo: filterDateRange.to }
      }
    }

    setListLoading(true)
    try {
      const reservations = await getAllReservations(auth, requestParams)
      setReservationList((prev) => [...prev, ...reservations.data])
      setListMeta((prev) => ({
        total: prev.total === reservations?.meta?.total ? prev.total : reservations?.meta?.total,
        currentPage: Number(reservations?.meta?.page),
        nextPage: Number(reservations?.meta?.page) + 1,
      }))
    } catch (error) {
      console.log(error)
      message.error('Getting reservation list failed.')
    }
    setListLoading(false)
  }

  async function fetchReservationCount(params: IGetReservationExtended) {
    try {
      const count = await countReservations(auth, params)
      const keyName = params.reservationStatus
      const keyNameWithCateg = `${keyName}${
        params.reservationCategory ? `_${params.reservationCategory}` : ''
      }`
      return setReservationCount((prev) => ({ ...prev, [keyNameWithCateg]: count }))
    } catch (error) {
      console.log(error)
      message.error('Getting reservation count failed. Please try refresh the page.')
    }
  }

  function handleFilterDateRangeChange(event: React.ChangeEvent<HTMLSelectElement>) {
    const [from, to] = event.target.value.split('|')
    setFilterDateRange({ from, to })
  }

  useEffect(() => {
    getOldestReservationDate()
    if (monthYear) {
      if (monthYear === 'all') {
        setFilterDateRange({ from: 'ทั้งหมด', to: '' })
      }
    }
  }, [])

  useEffect(() => {
    if (searchQuery.toLowerCase() === 'thai language please') {
      i18n.changeLanguage('th').finally(() => {
        message.info('เปลี่ยนเป็นภาษาไทย')
        setSearchQuery('')
      })
    }
    if (searchQuery.toLowerCase() === 'english language please') {
      i18n.changeLanguage('en').finally(() => {
        message.info('Change language to english')
        setSearchQuery('')
      })
    }
  }, [searchQuery])

  useEffect(() => {
    setListCountLoading(true)
    const dateParams: IGetReservationExtended = {
      dateFrom: moment(filterDateRange?.from).isValid() ? filterDateRange?.from : undefined,
      dateTo: moment(filterDateRange?.to).isValid() ? filterDateRange?.to : undefined,
    }
    const queryParams: IGetReservationExtended = {
      query: debouncedSearchQuery || undefined,
    }
    const fetchReservationCountTasks = [
      { reservationStatus: ReservationStatus.REQUEST },
      { reservationStatus: ReservationStatus.DRIVE, ...dateParams, ...queryParams },
      {
        ...dateParams,
        ...queryParams,
        reservationStatus: ReservationStatus.ALL,
      },
      {
        ...dateParams,
        ...queryParams,
        reservationStatus: ReservationStatus.COMPLETE,
      },
      {
        ...dateParams,
        ...queryParams,
        reservationStatus: ReservationStatus.CANCEL,
      },
      {
        ...dateParams,
        ...queryParams,
        reservationStatus: ReservationStatus.FINISH,
      },
      {
        ...dateParams,
        ...queryParams,
        reservationStatus: ReservationStatus.REJECT,
      },
      {
        ...dateParams,
        ...queryParams,
        reservationStatus: ReservationStatus.RESERVE,
      },
      {
        ...dateParams,
        ...queryParams,
        reservationStatus: ReservationStatus.RESERVE,
        reservationCategory: ReservationCategory.MA,
      },
    ]
    Promise.all(fetchReservationCountTasks.map((item) => fetchReservationCount(item)))
      .then(() => {
        setListCountLoading(false)
        return new Promise((resolve) => setTimeout(() => resolve(true), 1000))
      })
      .finally(() => {
        if (filterButtonActivedRef.current) {
          filterButtonActivedRef.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
            inline: 'nearest',
          })
        }
      })
  }, [filterDateRange, debouncedSearchQuery])

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false
      fetch(true)
      setPageLoading(false)
      return
    }
    setReservationList(initReservationList)
    setListMeta(initListMeta)
    fetch(true)
  }, [reservationStatus, reservationCategory, filterDateRange, debouncedSearchQuery])

  if (pageLoading) {
    return (
      <div
        style={{
          height: '100%',
          background: '#fafafa',
          minHeight: '100vh',
          fontWeight: 500,
          color: theme.textPrimary,
        }}
      >
        <Row justify="center">
          <Col flex="0 1 500px">
            <section id="main">
              <Skeleton active />
            </section>
          </Col>
        </Row>
      </div>
    )
  }

  if (view) {
    return <HostReservationDetail reservationId={Number(view)} hideNavBar={Boolean(hidenav)} />
  }

  return (
    <div
      style={{
        height: '100%',
        background: '#fafafa',
        fontWeight: 500,
        color: theme.textPrimary,
      }}
    >
      <Row justify="center">
        <Col flex="0 1 500px">
          <section id="main" style={{ minHeight: '100vh' }}>
            <MobileTitle
              backgroundColor="white"
              onBack={() => history.push('/host/dashboard')}
              backButtonProps={{ style: { color: 'black' } }}
              title={t('RESERVATION_LIST:TITLE')}
              color={theme.textPrimary}
            />
            <XScrollBar>
              {listCountLoading ? (
                <Space size="middle" style={{ margin: '0 1rem 10px 1rem' }}>
                  <Skeleton.Button active shape="round" size="large" />
                  <Skeleton.Button active shape="round" size="large" />
                  <Skeleton.Button active shape="round" size="large" />
                  <Skeleton.Button active shape="round" size="large" />
                </Space>
              ) : (
                <Space size="middle" style={{ margin: '0 1rem 10px 1rem' }}>
                  <WideMetricBadge
                    onActiveRef={filterButtonActivedRef}
                    value={reservationCount?.ALL || 0}
                    label={t('COMMON:ALL')}
                    active={!reservationStatus ? true : false}
                    link={{ to: { pathname: '/host/dashboard/reservation' } }}
                  />
                  <WideMetricBadge
                    onActiveRef={filterButtonActivedRef}
                    value={reservationCount?.REQUEST || 0}
                    dotVisible
                    label={t('RESERVATION_STATUSES:REQUEST')}
                    active={isDisableDateFilterTab}
                    link={{
                      to: {
                        pathname: '/host/dashboard/reservation',
                        search: '?reservationStatus=REQUEST',
                      },
                    }}
                  />
                  <WideMetricBadge
                    onActiveRef={filterButtonActivedRef}
                    value={reservationCount?.RESERVE || 0}
                    label={t('RESERVATION_STATUSES:RESERVE')}
                    active={reservationStatus === 'RESERVE' && !reservationCategory}
                    link={{
                      to: {
                        pathname: '/host/dashboard/reservation',
                        search: '?reservationStatus=RESERVE',
                      },
                    }}
                  />
                  <WideMetricBadge
                    onActiveRef={filterButtonActivedRef}
                    value={reservationCount?.DRIVE || 0}
                    label={t('RESERVATION_STATUSES:DRIVE')}
                    active={reservationStatus === 'DRIVE'}
                    link={{
                      to: {
                        pathname: '/host/dashboard/reservation',
                        search: '?reservationStatus=DRIVE',
                      },
                    }}
                  />
                  <WideMetricBadge
                    onActiveRef={filterButtonActivedRef}
                    value={reservationCount?.FINISH || 0}
                    label={t('RESERVATION_STATUSES:FINISH')}
                    active={reservationStatus === 'FINISH'}
                    link={{
                      to: {
                        pathname: '/host/dashboard/reservation',
                        search: '?reservationStatus=FINISH',
                      },
                    }}
                  />
                  <WideMetricBadge
                    onActiveRef={filterButtonActivedRef}
                    value={reservationCount?.COMPLETE || 0}
                    label={t('RESERVATION_STATUSES:COMPLETE')}
                    active={reservationStatus === 'COMPLETE'}
                    link={{
                      to: {
                        pathname: '/host/dashboard/reservation',
                        search: '?reservationStatus=COMPLETE',
                      },
                    }}
                  />
                  <WideMetricBadge
                    onActiveRef={filterButtonActivedRef}
                    value={reservationCount?.REJECT || 0}
                    label={t('RESERVATION_STATUSES:REJECT')}
                    active={reservationStatus === 'REJECT'}
                    link={{
                      to: {
                        pathname: '/host/dashboard/reservation',
                        search: '?reservationStatus=REJECT',
                      },
                    }}
                  />
                  <WideMetricBadge
                    onActiveRef={filterButtonActivedRef}
                    value={reservationCount?.CANCEL || 0}
                    label={t('RESERVATION_STATUSES:CANCEL')}
                    active={reservationStatus === 'CANCEL'}
                    link={{
                      to: {
                        pathname: '/host/dashboard/reservation',
                        search: '?reservationStatus=CANCEL',
                      },
                    }}
                  />
                  <WideMetricBadge
                    onActiveRef={filterButtonActivedRef}
                    value={reservationCount?.RESERVE_MA || 0}
                    label={t('RESERVATION_STATUSES:MA')}
                    active={reservationStatus === 'RESERVE' && reservationCategory === 'MA'}
                    link={{
                      to: {
                        pathname: '/host/dashboard/reservation',
                        search: '?reservationStatus=RESERVE&reservationCategory=MA',
                      },
                    }}
                  />
                </Space>
              )}
            </XScrollBar>
            <div className="pad-content">
              <CapsuleInput
                placeholder={t('RESERVATION_LIST:SEARCHBOX_PLACEHOLDER')}
                suffix={<SearchOutlined />}
                value={searchQuery}
                onChange={(event) => setSearchQuery(event.target.value)}
              />
              <div style={{ paddingTop: '1rem' }} hidden={isDisableDateFilterTab}>
                <select
                  disabled={isDisableDateFilterTab}
                  value={
                    filterDateRange ? `${filterDateRange?.from}|${filterDateRange?.to}` : undefined
                  }
                  onChange={handleFilterDateRangeChange}
                >
                  <option value={undefined}>{t('COMMON:ALL')}</option>
                  {listOfMonth.map((value, index) => {
                    const { from, to } = value
                    return (
                      <option key={index} value={`${from}|${to}`}>
                        {moment(from).locale(i18n.resolvedLanguage).format('MMM YYYY')}
                      </option>
                    )
                  })}
                </select>
              </div>
              {reservationList.length > 0 ? (
                <InfiniteScroll
                  dataLength={reservationList.length}
                  hasMore={listMeta.total > reservationList.length ? true : false}
                  loader={
                    <Card style={{ width: '100%', margin: '1rem 0' }}>
                      <Skeleton active avatar />
                    </Card>
                  }
                  next={fetch}
                  endMessage={
                    <Divider plain>{t('RESERVATION_LIST:LIST_INFINITESCROLL_END')}</Divider>
                  }
                >
                  <List
                    dataSource={reservationList}
                    style={{ marginTop: '1rem' }}
                    renderItem={(item, index) => {
                      return (
                        <Link
                          to={{
                            ...location,
                            search: `${location.search}&view=${item.reservationno}`,
                          }}
                        >
                          <List.Item>
                            <ReservationListItem key={index} {...item} />
                          </List.Item>
                        </Link>
                      )
                    }}
                  />
                </InfiniteScroll>
              ) : listLoading ? (
                <div style={{ marginTop: '1rem', padding: '1rem' }}>
                  <List
                    dataSource={[0]}
                    renderItem={(item) => (
                      <List.Item key={item}>
                        <Card style={{ width: '100%' }}>
                          <Skeleton active avatar />
                        </Card>
                      </List.Item>
                    )}
                  />
                </div>
              ) : (
                <div
                  style={{
                    height: '500px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <div style={{ textAlign: 'center' }}>
                    <div style={{ color: theme.textSecondary, fontSize: 'larger' }}>
                      {t('RESERVATION_LIST:LIST_NOTFOUND_TITLE')}
                    </div>
                    <div style={{ color: theme.textSecondary, fontWeight: 300 }}>
                      {t('RESERVATION_LIST:LIST_NOTFOUND_SUBTITLE')}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </section>
        </Col>
      </Row>
      <BackTop />
    </div>
  )
}

export default HostReservationList
