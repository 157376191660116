import { Col, Row } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { CarsType, ReservationMonthType } from './BlackoutDateType'
import styles from './css/backoutdate.module.css'
import './css/calendar.css'

export const FullCalendar = (props: any) => {
  const [monthActive, setMonthActive] = useState<string>(moment(new Date()).format('MM'))
  const localizer = momentLocalizer(moment)
  return (
    <>
      <Calendar
        views={['month']}
        showMultiDayTimes
        onNavigate={(date) => {
          setMonthActive(moment(date).format('MM')),
            setTimeout(() => props.setMonthCurrent(moment(date).format('MM-YYYY')), 700)
        }}
        defaultDate={props.monthActve}
        selectable={false}
        startAccessor="start"
        endAccessor="end"
        className={`${styles.calendar_custom}`}
        components={{
          toolbar: (toolbar) =>
            CustomToolbar(
              toolbar,
              props.monthActve,
              props.setMonthActive,
              props.clickDate,
              props.page,
            ),
          month: {
            header: (data) => (
              <DateHeader
                defaultHeaderProps={data}
                monthActive={monthActive}
                checkDayOfWeek={props.checkDayOfWeek}
                monthCurrent={props.monthCurrent}
                setClickDate={props.setClickDate}
              />
            ),
            dateHeader: (date: any, label: string, onDrillDown: void) => (
              <DateCellWrapper
                date={date}
                label={label}
                onDrillDown={onDrillDown}
                setClickDate={props.setClickDate}
                clickDate={props.clickDate}
                events={props.getEvents}
                checkDayOfWeek={props.checkDayOfWeek}
                carsHost={props.carsHost}
                setDateSendForm={props.setDateSendForm}
                clickdatelast={props.clickdatelast}
                setClickDatelast={props.setClickDatelast}
                page={props.page}
              />
            ),
          },
        }}
        localizer={localizer}
      />
    </>
  )
}

const CustomToolbar = (
  toolbar: any,
  monthActve: Date,
  setMonthActive: any,
  clickDate: Date[],
  page: string,
) => {
  const [checkback, setCheckback] = useState<boolean>(false)
  const to_day: Date = new Date()
  const toolbarDate = new Date(toolbar.date)

  const newDate = new Date(
    new Date(monthActve).getFullYear(),
    new Date(monthActve).getMonth(),
    new Date().getDate(),
  )

  const goToBack = () => {
    if (
      new Date(toolbarDate.getFullYear(), toolbarDate.getMonth()) >
      new Date(to_day.getFullYear(), to_day.getMonth())
    ) {
      setMonthActive(undefined)
      toolbar.date.setMonth(toolbarDate.getMonth() - 1)
      toolbar.onNavigate('prev')
    }
  }

  const goToNext = () => {
    setMonthActive(undefined)
    toolbar.date.setMonth(toolbarDate.getMonth() + 1)
    toolbar.onNavigate('next')
  }
  const label = () => {
    const date = moment(toolbarDate)
    let getdate: any = ''
    if (clickDate !== undefined && clickDate.length > 0 && page === 'list') {
      getdate = clickDate[0].getDate()
    }
    return (
      <label className={`${styles.label_date}`}>
        {getdate + ' ' + date.format('MMMM')} {date.format('YYYY')}
      </label>
    )
  }

  useEffect(() => {
    if (monthActve !== undefined) {
      toolbar.date.setMonth(newDate.getMonth())
      toolbar.date.setYear(newDate.getFullYear())
      toolbar.onNavigate('next')
    }
  }, [])

  useEffect(() => {
    if (
      new Date(toolbarDate.getFullYear(), toolbarDate.getMonth()) >
      new Date(to_day.getFullYear(), to_day.getMonth())
    ) {
      setCheckback(true)
    } else {
      setCheckback(false)
    }
  }, [toolbar])
  return (
    <div className={`${styles.toolbar_container}`}>
      <Row>
        <Col className="gutter-row" span={4}>
          <div
            className={`${styles.btn_back} ${
              checkback ? styles.on_click_back : styles.disabled_click
            }`}
            onClick={goToBack}
          >
            &#8249;
          </div>
        </Col>
        <Col className="gutter-row" span={16}>
          {label()}
        </Col>
        <Col className="gutter-row" span={4}>
          <div className={`${styles.btn_next}`} onClick={goToNext}>
            &#8250;
          </div>
        </Col>
      </Row>
    </div>
  )
}

const DateCellWrapper = (props: any) => {
  const date: any = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
  const NewDate = new Date(props.date.date)
  const day = date[NewDate.getDay()]
  const months = month[NewDate.getMonth()]
  const dateNow = new Date()

  dateNow.setHours(0, 0, 0, 0)

  const ClickDate = (date: Date) => {
    if (props.checkDayOfWeek === false) {
      if (date >= dateNow) {
        if (props.clickdatelast && props.clickdatelast.length === 1) {
          const lastDateselect = props.clickdatelast.pop()
          const dateRabge = []
          if (moment(lastDateselect).format('YYYY-MM-DD') <= moment(date).format('YYYY-MM-DD')) {
            for (const i = lastDateselect; i <= NewDate; i.setDate(i.getDate() + 1)) {
              dateRabge.push(new Date(i))
            }
            props.setClickDatelast([lastDateselect, date])
            props.setClickDate(dateRabge)
          } else {
            props.setClickDatelast([date])
            props.setClickDate([date])
          }
        } else {
          props.setClickDatelast ? props.setClickDatelast([date]) : null
          props.setClickDate([date])
        }
      }
    }
  }

  useEffect(() => {
    if (props.clickDate?.length > 0) {
      props.clickDate?.map((val: Date) => {
        const html =
          moment(val).format('DD') + '_' + month[Number(moment(val).format('MM')) - 1] + '_more'
        const dateclick = document.getElementsByClassName(html) as HTMLCollectionOf<HTMLElement>
        if (dateclick.length > 0) {
          dateclick[0].classList.add('date_current')
        }
      })
    }
  }, [props.clickDate])

  return (
    <>
      <div
        className={`${'date_'}${day}${'_'}${moment(NewDate).format('MM')}${'_'}${
          NewDate >= dateNow ? 'more' : ''
        } ${props.date.label}_${months}${'_'}${NewDate >= dateNow ? 'more' : ''} ${
          props.checkDayOfWeek ? styles.disabled : ''
        } ${styles.date_in_calendar} ${NewDate < dateNow ? styles.disabled_click : ''}`}
        date-attribut={props.date.date}
        onClick={() => [ClickDate(NewDate)]}
      >
        {props.date.label}
      </div>
      <div className={`${styles.box_list_events}`}>
        <Row className={`${styles.calendar_lists_events}`}>
          {Array.isArray(props.events)
            ? props.events?.map((val: ReservationMonthType) => {
                if (
                  moment(val.reservestarttime).format('YYYY-MM-DD') ===
                  moment(props.date.date).format('YYYY-MM-DD')
                ) {
                  return (
                    <>
                      {EventList(val, props.clickDate, props.carsHost, val.vehicleid, props.page)}
                    </>
                  )
                }
              })
            : props.events?.reserveddates?.map((val: Date) => {
                if (
                  moment(val).format('YYYY-MM-DD') === moment(props.date.date).format('YYYY-MM-DD')
                ) {
                  return (
                    <>
                      {EventList(
                        val,
                        props.clickDate,
                        props.carsHost,
                        props.events?.vehicleid,
                        props.page,
                      )}
                    </>
                  )
                }
              })}
        </Row>
      </div>
    </>
  )
}

const DateHeader = (props: any) => {
  const onDateHeaderClickedHandler = (e: any) => {
    e.preventDefault()
    const dateSlots: Date[] = []
    if (props.checkDayOfWeek) {
      removeElements(document.querySelectorAll('.date_current'))
      const day_date = document.getElementsByClassName(
        'date_' + props.defaultHeaderProps.label + '_' + props.monthActive + '_more',
      )
      for (let i = 0; i < day_date.length; i++) {
        dateSlots.push(
          new Date(moment(day_date[i].getAttribute('date-attribut')).format('MM/DD/YYYY')),
        )
        day_date[i].classList.add('date_current')
      }
    }
    props.setClickDate(dateSlots)
  }
  return (
    <React.Fragment>
      <div>
        <button
          className={`${styles.btn_in_calendar} ${
            props.checkDayOfWeek ? styles.check_dayOfweek : ''
          }`}
          onClick={(e) => onDateHeaderClickedHandler(e)}
        >
          <div>{props.defaultHeaderProps.label}</div>
        </button>
      </div>
    </React.Fragment>
  )
}

const EventList = (
  data: any,
  checkDate: any,
  carshost: CarsType[],
  vehicleid: number,
  page: string,
) => {
  const cars = carshost?.filter((car: CarsType) => car.vehicleId === vehicleid)

  const car = cars.shift()

  return (
    <>
      <div
        className={`${styles.events_list} ${
          checkDate &&
          checkDate.length > 0 &&
          page !== 'form' &&
          moment(checkDate[0]).format('DDMMYY') === moment(data.reservestarttime).format('DDMMYY')
            ? styles.display_none
            : ''
        }`}
        style={{ background: car?.hostColor }}
      ></div>
    </>
  )
}

const removeElements = (elms: any) => {
  elms.forEach((el: any) => el.classList.remove('date_current'))
}

const month: any = [
  'jan',
  'feb',
  'mar',
  'apr',
  'may',
  'jun',
  'jul',
  'aug',
  'sep',
  'oct',
  'nov',
  'dec',
]
