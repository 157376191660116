import { UserOutlined } from '@ant-design/icons'
import { Avatar, Col, Rate, Row, Space } from 'antd'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface IUserData {
  name: string
  lastName: string
  joinedDate: string
  rating: number
  reviews: number
  totalReservation: number
}

interface IVehicleData {
  starterCars: number
  verifiedCars: number
  verifiedPro: number
}

interface IProfileProps {
  userData: IUserData
  vehicle: IVehicleData
}

const Profile: React.FC<IProfileProps> = (props: IProfileProps) => {
  const { t, i18n } = useTranslation('HOST_DASHBOARD')
  const { name, lastName, joinedDate, rating, reviews, totalReservation } = props?.userData
  const { starterCars, verifiedCars, verifiedPro } = props?.vehicle

  const transformDate = joinedDate
    ? moment(new Date(joinedDate)).locale(i18n.resolvedLanguage).format('MMM YYYY')
    : '-'

  return (
    <Row
      className="pad-content"
      style={{
        backgroundColor: '#FFF',
        position: 'sticky',
        top: '0',
        zIndex: 99,
        color: '#445064',
        boxShadow: '0 5px 5px rgb(136 136 136 / 30%)',
      }}
    >
      <Col span={24}>
        <Row className="profile">
          <Space style={{ width: '100%', flex: 'none' }} className="fullWidthSpace">
            <Col>
              <Avatar size={74} icon={<UserOutlined />} />
            </Col>
            <Col>
              <Row>
                <Col>
                  <div style={{ color: '#0046A0', fontSize: '1rem' }}>
                    {name} {lastName}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <div>
                    {t('PROFILE.JOIN_DATE')} {transformDate}
                  </div>
                </Col>
                <Col span={12}>
                  {rating && (
                    <>
                      {rating}/5{' '}
                      <Rate
                        disabled
                        allowHalf
                        defaultValue={rating}
                        style={{ color: '#0046A0', fontSize: 'small' }}
                      />
                    </>
                  )}
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  {reviews} {t('PROFILE.REVIEWS')}
                </Col>
                <Col span={12}>
                  {totalReservation} {t('PROFILE.TOTAL_RESERVATION')}
                </Col>
              </Row>
              {/* <Row>
                <Col>Response within a day</Col>
              </Row> */}
            </Col>
          </Space>
        </Row>
        <br />
        <Row>
          <Col span={8} style={{ textAlign: 'center' }}>
            {starterCars}
            <br />
            Starter Cars
          </Col>
          <Col span={8} style={{ textAlign: 'center' }}>
            {verifiedCars}
            <br />
            Verified cars
          </Col>
          <Col span={8} style={{ textAlign: 'center' }}>
            {verifiedPro}
            <br />
            Verified Pro
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default Profile
