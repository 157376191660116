import { DownOutlined } from '@ant-design/icons'
import { Col, Collapse, message, Result, Row, Skeleton, Space, Spin } from 'antd'
import moment from 'moment'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect, useHistory } from 'react-router'
import { MobileTitle } from '../../../components/Dashboard/MobileTitle'
import {
  AcceptancePanel,
  CarImage,
  colorHaupBlue,
  colorTextPrimary,
  CustomTag,
  DecoratedReservationStatus,
  Divider,
  DividerDiv,
  DurationBox,
  fontLight,
  fontSmaller,
  format,
  hexCloudWhite,
  linkStyle,
  LR,
  NoticeBox,
  PaddedDiv,
  popConfirmAcceptReservation,
  popConfirmedModal,
  popRejectedModal,
  RejectPanel,
  TextFramed,
} from '../../../components/Dashboard/ReservationList/ReservationDetailComponents'
import { THB } from '../../../components/Dashboard/ReservationList/ReservationListItem'
import { theme } from '../../../config/style'
import { useAuth } from '../../../contexts/Auth'
import {
  acceptReservation,
  getReservationById,
  IReservationDetail,
  rejectReservation,
} from '../../../services/Host'

interface IHostReservationDetail {
  reservationId: number
  hideNavBar?: boolean
}

const HostReservationDetail = ({ reservationId, hideNavBar }: IHostReservationDetail) => {
  const { t, i18n } = useTranslation(['RESERVATION_DETAIL', 'COMMON'])
  const auth = useAuth()
  const history = useHistory()
  const [reservationData, setReservationData] = useState<Partial<IReservationDetail>>({})
  const [pageState, setPageState] = useState<'loading' | 'ready' | 'error'>('loading')
  const footerHeightRef: any = useRef(null)
  const [rejectPanelOpen, setReject] = useState(false)
  const [ajaxLoading, setAjaxLoading] = useState(false)
  const footerHeight = useMemo(() => {
    if (footerHeightRef?.current?.clientHeight) {
      return footerHeightRef.current.clientHeight
    }
    return 0
  }, [footerHeightRef, rejectPanelOpen])

  const { reservation, vehicle, renter, station, dst_station, charges } = useMemo(() => {
    return reservationData
  }, [reservationData])
  const transactions = useMemo(() => {
    if (!Array.isArray(charges)) {
      return []
    }
    const arr = charges.filter((item) => ['RESERVE', 'EXTRA'].includes(item.chargeevent))
    return arr.filter((item) => item.chargestate !== 'ERROR')
  }, [charges])

  const additionalTransactions = useMemo(() => {
    if (!Array.isArray(charges)) {
      return []
    }
    const arr = charges.filter((item) => ['MANUAL'].includes(item.chargeevent))
    return arr.filter((item) => item.chargestate !== 'ERROR')
  }, [charges])
  const price = {
    estimatedprice: reservation?.estimatedprice || 0,
    chargetotal: reservation?.chargetotal || 0,
    extra_chargetotal: reservation?.extra_chargetotal || 0,
    hourprice: reservation?.hourprice || 0,
    distance_price: reservation?.distance_price || 0,
    discount: reservation?.discount || 0,
    fullprice: reservation?.fullprice || 0,
    totalprice: reservation?.totalprice || 0,
    bundleprice: reservation?.bundleprice || 0,
    latehourprice: reservation?.latehourprice || 0,
    actualprice: reservation?.actualprice || 0,
    getHourPrice: function () {
      return this.hourprice
    },
    getDistancePrice: function () {
      return this.bundleprice
    },
    getSubTotalPrice: function () {
      // if (['FINISH', 'COMPLETE'].includes(reservation?.reservationstate || '')) {
      //   return this.actualprice + this.discount
      // }
      // return this.estimatedprice + this.discount
      return this.hourprice + this.bundleprice
    },
    getExcessDistancePrice: function () {
      return this.distance_price
    },
    getManualPriceDiscount: function () {
      return this.totalprice - this.actualprice
    },
    getTotalPrice: function () {
      if (['FINISH', 'COMPLETE'].includes(reservation?.reservationstate || '')) {
        return this.totalprice
      }
      return this.estimatedprice
    },
    getEstimatedPrice: function () {
      if (['FINISH', 'COMPLETE'].includes(reservation?.reservationstate || '')) {
        return this.totalprice
      }
      return this.estimatedprice
    },
  }

  const isMaTag = useMemo(
    () =>
      reservation && reservation.category.split(',').filter((item) => item === 'MA').length > 0
        ? true
        : false,
    [reservationData],
  )

  async function handleAcceptReservation() {
    const confirm = await popConfirmAcceptReservation()
    if (confirm === false) {
      return
    }
    setAjaxLoading(true)
    try {
      const result = await acceptReservation(auth, Number(reservationId), '')
      if (!result) {
        throw new Error('Accept request failed. Please try again.')
      }
      setAjaxLoading(false)
      const confirm = await popConfirmedModal()
      if (confirm === true) {
        window.location.reload()
      }
    } catch (error: any) {
      console.log(error)
      message.error(error.message)
      setAjaxLoading(false)
    }
  }

  async function handleRejectReservation(reason: string) {
    setAjaxLoading(true)
    try {
      const result = await rejectReservation(auth, Number(reservationId), reason)
      if (!result) {
        throw new Error('Reject request failed. Please try again.')
      }
      setAjaxLoading(false)
      const confirm = await popRejectedModal()
      if (confirm === true) {
        window.location.reload()
      }
    } catch (error: any) {
      console.log(error)
      message.error(error.message)
      setAjaxLoading(false)
    }
  }

  useEffect(() => {
    getReservationById(auth, Number(reservationId))
      .then((result) => {
        setReservationData(result)
        setPageState('ready')
      })
      .catch((error) => {
        console.log(error)
        setPageState('error')
      })
  }, [])

  if (isNaN(Number(reservationId))) {
    return <Redirect to="." />
  }

  if (pageState === 'loading') {
    return (
      <div
        style={{
          // height: '100%',
          background: '#fafafa',
          color: theme.textPrimary,
          fontWeight: 500,
          minHeight: `calc(100vh - ${footerHeight}px)`,
        }}
      >
        <Row justify="center">
          <Col flex="0 1 500px">
            <section>
              {!hideNavBar && (
                <MobileTitle
                  backgroundColor="white"
                  onBack={() => history.goBack()}
                  backButtonProps={{ style: { color: 'black' } }}
                  title={t('RESERVATION_DETAIL:TITLE')}
                  color={theme.textPrimary}
                />
              )}
              <div className="pad-content">
                <Skeleton avatar active />
                <Divider />
                <Skeleton active />
                <Divider />
                <Skeleton active />
                <Divider />
                <Skeleton active />
              </div>
            </section>
          </Col>
        </Row>
      </div>
    )
  }

  if (pageState === 'error') {
    return (
      <div
        style={{
          // height: '100%',
          background: '#fafafa',
          color: theme.textPrimary,
          fontWeight: 500,
          minHeight: `calc(100vh - ${footerHeight}px)`,
        }}
      >
        <Row justify="center">
          <Col flex="0 1 500px">
            <section>
              {!hideNavBar && (
                <MobileTitle
                  backgroundColor="white"
                  onBack={() => history.goBack()}
                  backButtonProps={{ style: { color: 'black' } }}
                  title={t('RESERVATION_DETAIL:TITLE')}
                  color={theme.textPrimary}
                />
              )}
              <div className="pad-content">
                <Result status="error" title="เกิดข้อผิดพลาด" subTitle="กรุณาลองใหม่อีกครั้ง" />
              </div>
            </section>
          </Col>
        </Row>
      </div>
    )
  }

  return (
    <Spin spinning={ajaxLoading}>
      <div
        style={{
          // height: '100%',
          background: '#f7f7f7',
          color: theme.textPrimary,
          fontWeight: 500,
          minHeight: `calc(100vh - ${footerHeight}px)`,
        }}
      >
        <Row justify="center">
          <Col flex="0 1 500px">
            <section>
              {!hideNavBar && (
                <MobileTitle
                  backgroundColor="white"
                  onBack={() => history.goBack()}
                  backButtonProps={{ style: { color: 'black' } }}
                  title={t('RESERVATION_DETAIL:TITLE')}
                  color={theme.textPrimary}
                />
              )}
              <PaddedDiv>
                <Row gutter={8}>
                  <Col span={10}>
                    <CarImage
                      imageSrc={
                        vehicle?.vehicle_picture ||
                        `https://ws.haupcar.com/mobile/vehicleimage?vehicleid=${vehicle?.vehicleid}`
                      }
                      overlayText={vehicle?.verifiedtype}
                    />
                  </Col>
                  <Col span={14}>
                    <div style={{ ...colorHaupBlue }}>
                      {t('COMMON:RESERVATION_NO')} {reservationId}{' '}
                      {isMaTag && <CustomTag color={'red'}>MA</CustomTag>}
                    </div>
                    <div style={{ ...fontSmaller }}>{vehicle?.vehiclemodel}</div>
                    <div style={{ ...fontSmaller }}>
                      {vehicle?.licensenumber} {vehicle?.licenseprovince}
                    </div>
                    <div style={{ color: 'transparent' }}>_</div>
                    <DecoratedReservationStatus reservationstate={reservation?.reservationstate} />
                  </Col>
                </Row>
                {isMaTag && (
                  <Row>
                    <Col span={24}>
                      <NoticeBox>{t('RESERVATION_DETAIL:MA_NOTICE')}</NoticeBox>
                    </Col>
                  </Row>
                )}
              </PaddedDiv>
              {!isMaTag && (
                <>
                  <DividerDiv />
                  <PaddedDiv>
                    <LR
                      left={
                        <span style={{ ...colorHaupBlue }}>
                          {t('RESERVATION_DETAIL:RENTER.TITLE')}
                        </span>
                      }
                      leftStyle={{ maxWidth: '30%' }}
                      right={
                        <span>
                          {renter?.name} {renter?.lastname},{' '}
                          <span style={{ ...fontSmaller, ...fontLight }}>
                            {t('RESERVATION_DETAIL:RENTER.AGE_TEXT')}{' '}
                            {moment().diff(moment(renter?.birthdate), 'year') >= 100
                              ? '-'
                              : moment().diff(moment(renter?.birthdate), 'year')}
                          </span>
                        </span>
                      }
                    />
                    <div style={{ ...fontLight, ...fontSmaller }}>
                      <LR
                        left={`${t('RESERVATION_DETAIL:RENTER.JOIN_DATE')} ${moment(
                          renter?.registeredtime,
                        )
                          .locale(i18n.resolvedLanguage)
                          .format('MMM YYYY')}`}
                        right={/*`${4.3} star ${29} Reviews`*/ ''}
                      />
                      <LR
                        left={`${t('RESERVATION_DETAIL:RENTER.FINISHED')}: ${
                          renter?.finished_reservation
                        }`}
                        right={`${t('RESERVATION_DETAIL:RENTER.COMPLETED')}: ${
                          renter?.completed_reservation
                        }`}
                      />
                    </div>
                  </PaddedDiv>
                </>
              )}
              <DividerDiv />
              <PaddedDiv>
                <LR
                  left={
                    <span style={{ ...colorHaupBlue }}>
                      {t('RESERVATION_DETAIL:DURATION.TITLE')}
                    </span>
                  }
                  right={
                    reservation?.reservationstate &&
                    ['COMPLETE', 'FINISH', 'RESERVE'].includes(reservation?.reservationstate) && (
                      <TextFramed>Confirmed by Host</TextFramed>
                    )
                  }
                />
                <div style={{ ...fontSmaller, margin: '1rem 0' }}>
                  <DurationBox
                    startDate={reservation?.reservestarttime}
                    endDate={reservation?.reservestoptime}
                  />
                </div>
              </PaddedDiv>
              <DividerDiv />
              <PaddedDiv>
                <LR
                  left={
                    <span style={{ ...colorHaupBlue }}>
                      {t('RESERVATION_DETAIL:LOCATION.TITLE')}
                    </span>
                  }
                />
                <DividerDiv />
                <LR
                  left={
                    <span style={{ ...colorTextPrimary, ...fontSmaller }}>
                      {!dst_station?.name
                        ? t('RESERVATION_DETAIL:LOCATION.PICKUP_AND_DROPOFF')
                        : t('RESERVATION_DETAIL:LOCATION.PICKUP')}
                    </span>
                  }
                />
                <LR
                  left={<span style={{ ...fontLight, ...fontSmaller }}>{station?.name}</span>}
                  leftStyle={{ maxWidth: '80%' }}
                  right={
                    <a
                      style={linkStyle}
                      href={`http://maps.google.com/maps?z=12&t=m&q=loc:${station?.lat}+${station?.lng}`}
                      target="_blank"
                    >
                      {t('COMMON:VIEWMAP')}
                    </a>
                  }
                />
                {dst_station?.name && (
                  <>
                    <LR
                      left={
                        <span style={{ ...colorTextPrimary, ...fontSmaller }}>
                          {t('RESERVATION_DETAIL:LOCATION.DROPOFF')}
                        </span>
                      }
                    />
                    <LR
                      left={
                        <span style={{ ...fontLight, ...fontSmaller }}>{dst_station?.name}</span>
                      }
                      leftStyle={{ maxWidth: '80%' }}
                      right={
                        <a
                          style={linkStyle}
                          href={`http://maps.google.com/maps?z=12&t=m&q=loc:${dst_station?.lat}+${dst_station?.lng}`}
                          target="_blank"
                        >
                          {t('COMMON:VIEWMAP')}
                        </a>
                      }
                    />
                  </>
                )}
              </PaddedDiv>
              <DividerDiv />
              <PaddedDiv>
                <LR
                  left={
                    <span style={{ ...colorHaupBlue }}>{t('RESERVATION_DETAIL:PRICE.TITLE')}</span>
                  }
                />
                <LR
                  left={
                    <span style={{ ...fontSmaller, ...fontLight }}>
                      {t('RESERVATION_DETAIL:PRICE.HOUR_PRICE')}
                    </span>
                  }
                  right={
                    <span style={{ ...fontSmaller, ...fontLight }}>
                      {THB(price?.getHourPrice())}
                    </span>
                  }
                />
                <LR
                  left={
                    <span style={{ ...fontSmaller, ...fontLight }}>
                      {t('RESERVATION_DETAIL:PRICE.DISTANCE_PRICE')}
                    </span>
                  }
                  right={
                    <span style={{ ...fontSmaller, ...fontLight }}>
                      {THB(price?.getDistancePrice())}
                    </span>
                  }
                />
                <LR
                  left={
                    <span style={{ ...colorHaupBlue }}>
                      {t('RESERVATION_DETAIL:PRICE.SUBTOTAL')}
                    </span>
                  }
                  right={<span style={{ ...colorHaupBlue }}>{THB(price.getSubTotalPrice())}</span>}
                />
                {price?.discount > 0 && (
                  <LR
                    left={
                      <span style={{ ...fontSmaller, ...fontLight }}>
                        {t('RESERVATION_DETAIL:PRICE.DISCOUNT_CODE')}:{' '}
                        {reservation?.promotioncode || '-'}
                      </span>
                    }
                    right={
                      <span style={{ ...fontSmaller, ...fontLight }}>-{THB(price?.discount)}</span>
                    }
                  />
                )}
                {price?.getExcessDistancePrice() > 0 && (
                  <LR
                    left={
                      <span style={{ ...fontSmaller, ...fontLight }}>
                        {t('RESERVATION_DETAIL:PRICE.EXCESS_DISTANCE_PRICE')}
                      </span>
                    }
                    right={
                      <span style={{ ...fontSmaller, ...fontLight }}>
                        {THB(price?.getExcessDistancePrice())}
                      </span>
                    }
                  />
                )}
                {price?.latehourprice > 0 && (
                  <LR
                    left={
                      <span style={{ ...fontSmaller, ...fontLight }}>
                        {t('RESERVATION_DETAIL:PRICE.LATEHOUR_PRICE')}
                      </span>
                    }
                    right={
                      <span style={{ ...fontSmaller, ...fontLight }}>
                        {THB(price?.latehourprice)}
                      </span>
                    }
                  />
                )}
                {price.getManualPriceDiscount() !== 0 && (
                  <LR
                    left={
                      <span style={{ ...fontSmaller, ...fontLight }}>
                        {price.getManualPriceDiscount() > 0 ? (
                          <>
                            {t('RESERVATION_DETAIL:PRICE.MANUAL_EXCESS_PRICE')}
                            {reservation?.remark && `: ${reservation.remark}`}
                          </>
                        ) : (
                          <>
                            {t('RESERVATION_DETAIL:PRICE.MANUAL_DISCOUNT_PRICE')}
                            {reservation?.remark && `: ${reservation.remark}`}
                          </>
                        )}
                      </span>
                    }
                    right={
                      <span style={{ ...fontSmaller, ...fontLight }}>
                        {THB(price.getManualPriceDiscount())}
                      </span>
                    }
                  />
                )}
                {reservation?.reservationstate &&
                  !['REQUEST', 'RESERVE', 'CANCEL', 'REJECT'].includes(
                    reservation?.reservationstate,
                  ) && (
                    <LR
                      left={
                        <span style={{ ...colorHaupBlue }}>
                          {t('RESERVATION_DETAIL:PRICE.TOTAL')}
                        </span>
                      }
                      right={<span style={{ ...colorHaupBlue }}>{THB(price.getTotalPrice())}</span>}
                    />
                  )}
                <Divider dashed />
                <LR
                  left={
                    <span style={{ ...colorHaupBlue }}>
                      {t('RESERVATION_DETAIL:PRICE.ESTIMATED_PRICE')}
                    </span>
                  }
                  right={<span style={{ ...colorHaupBlue }}>{THB(price.getEstimatedPrice())}</span>}
                />
                <DividerDiv />
                {reservation?.reservationstate !== 'COMPLETE' && (
                  <NoticeBox>
                    {t('RESERVATION_DETAIL:PRICE.PRICE_SUBJECT_TO_CHANGE_NOTICE')}
                  </NoticeBox>
                )}
              </PaddedDiv>
              <DividerDiv />
              {transactions.length > 0 && (
                <Collapse
                  ghost
                  expandIconPosition="right"
                  expandIcon={({ isActive }) => <DownOutlined rotate={isActive ? 180 : 0} />}
                  style={{ background: hexCloudWhite }}
                >
                  <Collapse.Panel key={0} header={t('RESERVATION_DETAIL:TRANSACTIONS.TITLE')}>
                    <Space direction="vertical" style={{ width: '100%' }}>
                      {transactions.map((item, index) => {
                        return (
                          <div
                            key={index}
                            style={{
                              background: '#F4F4F4',
                              borderRadius: '15px',
                              padding: '1rem',
                            }}
                          >
                            <LR
                              left={
                                <span style={{ ...fontSmaller, ...fontLight }}>
                                  {t('RESERVATION_DETAIL:TRANSACTIONS.PAYMENT_METHOD')}
                                </span>
                              }
                              right={
                                <span style={{ ...fontSmaller, ...fontLight }}>
                                  {item.chargeaccount}
                                </span>
                              }
                            />
                            <LR
                              left={
                                <span style={{ ...fontSmaller, ...fontLight }}>
                                  {t('RESERVATION_DETAIL:TRANSACTIONS.PAYMENT_STATUS')}
                                </span>
                              }
                              right={
                                <span style={{ ...fontSmaller, ...fontLight }}>
                                  {item.chargestate}
                                </span>
                              }
                            />
                            <LR
                              left={
                                <span style={{ ...fontSmaller, ...fontLight }}>
                                  {t('RESERVATION_DETAIL:TRANSACTIONS.AMOUNT')}
                                </span>
                              }
                              right={
                                <span style={{ ...fontSmaller, ...fontLight }}>
                                  {THB(item.chargetotal)}
                                </span>
                              }
                            />
                            <LR
                              left={
                                <span style={{ ...fontSmaller, ...fontLight }}>
                                  {t('RESERVATION_DETAIL:TRANSACTIONS.ID')}
                                </span>
                              }
                              right={
                                <span style={{ ...fontSmaller, ...fontLight }}>
                                  {item.chargestate === 'ERROR' ? (
                                    <span style={{ color: 'red' }}>{t('COMMON:UNSUCCESS')}</span>
                                  ) : (
                                    item.chargeid
                                  )}
                                </span>
                              }
                            />
                            <LR
                              left={
                                <span style={{ ...fontSmaller, ...fontLight }}>
                                  {t('RESERVATION_DETAIL:TRANSACTIONS.PAYMENT_DATE_TIME')}
                                </span>
                              }
                              right={
                                <span style={{ ...fontSmaller, ...fontLight }}>
                                  {format(item.chargetime)}
                                </span>
                              }
                            />
                          </div>
                        )
                      })}
                    </Space>
                  </Collapse.Panel>
                </Collapse>
              )}
              <DividerDiv />
              {additionalTransactions.length > 0 && (
                <Collapse
                  ghost
                  expandIconPosition="right"
                  expandIcon={({ isActive }) => <DownOutlined rotate={isActive ? 180 : 0} />}
                  style={{ background: hexCloudWhite }}
                >
                  <Collapse.Panel
                    key={0}
                    header={t('RESERVATION_DETAIL:TRANSACTIONS.ADDITIONAL_TITLE')}
                  >
                    <Space direction="vertical" style={{ width: '100%' }}>
                      {additionalTransactions.map((item, index) => {
                        return (
                          <div
                            key={index}
                            style={{
                              background: '#F4F4F4',
                              borderRadius: '15px',
                              padding: '1rem',
                            }}
                          >
                            <LR
                              left={
                                <span style={{ ...fontSmaller, ...fontLight }}>
                                  {item.description}
                                </span>
                              }
                              leftStyle={{ maxWidth: '100%' }}
                            />
                            <LR
                              left={
                                <span style={{ ...fontSmaller, ...fontLight }}>
                                  {t('RESERVATION_DETAIL:TRANSACTIONS.PAYMENT_METHOD')}
                                </span>
                              }
                              right={
                                <span style={{ ...fontSmaller, ...fontLight }}>
                                  {item.chargeaccount}
                                </span>
                              }
                            />
                            <LR
                              left={
                                <span style={{ ...fontSmaller, ...fontLight }}>
                                  {t('RESERVATION_DETAIL:TRANSACTIONS.PAYMENT_STATUS')}
                                </span>
                              }
                              right={
                                <span style={{ ...fontSmaller, ...fontLight }}>
                                  {item.chargestate}
                                </span>
                              }
                            />
                            <LR
                              left={
                                <span style={{ ...fontSmaller, ...fontLight }}>
                                  {t('RESERVATION_DETAIL:TRANSACTIONS.AMOUNT')}
                                </span>
                              }
                              right={
                                <span style={{ ...fontSmaller, ...fontLight }}>
                                  {THB(item.chargetotal)}
                                </span>
                              }
                            />
                            <LR
                              left={
                                <span style={{ ...fontSmaller, ...fontLight }}>
                                  {t('RESERVATION_DETAIL:TRANSACTIONS.ID')}
                                </span>
                              }
                              right={
                                <span style={{ ...fontSmaller, ...fontLight }}>
                                  {item.chargestate === 'ERROR' ? (
                                    <span style={{ color: 'red' }}>{t('COMMON:UNSUCCESS')}</span>
                                  ) : (
                                    item.chargeid
                                  )}
                                </span>
                              }
                            />
                            <LR
                              left={
                                <span style={{ ...fontSmaller, ...fontLight }}>
                                  {t('RESERVATION_DETAIL:TRANSACTIONS.PAYMENT_DATE_TIME')}
                                </span>
                              }
                              right={
                                <span style={{ ...fontSmaller, ...fontLight }}>
                                  {format(item.chargetime)}
                                </span>
                              }
                            />
                          </div>
                        )
                      })}
                    </Space>
                  </Collapse.Panel>
                </Collapse>
              )}
            </section>
            <footer>
              {reservation?.reservationstate === 'REQUEST' && !rejectPanelOpen && (
                <AcceptancePanel
                  onRef={(ref) => {
                    footerHeightRef.current = ref
                  }}
                  onConfirmClick={handleAcceptReservation}
                  onRejectClick={() => setReject(true)}
                  title={`${t('RESERVATION_DETAIL:FOOTER.REPONSE_BEFORE_TEXT')} ${format(
                    moment(reservation?.logtime).add(1, 'day'),
                  )}`}
                />
              )}
              {rejectPanelOpen && (
                <RejectPanel
                  onCloseButtonClick={() => setReject(false)}
                  onRejectConfirmClick={handleRejectReservation}
                />
              )}
            </footer>
          </Col>
        </Row>
      </div>
    </Spin>
  )
}

export default HostReservationDetail
