import { UserOutlined } from '@ant-design/icons'
import { Avatar, Button, Col, Descriptions, Divider, Modal, Rate, Row, Space } from 'antd'
import React, { useState } from 'react'
import styled from 'styled-components'
import { IReservationDetail } from './index'

const StyledButton = styled(Button)`
  border-radius: 18rem;
`

const DoneButton = styled(StyledButton)`
  background-color: #0046a0;
  min-width: 10rem;
  font-size: 1rem;
  font-weight: lighter;
  height: 3rem;
`

const ActionModal: React.FC<{ isModalVisible: boolean; reservationDetail: IReservationDetail }> = ({
  isModalVisible,
  reservationDetail,
}) => {
  const [showModal, setShowModal] = useState(isModalVisible)
  const {
    reservationNo,
    renterName,
    renterScore,
    vehicleLicense,
    vehicleModel,
    reserveStartTime,
    reserveStopTime,
    hostStationName,
    estimatePrice,
  } = reservationDetail
  return (
    <Modal
      title={<h2 style={{ textAlign: 'center', color: '#0FB75D' }}>ACCEPTED</h2>}
      visible={showModal}
      onCancel={() => setShowModal(false)}
      footer={
        <div style={{ textAlign: 'center' }}>
          <DoneButton type="primary" key="back" onClick={() => setShowModal(false)}>
            Done
          </DoneButton>
        </div>
      }
    >
      <Row>
        <Space>
          <Col>
            <Avatar size={100} icon={<UserOutlined />} />
          </Col>
          <Col>
            <div style={{ fontSize: '0.8rem', color: '#445064' }}>#{reservationNo}</div>
            <div style={{ fontSize: '1rem' }}>{renterName}</div>
            <div>
              {renterScore} <Rate disabled allowHalf defaultValue={renterScore} />
            </div>
          </Col>
        </Space>
      </Row>
      <Divider style={{ color: '#445064' }} />
      <Row>
        <Col>
          <Descriptions layout="vertical">
            <Descriptions.Item label="Car">
              <h3>{vehicleLicense}</h3>
              <h3>{vehicleModel}</h3>
            </Descriptions.Item>
            <Descriptions.Item label="Start Date">
              <h3>{reserveStartTime}</h3>
            </Descriptions.Item>
            <Descriptions.Item label="Stop Date">
              <h3>{reserveStopTime}</h3>
            </Descriptions.Item>
            <Descriptions.Item label="Pick up Location">
              <h3>{hostStationName}</h3>
            </Descriptions.Item>
            <Descriptions.Item label="Estimate Price">
              <h3>฿{estimatePrice}</h3>
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
    </Modal>
  )
}

export default ActionModal
