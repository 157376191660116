import axios from 'axios'
import configs from '../../config'
const { restfulService } = configs

interface IAuthContext {
  user: any
  userLoggedIn: any
}

interface INewReservationService {
  authContext: IAuthContext
  getAvailableVehicleList(param: any): Promise<any>
  getStationList(params: IGetStationList): Promise<any>
  getEstimateReservation(params: IGetEstimateReservation): Promise<any>
  confirmReservation(params: IGetEstimateReservation): Promise<any>
}

interface IGetStationList {
  startTime: string
  stopTime: string
  userId: number | null
}

interface IGetAvailableVehicle extends IGetStationList {
  stationId: number | null
}

interface IGetEstimateReservation extends IGetAvailableVehicle {
  vehicleId: number | null | undefined
  bundleId?: number | null | undefined
  paymentId?: number | null | undefined
}

class NewReservationService implements INewReservationService {
  authContext
  constructor(authContext: IAuthContext) {
    this.authContext = authContext
  }

  getStationList(params: IGetStationList): Promise<any> {
    return new Promise((resolve, reject) => {
      const userToken = this.authContext?.userLoggedIn?.userToken

      console.log(userToken)
      console.log('params', params)

      axios
        .get(`${restfulService.URL}/partners/station`, {
          headers: {
            authorization: userToken,
          },
          params,
        })
        .then(function (response) {
          const { data } = response
          if (data?.result) {
            resolve(data?.data)
          } else {
            reject(data)
          }
        })
        .catch(function (error) {
          const { response, message } = error
          const errorMessage = response ? response?.data?.message : message
          console.dir(error, { depth: null })
          reject(errorMessage)
        })
    })
  }

  getAvailableVehicleList(params: IGetAvailableVehicle): Promise<any> {
    return new Promise((resolve, reject) => {
      const userToken = this.authContext?.userLoggedIn?.userToken

      console.log(userToken)
      console.log('params', params)

      axios
        .get(`${restfulService.URL}/partners/vehicle/available`, {
          headers: {
            authorization: userToken,
          },
          params,
        })
        .then(function (response) {
          const { data } = response
          if (data?.result) {
            resolve(data?.data)
          } else {
            reject(data)
          }
        })
        .catch(function (error) {
          const { response, message } = error
          const errorMessage = response ? response?.data?.message : message
          console.dir(error, { depth: null })
          reject(errorMessage)
        })
    })
  }

  getEstimateReservation(params: IGetEstimateReservation): Promise<any> {
    return new Promise((resolve, reject) => {
      const userToken = this.authContext?.userLoggedIn?.userToken

      console.log(userToken)
      console.log('params', params)

      axios
        .get(`${restfulService.URL}/partners/estimate`, {
          headers: {
            authorization: userToken,
          },
          params,
        })
        .then(function (response) {
          const { data } = response
          console.log('case1')
          if (data?.result) {
            resolve(data?.data)
          } else {
            reject(data)
          }
        })
        .catch(function (error) {
          const { response, message } = error
          const errorMessage = response ? response?.data?.message : message
          console.dir(error, { depth: null })
          reject(errorMessage)
        })
    })
  }

  confirmReservation(params: IGetEstimateReservation): Promise<any> {
    return new Promise((resolve, reject) => {
      const userToken = this.authContext?.userLoggedIn?.userToken

      console.log(userToken)
      console.log('params', params)

      axios
        .post(`${restfulService.URL}/partners/confirm`, params, {
          headers: {
            authorization: userToken,
          },
        })
        .then(function (response) {
          const { data } = response
          if (data?.result) {
            resolve(data?.data)
          } else {
            reject(data)
          }
        })
        .catch(function (error) {
          const { response, message } = error
          const errorMessage = response ? response?.data?.message : message
          console.dir(error, { depth: null })
          reject(errorMessage)
        })
    })
  }
}

export default NewReservationService
