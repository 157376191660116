import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Button, Modal, notification, Space, Table, Tag } from 'antd'
import React, { useContext } from 'react'
import { manageRequestReservation, RequestAction } from '../../services/reservation'
import { AuthContext } from './../../contexts/Auth'

interface IReservationStatus {
  reserveNo: string
  reserveStatus: string
}

interface IReservationTime {
  reserveStarttime: string
  reserveStoptime: string
}

interface IReservationOrigin {
  reserveOrigin: string
  reserveDestination: string
}

const columns = [
  {
    title: 'Reservation No.',
    dataIndex: 'reservationStatus',
    render: (reservationStatus: IReservationStatus) => renderReservationStatus(reservationStatus),
  },
  {
    title: 'Vehicle',
    dataIndex: 'vehicle',
    render: (
      text: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined,
    ) => <a>{text}</a>,
  },
  {
    title: 'Reservation Time',
    dataIndex: 'reservationTime',
    render: (reservationTime: IReservationTime) => renderReservationTime(reservationTime),
  },
  {
    title: 'Origin / Destination',
    dataIndex: 'reservationOrigin',
    render: (reservationOrigin: IReservationOrigin) => renderReservationOrigin(reservationOrigin),
  },
  {
    title: 'Type',
    dataIndex: 'reservationType',
    render: (reservationType: string) => renderReservationType(reservationType),
  },
]
const openNotification = (title: string, message: string) => {
  notification.error({
    message: title,
    description: message,
    onClick: () => {
      console.log('Notification Clicked!')
    },
  })
}
function showApproveBox(reservationNo: string, authContext: any) {
  const getManageRequestReservation = async (action: RequestAction) => {
    return await manageRequestReservation(authContext, action, reservationNo).catch((error) => {
      const { name, message } = error
      openNotification(name, message)
      return false
    })
  }
  Modal.confirm({
    title: `Accept reservation ${reservationNo}?`,
    icon: <ExclamationCircleOutlined />,
    content: '',
    okText: 'Accept',
    okType: 'primary',
    cancelText: 'Reject',
    closable: true,
    cancelButtonProps: { danger: true },
    async onOk() {
      console.log('OK trigger')
      await getManageRequestReservation(RequestAction.accept)
    },
    async onCancel(cancel) {
      if (!cancel?.triggerCancel) {
        await getManageRequestReservation(RequestAction.reject)
      }
    },
  })
}

const renderReservationStatus = (data: IReservationStatus) => {
  const apa = useContext(AuthContext)
  let color = 'default'
  const { reserveStatus, reserveNo } = data
  if (reserveStatus === 'RESERVE' || reserveStatus === 'REQUEST') {
    color = 'blue'
  }
  if (reserveStatus === 'CANCEL' || reserveStatus === 'REJECT') {
    color = 'red'
  }
  if (reserveStatus === 'DRIVE') {
    color = 'green'
  }

  const StatusLabel = (
    <Tag color={color} style={{ width: '70px', textAlign: 'center' }}>
      {data?.reserveStatus}
    </Tag>
  )
  const RequestLabel = (
    <Button
      type="primary"
      size="small"
      onClick={() => {
        showApproveBox(reserveNo, apa)
      }}
    >
      REQUEST
    </Button>
  )
  const Status: JSX.Element = reserveStatus === 'REQUEST' ? RequestLabel : StatusLabel
  return (
    <>
      <Space>
        {data?.reserveNo}
        {Status}
      </Space>
    </>
  )
}

const renderReservationTime = (data: IReservationTime) => {
  return (
    <>
      <p>From: {data?.reserveStarttime}</p>
      <p>To: {data?.reserveStoptime}</p>
    </>
  )
}

const renderReservationOrigin = (data: IReservationOrigin) => {
  return (
    <>
      <p>From: {data?.reserveOrigin}</p>
      <p>To: {data?.reserveDestination}</p>
    </>
  )
}

const renderReservationType = (reservationType: string) => {
  const TagList: JSX.Element[] = []
  if (reservationType !== '') {
    const typeLists = reservationType.split(',')
    typeLists.map((category) => {
      switch (category) {
        case 'MA':
          TagList.push(<Tag color="red">{category}</Tag>)
          break
        case 'HAUP':
          TagList.push(<Tag color="blue">{category}</Tag>)
          break
        default:
          TagList.push(<Tag color="default">{category}</Tag>)
          break
      }
    })
  }
  // let color = 'default'
  // if (reservationType === 'MA') {
  //   color = 'red'
  // }
  // if (reservationType === 'HAUP') {
  //   color = 'blue'
  // }
  // return <Tag color={color}>{reservationType}</Tag>
  return TagList
}

interface IReservationListProps {
  dataSource: any
  getReservationDetail: any
  pagination: any
  onTableChange: any
  loading: boolean
}

class ReservationList extends React.Component<IReservationListProps, unknown> {
  dataSource
  getReservationDetail
  constructor(props: IReservationListProps) {
    super(props)
    this.dataSource = this.props.dataSource
    this.getReservationDetail = this.props.getReservationDetail
  }
  static contextType = AuthContext
  render() {
    return (
      <Table
        columns={columns}
        dataSource={this.props.dataSource}
        pagination={this.props.pagination}
        onChange={this.props.onTableChange}
        loading={this.props.loading}
        style={{ margin: 10 }}
        onRow={(record, rowIndex) => {
          return {
            onClick: () => {
              this.getReservationDetail(rowIndex)
            }, // click row
          }
        }}
      />
    )
  }
}

export default ReservationList
