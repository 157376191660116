import axios from 'axios'
import config from '../../config'

const { restfulService } = config

interface IAuthContext {
  user: any
  userLoggedIn: any
}

export async function getUserList(authContext: IAuthContext, search = ''): Promise<any> {
  return new Promise((resolve, reject) => {
    const userToken = authContext?.userLoggedIn?.userToken
    axios
      .get(`${restfulService.URL}/partners/user`, {
        headers: {
          authorization: userToken,
        },
        params: {
          search,
        },
      })
      .then(function (response) {
        const { data } = response
        if (data?.data) {
          resolve(data?.data)
        } else {
          reject(data)
        }
      })
      .catch(function (error) {
        reject(error)
      })
  })
}
